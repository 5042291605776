<div class="profile">
    <div *ngIf="userDetails?.imageSrc!==undefined; else noImgSrcLarge">
        <img [src]="userDetails?.imageSrc" class="default large" /> 
    </div>
    <ng-template #noImgSrcLarge>
        <div class="no-image-large">
            <p>{{ replacementText }}</p>
        </div>
    </ng-template>
    <div class="profile-details">
        <h6 class="name">{{userDetails?.firstName}} {{userDetails?.lastName}}</h6>
        <div class="caption-1">{{role}}</div>
    </div>
</div>