import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DatepickerComponent } from '../../datepicker/datepicker.component';

const materialModules = [MatCheckboxModule, MatFormFieldModule, MatDatepickerModule, MatInputModule, MatNativeDateModule, MatDatepickerModule, MatSidenavModule];

@Component({
  selector: 'app-filter-sidebar',
  standalone: true,
  imports: [CommonModule, ...materialModules],
  templateUrl: './filter-sidebar.component.html',
  styleUrls: ['./filter-sidebar.component.scss'],
})
export class FilterSidebarComponent {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }


  startDate = new FormControl('');
  endDate = new FormControl('');
  exampleHeader = DatepickerComponent;
}
