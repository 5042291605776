import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketService } from 'src/app/core';
import { DialogService } from '../dialog/dialog.service';
import { SnackbarService } from './../snackbar/snackbar.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

	constructor(private router: Router, private zone: NgZone, private _snackbar: SnackbarService, private dialogeService: DialogService, private websocket: WebsocketService) {
		super();
	}

	override handleError(error: any): void {

		this.zone.run(() => {
			if (error.status === 401) {
				this.dialogeService.closeAll();
				this._snackbar.openSnackBar('Session Timed out. Please Login again!');
				localStorage.clear();
				this.router.navigate(['auth']);
				this.websocket._disconnect();
			}
		})
	}

}
