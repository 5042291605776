import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { MentorFeedbackDialogComponent } from 'src/app/modules/mentor/components/feedback-dialog/feedback-dialog.component';
import { FeedbackDialogComponent } from 'src/app/modules/trainer/components/feedback-dialog/feedback-dialog.component';
import { AuthService, CandidateService, DialogService, OPERATIONS, PRIVATE_ROUTES } from '../../core';
import { AvatarListComponent } from "../avatar-list/avatar-list.component";
// import { DropDownComponent } from '../drop-down/drop-down.component';
import { STATUS } from 'src/app/core/structs/status';
import { ROLES } from '../../core';
import { DropDownComponent } from '../drop-down/drop-down.component';
import { ParameterPromptComponent } from '../parameter-prompt/parameter-prompt.component';
import { ScoreChipComponent } from '../score-chip/score-chip.component';

const materialModules = [
  MatIconModule, MatButtonModule, MatCardModule
]
@Component({
	selector: 'app-candidates-header',
	standalone: true,
	templateUrl: './candidates-header.component.html',
	styleUrls: ['./candidates-header.component.scss'],
	imports: [CommonModule, ...materialModules, ScoreChipComponent, AvatarListComponent, MatDividerModule, DropDownComponent]
})
export class CandidatesHeaderComponent implements OnChanges, OnInit {

  candidateFirstName!: string;
  candidateLastName!: string;
  programName!: string;
  batchName!: string;
  trackName!: string;
  nameInitials!: string;
  userId!: number;
  id!: number;
  fileName = '......';
  isAddFeedbackBtnVisible = false;
  userType!: any;

  score!: number;
  @Output() feedbackAdded = new EventEmitter();
  @Output() candidateData = new EventEmitter();
  @Output() downloadBtnClicked = new EventEmitter();
  @Input() downloadVisibility: any = false;
  @Input() isTrainer = false;
  @Input() isCandidatePortal = false;
  @Input() candidateDetails!: any;
  @Input() candidateId!: any;
  @Input() batchId!: any;
  @Input() isMentor = false;
  @Input() currentUserType = '';
  @Input() isOverAllScore = false;
  showTrainerImageList = false;
  showMentorImageList = false;
  data!: any;
  userDetails!: any;
  role!: any;
  firstName!: any;
  fullName!: any;
  lastName!: any;
  changedCandidateStatus!: any;
  status!: any;
  replacementText: any;
  pipMentorReplacementText: any;
  mentorReplacementText: any;
  @Input() scoreData!: any;
  @Input() candidateStatus!: any;
  @Input() isInterviewer!: any;
  trainersList: any[] = [];
  mentorsList: any[] = [];
  pipMentorsList: any[] = [];
  newStatus!: any;
  currentRole !: string;
  overallScore!: any;
  @Output() changeInCandidateStatus = new EventEmitter();

  constructor(private candidateService: CandidateService,
    private dialog: DialogService,
    private route: ActivatedRoute, public router: Router, private authService: AuthService, private dialogService: DialogService) {

  }

  ngOnInit(): void {
    this.scoreData = { ...this.scoreData };
  }
  ngOnChanges(): void {
    this.scoreData = { ...this.scoreData };
    this.currentRole = this.authService.getCurrentRole() || '';
    if (this.authService.getCurrentRole() === 'admin' || this.authService.getCurrentRole() === 'super_admin') {
      this.scoreData.overallScore = this.candidateDetails.overAllFeedback
        ;
    }
    this.data = localStorage.getItem('userDetails');
    this.userDetails = JSON.parse(this.data) || '';
    this.role = this.userDetails.role;
    this.firstName = this.userDetails.firstName;
    this.lastName = this.userDetails.lastName;
    this.changedCandidateStatus = this.candidateDetails.candidateStatus;
    if (this.role === ROLES.ROLE_CANDIDATE) {
      this.isCandidatePortal === true;
    }
    else if (this.role === ROLES.ROLE_MENTOR) {
      this.isMentor === true;
    }
    this.showHeader();

    if (this.changedCandidateStatus === STATUS.IN_TRAINING || this.changedCandidateStatus === null) {
      this.status = 'In Training';
    }
    else if (this.changedCandidateStatus === STATUS.RESIGNED) {
      this.status = 'Resigned';
    }
    else if (this.changedCandidateStatus === STATUS.CONFIRMED) {
      this.status = 'Confirmed';
    }
    else if (this.changedCandidateStatus === STATUS.PROCESS_EXIT) {
      this.status = 'Process Exit';
    }
  }


  onClick() {
    const currentRole = this.authService.getCurrentRole();
    const firstSegment = this.route.snapshot.url[0]?.path;
    const secondSegment = this.route.snapshot.url[1]?.path;
    const thirdSegment = this.route.snapshot.url[2]?.path;

    if (currentRole === 'super_admin' || currentRole === 'admin') {

      switch (firstSegment) {
        case 'candidates':
          this.router.navigate([`${currentRole}/${PRIVATE_ROUTES.adminCandidates}`]);
          break;
        case 'programs':
          this.router.navigate([`${currentRole}/${PRIVATE_ROUTES.adminPrograms}/${secondSegment}/${thirdSegment}`]);
          break;
        case 'batches':
          this.router.navigate([`${currentRole}/${PRIVATE_ROUTES.adminBatches}/${secondSegment}`]);
          break;
      }
    }

    else if (currentRole === 'mentor') {
      this.router.navigate([`mentor/${PRIVATE_ROUTES.mentorCandidates}`]);
    }

    else if (currentRole === 'trainer') {
      this.router.navigate([`${PRIVATE_ROUTES.trainer}`]);
    }

    else if (currentRole === 'interviewer') {
      this.router.navigate([`${PRIVATE_ROUTES.interviewer}`]);
    }
  }

  downloadReport() {
    this.downloadBtnClicked.emit();
  }

  showDialog(operation: string) {
    this.dialogService.openDialog(ParameterPromptComponent, {}).subscribe({
      next: (response) => {
        if (response) {
          switch (this.authService.getCurrentRole()) {
            case 'trainer':
              this.dialog.openDialog(FeedbackDialogComponent, {
                data: {},
                panelClass: 'feedback-dialog',
                operation: OPERATIONS.ADD,
                candidateData: this.candidateDetails,
                candidateId: this.candidateId,
                batchId: this.batchId
              }).subscribe({
                next: (resp: string) => {
                  this.feedbackAdded.emit('added');
                }
              })
              break;
            case 'mentor':
              this.dialog.openDialog(MentorFeedbackDialogComponent, {
                data: {},
                panelClass: 'feedback-dialog',
                operation: OPERATIONS.ADD,
                candidateData: this.candidateDetails,
                candidateId: this.candidateId,
                batchId: this.batchId
              }).subscribe({
                next: (resp: string) => {
                  this.feedbackAdded.emit('added');
                }
              })
              break;
          }
        }
      }
    })
  }

  showHeader() {
    if (this.isCandidatePortal) {
      this.trackName = this.candidateDetails.trackName;
      this.fullName = this.candidateFirstName + this.candidateLastName;
      this.batchName = this.candidateDetails.batchName;
      this.programName = this.candidateDetails.programName;
      this.userId = this.id;
      this.candidateFirstName = this.firstName;
      this.candidateLastName = this.lastName;

      if (this.candidateFirstName && this.candidateLastName) {
        this.nameInitials = this.candidateFirstName.charAt(0) + this.candidateLastName.charAt(0);

      }

      this.replacementText = this.candidateDetails.candidateDashboardTrainers.map((e: { firstName: string; lastName: string; }) => {
        if (e.firstName && e.lastName)
          return e.firstName?.charAt(0);
        return ''
      })
      if (this.candidateDetails.candidateDashboardTrainers) {
        this.trainersList = this.candidateDetails.candidateDashboardTrainers.map((e: { firstName: string; lastName: string; }) =>
          e.firstName + ' ' + e.lastName
        )

      }
      this.mentorReplacementText = this.candidateDetails.candidateDashboardMentor.map((e: { firstName: string; lastName: string; }) => {
        if (e.firstName && e.lastName)
          return e.firstName?.charAt(0);
        return ''
      })

      if (this.candidateDetails.candidateDashboardMentor) {
        this.mentorsList = this.candidateDetails.candidateDashboardMentor.map((e: { firstName: string; lastName: string; }) =>
          e.firstName + ' ' + e.lastName
        )
      }
      this.pipMentorReplacementText = this.candidateDetails.candidateDashboardPipMentor.map((e: { firstName: string; lastName: string; }) => {
        if (e.firstName && e.lastName)
          return e.firstName?.charAt(0);
        return ''
      })
      if (this.candidateDetails.candidateDashboardPipMentor) {
        this.pipMentorsList = this.candidateDetails.candidateDashboardPipMentor.map((e: { firstName: string; lastName: string; }) =>
          e.firstName + ' ' + e.lastName
        )
      }
    }
    else {
      this.trackName = this.candidateDetails.trackName;
      this.fullName = this.candidateDetails.candidateName.split(' ');
      this.candidateFirstName = this.fullName[0];
      this.candidateLastName = this.fullName[1];
      this.batchName = this.candidateDetails.batchName;
      this.programName = this.candidateDetails.programName;
      this.userId = this.id;
      if (this.candidateFirstName && this.candidateLastName) {
        this.nameInitials = this.candidateFirstName.charAt(0) + this.candidateLastName.charAt(0);
      }
    }
  }

  updatedStatus(status: any) {
    this.newStatus = status;
    this.changeInCandidateStatus.emit(this.newStatus);
  }
}
