import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class UtilityService {
	filterOpen = false;


	icons = [
		'archive',
		'arrow-group',
		'coditas-logo',
		'dot-matrix',
		'one-view-logo',
		'success-check-icon',
		'triangle-group',
		'base_checkbox',
		'left-arrow',
		'email',
		'edit',
		'bottom-illustration',
		'top-radial',
		'logout-g500',
		'logout',
		'google-signin',
		'envelop',
		'lock',
		'search',
		'trainer-logout',
		'trainer-trainings',
		'trainer-batches',
		'Graph-Scroll-nav-left',
		'Graph-Scroll-nav-right',
		'Back',
		'eye-slash',
		'eye',
		'close',
		'download',
		'candidates-empty-state',
		'warning',
		'unarchive',
		'check',
		'error',
		'cancel-icon',
		'checkmark-icon',
		'coditas-logo-primary',
		'person',
		'stand',
		'stand-g500',
		'switch-account',
		'switch-account-g500',
		'users',
		'users-g500',
		'view-kanban',
		'view-kanban-g500',
		'human-male-board',
		'human-male-board-g500',
		'book-open',
		'book-open-g500',
		'arrow-right',
		'arrow-left',
		'left',
		'right',
		'datepicker',
		'chevron-left-icon',
		'chevron-right-icon',
		'add',
		'training',
		'empty-state',
		'left',
		'right',
		'search-empty-state',
		'track-empty-state',
		'cancel',
		'tracks',
		'filter',
		'program-icon',
		'tick',
		'program-empty-state',
		'trainer',
		'trainer-empty-state',
		'dropdown-arrow',
		'dropdown-collapse',
		'archive-confirmation',
		'unarchive-confirmation',
		'duplicate',
		'batches-empty-state',
		'coditas-oneView-logo',
		'batch-icon',
		'black-add',
		'white-tick',
		'line-chart-empty-state',
		'spider-chart-empty-state',
		'score-feedback-empty-state',
		'view-feedback',
		'oneView-sidebar-logo',
		'feedback-dialog-logo',
		'comment-icon',
		'comment-error-icon',
		'comment-success-icon',
		'comment-box-top',
		'edit-feedback',
		'breadcrumb-right',
		'avatar-placeholder',
		'comment-icon-active',
		'mark-pip',
		'mentors',
		'mentors-active',
		'admins-active',
		'notifications',
		'trainer-role',
		'interviewer-role',
		'candidate-role',
		'super_admin-role',
		'switch-role',
		'switch-role-down',
		// 'admin-user',
		'mentor-user',
		'Admins',
		'Admins-user-empty-state',
		'Mentors',
		'admin-dialog',
		'Mentors-user-empty-state',
		'admin-role',
		'super-admin',
		'mentors-dialog',
		'status-icon',
		'right-tick',
		'in-training',
		'confirmation',
		'resigned',
		'mentor-empty-state',
		'info',
		'info-info',
		'first-rank-star',
		'second-rank-star',
		'third-rank-star',
		'mentor-role',
		'confirmed',
		'clock',
		'round-cancel',
		'greater-symbol',
		'feedback-comment-error',
		'unmark-confirmation',
		'mark-pip',
		'extend-pip',
		'notifications-empty-state',
		'notifications-feedback',
		'pip-updates',
		'unread-notification',
		'marked-notification',
		'red-badge',
		'unread-empty-count',
		'interviewers',
		'interviewers-active',
		'interviewers-empty-state',
		'interviewer-dialog',
		'arrow-right-small',
		'set-interview',
		'time-picker',
		'delete',
		'reschedule',
		'cancelled-interview',
		'interview',
		'cancel-interview',
		'delete-interview',
		'category-vector',
		'category-active',
		'add-category',
		'category-emptystate',
		'reminder'
	];

	months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) { }

	/**
	 * Registers the icons in the icons array in the matIconRegistry
	 */
	registerIcons() {
		this.icons.forEach((icon: string) => {
			this.matIconRegistry.addSvgIcon(
				icon,
				this.domSanitizer.bypassSecurityTrustResourceUrl(
					`assets/icons/${icon}.svg`
				)
			);
		});
	}

	/**
	 * Converts simple array into array of objects
	 *
	 * @param array The array to be converted
	 * @param key The key of an object
	 * @returns The array with object
	 */
	toObjectArray(array: any[], key: string): { [key: string]: number }[] {
		return array.map((x: number) => {
			return { [key]: x };
		});
	}

	/**
	 * function to return a string with the date in a format to be sent according to the backend
	 * @param date date which has been selected in the range picker {year:number,month:number,date:number}.Number in being sent as 1 less as set in calendar range picker
	 * @returns the date in the required format which is to be sent to the backend
	 */
	getDateFormat(date: any): string {
		let formattedDate = '';
		for (const key in date) {
			if (key === 'month') {
				(date[key] < 10 && date[key] !== 9) ? formattedDate += `0${date[key] + 1}-` : formattedDate += `${date[key] + 1}-`;
			}
			else if (key === 'date') {
				date[key] < 10 ? formattedDate += `0${date[key]}` : formattedDate += `${date[key]}`;
			}
			else { formattedDate += `${date[key]}-`; }
		}
		return formattedDate;
	}

	/**
	 * 
	 * @param startDate start date selected in the range picker
	 * @param endDate end date selected in the range picker
	 * @returns duration in the format start date-end date to be displayed on the user's screen
	 */
	getDurationFormat(startDate: string, endDate: string, withDate?: boolean): string {
		let duration = '';
		const startArray = startDate.split('-');
		const endArray = endDate.split('-');
		if (withDate) {
			startDate = `${this.months[Number(startArray[1]) - 1]} ${startArray[2]} ${startArray[0]}`;
			endDate = `${this.months[Number(endArray[1]) - 1]} ${endArray[2]} ${endArray[0]}`;
		} else {
			startDate = `${this.months[Number(startArray[1]) - 1]} ${startArray[0]}`;
			endDate = `${this.months[Number(endArray[1]) - 1]} ${endArray[0]}`;
		}
		duration = `${startDate} - ${endDate}`;
		return duration;
	}

	/**
	 * function to convert a string with length>4 to title case i.e. 1st letter capital and other small
	 * @param enteredString the string which is to be converted to title case
	 * @returns string in title case format
	 */
	toTitleCase(enteredString: string) {
		return enteredString.length > 4 ?
			enteredString = enteredString.charAt(0) + enteredString.substring(1).toLowerCase()
			: enteredString
	}

	setFilterOpenTrue() {
		this.filterOpen = true;
	}

	setFilterOpenFalse() {
		this.filterOpen = false;
	}

}



