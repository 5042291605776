import {
	HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private excludedUrlsRegex: RegExp[] = [];
	private excludedUrls = [".svg",".png", "https://accounts.google.com", "www.googleapis.com/oauth2/v3/certs", "https://openidconnect.googleapis.com/v1/userinfo", 'https://oauth2.googleapis.com/token', 'https://accounts.google.com/v3/signin', 'https://accounts.google.com/signin/oauth', 'https://www.googleapis.com/calendar/v3'];

	constructor() {
		this.excludedUrlsRegex =
			this.excludedUrls.map(urlPattern => new RegExp(urlPattern, 'i')) || [];
	}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {

		const passThrough =
			!!this.excludedUrlsRegex.find(regex => regex.test(request.url));

		if (passThrough) {
			return next.handle(request);
		}
		//Setting Content type
		if (!request.headers.has('Content-Type')) {
			request = request.clone({
				url: environment.HOST_URL + request.url,
				headers: request.headers.set(
					'Content-Type',
					'application/json'
				),
			});
		}
		request = this.addAuthenticationToken(request);
		return next.handle(request);
	}

	/**
	 * adds authorisation token to the request header
	 * @param request request in which token to be added
	 * @returns request
	 */
	private addAuthenticationToken(
		request: HttpRequest<any>
	): HttpRequest<any> {
		const token = localStorage.getItem('token');
		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			});
		}
		return request;
	}
}
