import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { EventService } from 'src/app/core/services/event/event.service';

@Component({
  selector: 'app-header-checkbox-dropdown',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatCheckboxModule, MatIconModule, MatMenuModule],
  templateUrl: './header-checkbox-dropdown.component.html',
  styleUrls: ['./header-checkbox-dropdown.component.scss']
})
export class HeaderCheckboxDropdownComponent implements OnInit{
  @Input() options !: any[];
  clickedOptions : any[] = [];
  @Input() X !: number | string;
  @Input() Y !: number | string;
  @Input() mode : 'single' | 'many' = "single";
  @Output() static optionSelected =  new EventEmitter()
  @ViewChild('checkboxDropdown') dropdown !: ElementRef<HTMLElement>
  public open  = false;
  optionsFormControl = new FormArray([] as any);
  @ViewChild('menu') menu !: MatMenu;
  ngOnInit(): void {
    this.createAndAddControls();
  }
  constructor(private event : EventService) {}
  createAndAddControls(){
    this.options?.forEach((option:any)=>{
      const control : FormControl = new FormControl(false);
      this.optionsFormControl.controls.push(control)
      control.valueChanges.subscribe({
        next : (value:any)=>{
          if(value)
          this.clickedOptions.push(option)
          else{
            this.clickedOptions = this.clickedOptions.filter(o=>o.id!==option.id);
          }
          
        }
      })
    });
  }

  optionClicked(index:number){
    this.optionsFormControl.controls[index].patchValue(true);
    if(this.mode==='single'){
      this.event.headerCheckboxDropdownOptionSelected.emit(this.clickedOptions[0])
      this.clickedOptions = [];
      this.open = false;
    }
  }

  public openDropdown(){
    setTimeout(()=>{
      if(this.open===false)
      this.open = true
    }, 2)
  }

}
