import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../../structs';
@Injectable({
  providedIn: 'root'
})
export class InterviewerService {
  constructor(public http: HttpClient) { }
  getInterviewerDashboard(
        reqBody: any
    ): Observable<any> {
        return this.http.post(API_ENDPOINTS.getInterviewerDashboard, reqBody);
    }
    getCandidateHeaderData(
      http: HttpClient,
      reqBody: any
    ): Observable<any> {
      return http.post(API_ENDPOINTS.getCandidateHeaderData, reqBody);
    }
    addInterviewFeedback(reqBody: any) {
      return this.http.post( API_ENDPOINTS.addInterviewerFeedback,reqBody);
    }
    updateInterviewFeedback(reqBody: any) {
      return this.http.post(API_ENDPOINTS.updateInterviewerFeedback, reqBody);
    }
    getCandidateOverviewForInterviewer(reqBody:any){
      return this.http.post(API_ENDPOINTS.getCandidateOverviewForInterviewer,reqBody);
    }
}
