import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-mentor-feedback-comment',
  templateUrl: './mentor-feedback-comment.component.html',
  styleUrls: ['./mentor-feedback-comment.component.scss']
})

export class MentorFeedbackCommentComponent {
  @Input() initialValue = '';
  @Input() control !: FormControl;
  @Input() error = false;

}
