import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}
  status='hello';
  openDialog(component: ComponentType<any>, options: any) {
    const dialogRef = this.dialog.open(component,{
      data: options,
      autoFocus: false,
      width : options.width?options.width : null,
      panelClass: options.panelClass
    });
    return dialogRef.afterClosed();
  }

  /** function to close all dialogs open on the user's screen
   * no params required
   */
  closeAll() {
    this.dialog.closeAll();
  }

  showStatus(event:any){
    this.status=event;
  }
}
