import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEvaluation, IQueryParams, IResponse, ISuccessResponse, ITraining } from 'src/app/core/models';
import { ServicesUtilityService } from '../../utility/services-utility.service';
import { API_ENDPOINTS } from './../../../structs/api.endpoints.enum';

@Injectable({
	providedIn: 'root'
})
export class TrainingsService {
	addEventSuccess: BehaviorSubject<any> = new BehaviorSubject<any>({});
	constructor(public http: HttpClient, private servicesUtilityService: ServicesUtilityService) { }

	/**
	 * Fetch all trainings from server
	 *
	 * @param http The HttpClient object for api call
	 * @param queryParams The get params for the api
	 * @returns Observable of ITraining[]
	 */
	findTrainings(
		http: HttpClient,
		queryParams: IQueryParams
	): Observable<IResponse<ITraining>> {
		const params = this.servicesUtilityService.createHttpParams(queryParams);

		return http.get<IResponse<ITraining>>(API_ENDPOINTS.findTrainings, {
			params,
		});
	}

	findEvalutionType(): Observable<IEvaluation[]> {
		return this.http.get<IEvaluation[]>(API_ENDPOINTS.findEvaluations)
	}

	/**
	 * Sends post request to create a new training
	 *
	 * @param training training object
	 * @returns observable of training created
	 */
	createTraining(training: ITraining): Observable<ISuccessResponse> {
		return this.http.post<ISuccessResponse>(API_ENDPOINTS.createTraining, training);
	}

	/**
	 * Sends patch request to update existing training
	 *
	 * @param training updated training with training_id
	 * @returns observable of training updated
	 */
	updateTrainingStatus(training: ITraining): Observable<ISuccessResponse> {
		return this.http.patch<ISuccessResponse>(
			`${API_ENDPOINTS.updateTrainingStatus}/${training.trainingId}/${training.status}`,
			training
		);
	}

	updateTraining(training: ITraining): Observable<ISuccessResponse> {
		return this.http.put<ISuccessResponse>(
			`${API_ENDPOINTS.updateTraining}`,
			training
		);
	}

	searchTraining(reqBody:any):Observable<any>{
		return this.http.post(API_ENDPOINTS.searchTraining,{},{
			params:reqBody
		})
	}
}
