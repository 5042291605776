import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, PRIVATE_ROUTES, PUBLIC_ROUTES } from 'src/app/core';
import { RoleGuard } from 'src/app/core/guards/choose-role/role.guard';
import { ResetGuardService } from 'src/app/core/guards/reset-page/reset-guard.service';
import {
	AuthComponent,
	ForgotPasswordComponent,
	LoginComponent,
	ResetPasswordComponent
} from './pages';
import { ChooseRoleComponent } from './pages/choose-role/choose-role.component';

const routes: Routes = [
	{
		path: 'auth',
		component: AuthComponent,
		canActivate:[AuthGuard],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: PUBLIC_ROUTES.authLogin,
			},
			{ path: PUBLIC_ROUTES.authLogin, component: LoginComponent },
			{
				path: PUBLIC_ROUTES.authResetPassword,
				canActivate:[ResetGuardService ],
				component: ResetPasswordComponent,
			},
			{
				path: PUBLIC_ROUTES.authForgotPassword,
				component: ForgotPasswordComponent,
			},
			{
				path : PUBLIC_ROUTES.authChooseRole,
				canActivate : [RoleGuard],
				component : ChooseRoleComponent
			}
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}
