<div class="chip-card">
	<div class="card-content-box">
		<div class="card-top-content">
			<div class="card-content-unmark">
				<div class="chip-box">
					<mat-chip
						class="unmark-pip-chip"
						[ngClass]="chipClass(status)"
						>{{ status }}</mat-chip
					>
				</div>
			</div>
			<div class="candidate-status">
				<div class="status-details-box" *ngFor="let details of dataList">
					<!-- <ng-container *ngFor="let details of dataList"> -->
						<div>
							<p class="status-title">{{details.title}}</p>
							<p class="status-body">{{details.value}}</p>
						</div>
					<!-- </ng-container> -->
						
				</div> 

				<!-- <div class="status-details-box">
					<div class="status-title-box">
						<ng-container *ngFor="let key of dataKeys">
							<p class="status-title">{{key}}</p>
						</ng-container>
					</div>
					<div class="status-value-box">
						<ng-container *ngFor="let value of dataValues">
							<p class="status-body">{{value}}</p>
						</ng-container>
					</div>
			</div>  -->
			</div>
		</div>
		<ng-template [ngIf]="statusCard?.isSetInterview">
			<div>
				<button mat-flat-button disableRipple class="tertiary sm">
					Set Interview
				</button>
			</div>
		</ng-template>
		<ng-template [ngIf]="statusCard?.isScheduled">
			<div class="status-actions">
				<div class="action-chip">
					<mat-chip class="scheduled-class">Scheduled</mat-chip>
				</div>
				<div class="icons-box">
					<mat-icon matPrefix svgIcon="clock"></mat-icon>
					<mat-icon matPrefix svgIcon="round-cancel"></mat-icon>
				</div>
			</div>
		</ng-template>
		<ng-template [ngIf]="statusCard?.isCompleted">
			<div class="status-actions">
				<div><mat-chip class="completed">Completed</mat-chip></div>
				<div class="icons-box">
					<mat-icon matPrefix svgIcon="clock"></mat-icon>
					<mat-icon matPrefix svgIcon="round-cancel"></mat-icon>
				</div>
			</div>
		</ng-template>
	</div>
	<ng-template [ngIf]="statusCard?.isViewHistory">
		<div class="bottom-text">
			<p>View History</p>
			<mat-icon matPrefix svgIcon="greater-symbol"></mat-icon>
		</div>
	</ng-template>
</div>
