<div class="reset-password-container" *ngIf="!displaySuccessMsg">
	<div>
		<h1>Create new</h1>
		<h1>password</h1>
	</div>
	<div>
		<p>Choose a password that is different from the</p>
		<p>temporary password.</p>
	</div>

	<form [formGroup]="resetPasswordGroup" class="form-container" (ngSubmit)="resetPasswordSubmit(resetPasswordGroup)">
		<div>
			<label>New Password</label>
			<mat-form-field class="password-form-field social-login" appearance="fill" floatLabel="always">
				<mat-icon matPrefix svgIcon="lock"></mat-icon>
				<input [type]="showNewPassword ? 'text' : 'password'" matInput formControlName="newPassword"
					placeholder="Enter password" class="passwordField" id="newPasswordField"
					(keyup)="passwordValidation($event)" (keypress)="openPopup($event)" (click)="openPopup($event)"
					(clickOutside)="closePopup()" />
				<mat-icon matSuffix [svgIcon]="showNewPassword ? 'eye' : 'eye-slash'" (click)="toggleNewPassword()">
				</mat-icon>
				<div class="context-menu" id="context-menu" [ngStyle]="meterPopup" (clickOutside)="closePopup()">
					<ul tabindex="0" *ngIf="resetPasswordGroup.get('newPassword')?.dirty || spaceKeyDown">
						<span class="caption-4">Password must:</span>
						<ng-container *ngFor="let item of validationMessages.password">
							<li class="smaller-text" [ngClass]="resetPasswordGroup.get('newPassword')?.value?.length===0? 'text-not-valid':
							 resetPasswordGroup.get('newPassword')?.hasError(item.type) ? 'text-not-valid': 'text-valid'">
								<mat-icon matPrefix svgIcon="tick"></mat-icon>
								{{item.label}}
							</li>
						</ng-container>
					</ul>
				</div>
			</mat-form-field>
		</div>
		<div>
			<label>Confirm Password</label>
			<mat-form-field appearance="fill" floatLabel="always" class="social-login">
				<mat-icon matPrefix svgIcon="lock"></mat-icon>
				<input [type]="showConfirmPassword ? 'text' : 'password'" matInput formControlName="confirmPassword"
					placeholder="Confirm password" />
				<mat-icon matSuffix [svgIcon]="showConfirmPassword ? 'eye' : 'eye-slash'"
					(click)="toggleConfirmPassword()">
				</mat-icon>
				<mat-error *ngIf="resetPasswordGroup?.hasError('notSame')">
					Password does not match.Try again!
				</mat-error>
			</mat-form-field>
		</div>
		<button mat-flat-button type="submit" class="mat-flat-button mat-primary functionality-elements">
			Reset Password
		</button>
	</form>
</div>
<app-success-msg *ngIf="displaySuccessMsg" [condition]="'reset'"></app-success-msg>