import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
	MatSnackBarRef, MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';

@Component({
	selector: 'app-snackbar',
	standalone: true,
	imports: [CommonModule, MatIconModule, MatButtonModule],
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
	icon_name!: string | undefined;
	constructor(
		public snackBarRef: MatSnackBarRef<SnackbarComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: any
	) { }

	ngOnInit(): void {
		// set up the icon for snackbar
		if (this.data.type === 'snackbar-success') {
			this.icon_name = 'check';
		} else if (this.data.type === 'snackbar-error') {
			this.icon_name = 'error';
		} else if (this.data.type === 'snackbar-warning') {
			this.icon_name = 'warning';
		} else {
			this.icon_name = undefined;
		}
	}
}
