import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  interviewStatus : {L1 : string, L2 : string, L3 : string} = {
    L1: '',
    L2: '',
    L3: ''
  }
  public headerCheckboxDropdownOptionSelected =  new EventEmitter();
  public loadStatusHistory = new EventEmitter();
  public reset = new EventEmitter();
  public setPaginationCount = new EventEmitter();
}
