import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorHandlerService, initialize } from './core';
import { TokenInterceptor } from './core/interceptors/token-interceptor/token.interceptor';
import { AuthModule } from './modules/auth/auth.module';
import { CalendarComponent } from './shared/calendar/calendar.component';
import { MatTreeModule } from '@angular/material/tree';

const materialModules = [MatIconModule, MatSnackBarModule, MatDialogModule, MatAutocompleteModule, MatSelectModule, MatDatepickerModule,
	MatNativeDateModule,MatMomentDateModule, MatTreeModule];

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AuthModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		...materialModules,
		CalendarComponent
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initialize,
			deps: [],
			multi: true,
		},
		{
			provide:ErrorHandler,
			useClass:ErrorHandlerService
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide:ErrorHandler,
			useClass:ErrorHandlerService
		},
		{
			provide: MatDialogRef,
			useValue: {}
		}
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
