<div class="auth-container">
	<div class="illustrations-container">
		<div class="heading">
			<mat-icon svgIcon="coditas-oneView-logo"></mat-icon>
		</div>
		<img
			src="../../../../../assets/icons/top-radial.svg"
			class="top-radial"
		/>
		<div class="central-illustrations">
			<img
				src="../../../../../assets/images/landing-illustration.svg"
				alt="central illustration"
				class="center-illustration"
			/>
			<img
				src="../../../../../assets/icons/dot-matrix.svg"
				class="dot-matrix"
			/>
			<img
				src="../../../../../assets/icons/arrow-group.svg"
				class="arrow-group"
			/>
			<img
				src="../../../../../assets/icons/triangle-group.svg"
				class="triangle-group"
			/>
		</div>
		<div class="information">
			<h1>Welcome to OneView</h1>
			<p class="para-2">One place to view, evaluate and track the progress of</p>
			<p class="para-2">all your candidates.</p>
		</div>
		<img
			src="../../../../../assets/icons/bottom-illustration.svg"
			class="bottom-illustration"
		/>
		<span class="coditas-copyright">© Coditas 2023</span>
	</div>
	<div class="router-container">
		<router-outlet></router-outlet>
	</div>
</div>
