<!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        {{node.name}}
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          {{node.name}}
        </div>
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
            role="group">
          <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree> -->

<mat-tree>
  <mat-nested-tree-node>
    parent node
    <mat-nested-tree-node> -- child node1 </mat-nested-tree-node>
    <mat-nested-tree-node> -- child node2 </mat-nested-tree-node>
  </mat-nested-tree-node>
</mat-tree>