import { CommonModule, DatePipe, formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
// import * as dayjs from "dayjs";
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import _ from 'lodash';
import { AuthService, SnackbarService } from 'src/app/core';
import { CalendarService, ICalendarRes } from 'src/app/core/services/calendar.service';
import { EventService } from 'src/app/core/services/event/event.service';
import { AttendanceAssignmentComponent } from '../attendace-assignment/attendace-assignment.component';
import { PaginationComponent } from '../pagination/pagination.component';
import { ICandidateTree, TreeComponent } from '../tree/tree.component';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../component-library/component-library.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
// dayjs.extend(weekOfYear);

const DATE_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [CommonModule, MatTableModule, AttendanceAssignmentComponent, MatIconModule, MatSidenavModule, MatCardModule, MatTreeModule, PaginationComponent, MatButtonModule, TreeComponent, MatDatepickerModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    MatMomentDateModule,
			{
				provide: DateAdapter,
				useClass: MomentDateAdapter,
				deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
			},

			{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
			{
				provide: MatDialogRef,
				useValue: {}
			},
			{ provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
			[DatePipe]
  ]
})
export class CalendarComponent implements OnInit {

  year: number = 2023;
  month: number = 6;
  week: number = 2;
  openDrawer: boolean = false;
  datePicker = new FormControl();
  calendarData: ICalendarRes[] = [];
  pageSize: number = 4;
  pageNumber: number = 0;
  toDay: Date = new Date();
  @Input() candidates: { candidateId: number, candidateName: string, batchId: number }[] = [
    {
      candidateId: 1,
      candidateName: 'Manish Ingale',
      batchId: 10
    },
    {
      candidateId: 2,
      candidateName: 'Sagar Kedari',
      batchId: 10
    },
    {
      candidateId: 3,
      candidateName: 'Shikha Jain',
      batchId: 10
    },
    {
      candidateId: 4,
      candidateName: 'Tejas Labde',
      batchId: 10
    }
  ]
  currentMonth: string = '';
  weekStartDate: number = 0;
  weekEndDate: number = 0;
  weekDays: string[] = ['candidates', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun',];
  days: Date[] = [];
  daysMap: { [key: string]: Date } = {}
  dataSource: any[] = [];
  changesArray: ICandidateTree[] = [];
  searchField = new FormControl('');
  maxPages !: number;
  totalCount: number = 0;
  id: any;
  @Input() batchId!: any;

  constructor(private datePipe: DatePipe, private calendarService: CalendarService, private authService: AuthService, private eventService: EventService, private snackbarService: SnackbarService, private route: ActivatedRoute) {
    this.id = route.snapshot.params['batchId'];
  }
  ngOnInit(): void {
    this.setPageSize();
    this.getCandidates();
    // console.log(this.generateCalendar(this.year, this.month, this.week));
    this.year = parseInt(this.datePipe.transform(new Date(), 'YYYY') || '-1');
    this.month = parseInt(this.datePipe.transform(new Date(), 'MM') || '-1');
    this.week = this.calculateWeekNumber(new Date());
    this.days = this.getDaysOfWeek(this.year, this.month, this.week);
    this.processDateMap();
    this.getCalendarWithDate();
    // this.prepareDataSource();
    this.searchCandidate();
  }

  candidateFormGroupMap: {
    [key: string | number]: {
      [key: string]: {
        formGroup: FormGroup,
        initialValue: {
          attendance: null | boolean,
          assignment: null | boolean
        }
      },
    }
  } = {}

  // generateCalendar(year: number, month: number, week: number): Date[] {
  //   const startDate = dayjs().year(year).month(month - 1).startOf('month'); // Subtract 1 from month to match zero-based index
  //   const startOfWeek = startDate.startOf('week').add(week - 1, 'week'); // Subtract 1 from week to match zero-based index
  //   const endOfWeek = startOfWeek.endOf('week');
  //   const days = [];

  //   let currentDate = startOfWeek;
  //   while (currentDate.isSame(endOfWeek, 'day') || currentDate.isBefore(endOfWeek, 'day')) {
  //     days.push(currentDate.toDate());
  //     currentDate = currentDate.add(1, 'day');
  //   }

  //   return days;
  // }


  prepareDataSource() {
    this.dataSource = [];
    this.candidates.forEach(candidate => {
      let weekNo = 0;
      const data: any = {
        candidate: candidate,
      }
      this.weekDays.forEach(day => {
        if (day !== 'candidates') {
          data[day] = {
            date: this.daysMap[day],
            content: 'All Good',
          }
          data[day].formGroup = this.getFormGroup(candidate, (data[day].date as Date).toDateString(), data[day]);
          data[day].initialValues = this.getInitialValue(candidate.candidateId, (data[day].date as Date).toDateString()) ? this.getInitialValue(candidate.candidateId, (data[day].date as Date).toDateString()) : data[day].formGroup.value;
          if (!this.getInitialValue(candidate.candidateId, (data[day].date as Date).toDateString())) {
            data[day].formGroup.valueChanges.subscribe({
              next: (value: any) => {
                const initialValue = data[day].initialValues;
                if (_.isEqual(initialValue, value)) {
                  const entry = {
                    candidateDetails: candidate,
                    date: (data[day].date as Date).toDateString()
                  }
                  this.popAnEntry(entry)
                } else {
                  const entry = {
                    candidateDetails: candidate,
                    Dates: [
                      {
                        date: (data[day].date as Date).toDateString(),
                        values: {
                          attendance: {
                            from: initialValue.attendace,
                            to: value.attendace
                          },
                          assignment: {
                            from: initialValue.assignment,
                            to: value.assignment
                          }
                        },
                        formGroup: data[day].formGroup,
                        initialValue: data[day].initialValues,
                        recordId: data.recordId || null
                      }
                    ]
                  }
                  this.pushAnEntry(entry)
                }
              }
            })

            if (this.candidateFormGroupMap[candidate.candidateId] && !this.candidateFormGroupMap[candidate.candidateId]?.[data[day]?.date]) {
              this.candidateFormGroupMap[candidate.candidateId] = this.candidateFormGroupMap[candidate.candidateId] || {};
              this.candidateFormGroupMap[candidate.candidateId][(data[day].date as Date).toDateString()] = this.candidateFormGroupMap[candidate.candidateId][(data[day].date as Date).toDateString()] || {
                formGroup: new FormGroup({}),
                initialValue: { attendance: null, assignment: null }
              }
              this.candidateFormGroupMap[candidate.candidateId][(data[day].date as Date).toDateString()].formGroup = data[day].formGroup;
              this.candidateFormGroupMap[candidate.candidateId][(data[day].date as Date).toDateString()].initialValue = data[day].initialValues;
            } else if (!this.candidateFormGroupMap[candidate.candidateId]) {
              this.candidateFormGroupMap[candidate.candidateId] = this.candidateFormGroupMap[candidate.candidateId] || {};
              this.candidateFormGroupMap[candidate.candidateId][(data[day].date as Date).toDateString()] = this.candidateFormGroupMap[candidate.candidateId][(data[day].date as Date).toDateString()] || {
                formGroup: new FormGroup({}),
                initialValue: { attendance: null, assignment: null }
              }
              this.candidateFormGroupMap[candidate.candidateId][(data[day].date as Date).toDateString()].formGroup = data[day].formGroup;
              this.candidateFormGroupMap[candidate.candidateId][(data[day].date as Date).toDateString()].initialValue = data[day].initialValues;
            }

          }
          //FormGroup Calculation
        }
      })
      this.dataSource.push(data);
      this.dataSource = [...this.dataSource];
    })
  }

  processDateMap() {
    this.days.forEach(day => {
      const waar = day.toDateString().split(' ')[0]
      this.daysMap[waar] = day;
    })
  }

  incrementWeek() {
    this.week++;
    // if(this.week>4) {
    //   this.month++;
    //   this.week=1;
    // }
    this.days = this.getDaysOfWeek(this.year, this.month, this.week);
    this.processDateMap();
    this.getCalendarData();
  }

  decrementWeek() {
    this.week--;
    // if(this.week<1) {
    //   this.month--;
    //   this.week = 4;
    // }
    this.days = this.getDaysOfWeek(this.year, this.month, this.week);
    this.processDateMap();
    this.getCalendarData();
  }

  getDaysOfWeek(year: number, month: number, weekNumber: number, inputtedDate?: Date) {
    const startDate = new Date(year, month - 1, 1);
    const firstDayOfMonth = startDate.getDay();
    const firstDayOfWeek = (weekNumber - 1) * 7 - firstDayOfMonth + 1;
    const daysOfWeek = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(year, month - 1, firstDayOfWeek + i);
      if (inputtedDate) { // Check if inputtedDate is provided
        if (currentDate >= inputtedDate) {
          daysOfWeek.push(currentDate);
        }
      } else {
        daysOfWeek.push(currentDate);
      }
    }

    return daysOfWeek;
  }


  getControlValue(candidateDetails: any, control: 'Assignment' | 'Attendance', date: string, recordData: any): null | boolean {
    let assignmentControlValue = null;
    let attendanceControlValue = null;
    // const random = Math.random() * 5;
    // if (random > 2.5)
    //   return false;
    // else return true;
    // // return null;

    switch (control) {
      case 'Assignment':
        this.calendarData.map(data => {
          if (data.batchId === candidateDetails.batchId) {
            data.candidateRecordsResponseDtoList.map(record => {

              if (record.candidateId === candidateDetails.candidateId && new Date(record.recordDate).toDateString() === date) {
                assignmentControlValue = record.assignmentStatus === 0 ? false : record.assignmentStatus === 1 ? true : null;
                recordData.recordId = record.candidateRecordsId;
              }
            })
          }
        });
        return assignmentControlValue;
        break;
      case 'Attendance':
        this.calendarData.map(data => {
          if (data.batchId === candidateDetails.batchId) {
            data.candidateRecordsResponseDtoList.map(record => {
              if (record.candidateId === candidateDetails.candidateId && new Date(record.recordDate).toDateString() === date) {
                attendanceControlValue = record.attendanceStatus === 0 ? false : record.attendanceStatus === 1 ? true : null;
                recordData.recordId = record.candidateRecordsId;
              }
            })
          }
        });
        return attendanceControlValue;
        break;
    }
  }

  pushAnEntry(entryDetails: ICandidateTree) {
    let found = false;
    this.changesArray.forEach(change => {
      if (change.candidateDetails.candidateId === entryDetails.candidateDetails.candidateId) {
        const changesToPush : any[] = [];
        change.Dates =
          change.Dates.map(date => {
            if (date.date === entryDetails.Dates[0].date) {
              date = entryDetails.Dates[0]
            } else {
              changesToPush.push(entryDetails.Dates[0])
            }
            return date;
          })
          change.Dates.push(...changesToPush);
        found = true;
      }
    })
    if (!found)
      this.changesArray.push(entryDetails);
    this.changesArray = _.cloneDeep(this.changesArray);
  }

  popAnEntry(entryDetails: any) {
    let datesEmptied = false;
    this.changesArray.forEach(change => {
      if (change.candidateDetails.candidateId === entryDetails.candidateDetails.candidateId) {
        change.Dates = change.Dates.filter((date: any) => date.date !== entryDetails.date);
        if (change.Dates.length === 0) {
          datesEmptied = true;
        }
      }
    })

    if (datesEmptied) {
      this.changesArray = this.changesArray.filter(change => {
        return change.candidateDetails.candidateId !== entryDetails.candidateDetails.candidateId
      })
    }
    this.changesArray = _.cloneDeep(this.changesArray);
  }

  calculateWeekNumber(date: Date): number {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const pastDaysOfMonth = date.getDate() - 1;
    return Math.ceil((pastDaysOfMonth + firstDayOfMonth.getDay()) / 7);
  }

  getWeekdaysArray(date: Date): Date[] {
    const weekdaysArray: Date[] = [];

    // Find the Monday of the inputted date's week
    const monday = new Date(date);
    monday.setDate(date.getDate() - (date.getDay() + 6) % 7);

    // Add each weekday's date to the array
    for (let i = 0; i < 7; i++) {
      const weekday = new Date(monday);
      weekday.setDate(monday.getDate() + i);
      weekdaysArray.push(weekday);
    }

    return weekdaysArray;
  }

  getCalendarWithDate() {
    this.datePicker.valueChanges.subscribe({
      next: (value) => {
        if (value) {
          this.days = this.getWeekdaysArray(value._d)
          this.processDateMap();
          this.getCalendarData();
          // this.prepareDataSource();
        }

      }
    })
  }

  toDisableToday(): boolean {
    let found = false;
    this.days.map((day: Date) => {
      if (day.toDateString() === new Date().toDateString())
        found = true;
    })
    return found;
  }

  onTodayClicked() {
      this.year = parseInt(this.datePipe.transform(new Date(), 'YYYY') || '-1');
      this.month = parseInt(this.datePipe.transform(new Date(), 'MM') || '-1');
      this.week = this.calculateWeekNumber(new Date());
      this.days = this.getDaysOfWeek(this.year, this.month, this.week);
      this.processDateMap();
      this.datePicker.patchValue('');
      this.getCalendarData()
  }

  onResetClick() {
    this.changesArray.forEach(change => {
      change.Dates.forEach(date => {
        date.formGroup.get('attendance')?.patchValue(date.initialValue.attendance);
        date.formGroup.get('assignment')?.patchValue(date.initialValue.assignment);
        this.eventService.reset.emit({
          candidateId: change.candidateDetails.candidateId,
          date: date.date
        })
      })
    })
    this.emptyFormGroupMap();
  }

  onSubmitClick() {
    console.clear();
    let payload: any = [];
    this.changesArray.map(change => {
      change.Dates.map(date => {
        payload.push({
          "candidateRecordsId": date.recordId,
          "candidateId": change.candidateDetails.candidateId,
          "batchId": change.candidateDetails.batchId,
          "recordDate": this.datePipe.transform(date.date, 'YYYY-MM-dd'),
          "assignmentStatus": this.getPayloadControlValue(date.formGroup.value.assignment),
          "attendanceStatus": this.getPayloadControlValue(date.formGroup.value.attendance)
        })
      })
    })
    payload = _.cloneDeep(payload);
    this.calendarService.submitRecord(payload).subscribe({
      next: (response: any) => {
        this.snackbarService.openSnackBar(response.message, 'snackbar-success');
        this.onResetClick();
        this.getCalendarData();
      }
    })

  }

  getFormGroup(candidate: any, date: string, data: any): FormGroup {
    if (this.candidateFormGroupMap[candidate.candidateId] && this.candidateFormGroupMap[candidate.candidateId][date]) {
      return this.candidateFormGroupMap[candidate.candidateId]?.[date]?.formGroup;
    }

    return new FormGroup({
      attendance: new FormControl(this.getControlValue(candidate, 'Attendance', date, data)),
      assignment: new FormControl(this.getControlValue(candidate, 'Assignment', date, data))
    })
  }

  getInitialValue(candidateId: number | string, date: string): { attendance: null | boolean, assignment: null | boolean } | null {

    if (this.candidateFormGroupMap[candidateId] && this.candidateFormGroupMap[candidateId][date]) {
      return this.candidateFormGroupMap[candidateId]?.[date]?.initialValue;
    }
    return null;
  }

  getCalendarData() {
    const currentRole = this.authService.getCurrentRole();
    let payload: any;
    if (currentRole === 'admin' || currentRole === 'super_admin') {
      payload = {
        "batchIdList": [
          this.batchId
        ],
        "startDate": this.datePipe.transform(this.days[0], 'YYYY-MM-dd'),
        "endDate": this.datePipe.transform(this.days[6], 'YYYY-MM-dd')
      }
    } else if (currentRole === 'trainer') {

      const endDate = new Date(this.days[6]).setDate(this.days[6].getDate() + 1)
      const startDate = new Date(this.days[0]).setDate(this.days[0].getDate() + 1)
      payload = {
        "batchIdList": this.getBatchIdList(),
        "startDate": this.datePipe.transform(this.days[0], 'YYYY-MM-dd'),
        "endDate": this.datePipe.transform(this.days[6], 'YYYY-MM-dd')
      }
    }
    this.calendarService.getCalendarData(payload).subscribe({
      next: (response: ICalendarRes[]) => {
        this.calendarData = response;
        this.prepareDataSource();
      }
    })
  }

  getCandidates() {
    const currentRole = this.authService.getCurrentRole();
    if (currentRole === 'admin' || currentRole === 'super_admin') {
      this.calendarService.getCandidates(this.batchId).subscribe({
        next: (response: any) => {
          let candidates = response.contents.map((content: any) => {
            return {
              candidateId: content.userId,
              batchId: content.batchId,
              candidateName: `${content.firstName} ${content.lastName}`
            }
          });
          this.calendarService.setCandidates(candidates);
          this.totalCount = candidates.length;
          this.eventService.setPaginationCount.emit(this.totalCount)
          this.candidates = this.getPaginatedCandidates().candidates;
          this.maxPages = this.getPaginatedCandidates().maxPage;
          this.getCalendarData();
        }
      })

    } else if (currentRole === 'trainer') {
      this.calendarService.getTrainerCandidates().subscribe({
        next: (response) => {
          // try {
          let candidates: any[] = [];
          candidates = response.candidates.map((content: any) => {
            return {
              candidateId: content.userId,
              batchId: content.batchId,
              candidateName: `${content.firstName} ${content.lastName}`
            }
          });
          this.calendarService.setCandidates(candidates);
          this.totalCount = candidates.length;
          this.eventService.setPaginationCount.emit(this.totalCount)
          this.candidates = this.getPaginatedCandidates().candidates;
          this.maxPages = this.getPaginatedCandidates().maxPage;
          this.getCalendarData();
          // } catch(e) {
          //     alert(e)
          // }
        }

      })
    }
  }

  getBatchIdList(): number[] {
    const batchIdList: number[] = [];
    this.candidates.map(candidate => {
      if (!batchIdList.includes(candidate.batchId)) {
        batchIdList.push(candidate.batchId);
      }
    })
    return batchIdList;
  }

  emptyFormGroupMap() {
    this.candidateFormGroupMap = {}
  }

  getPayloadControlValue(value: boolean | null): 0 | 1 | null {
    switch (value) {
      case true:
        return 1;
      case false:
        return 0;
      default:
        return null;

    }
  }

  getPaginatedCandidates(searchText?: string, pageNumber?: number): any {
    return this.calendarService.getPaginatedCandidates(searchText, pageNumber);

  }

  setPageSize() {
    this.calendarService.setPageSize(this.pageSize);
  }

  searchCandidate() {
    this.searchField.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe({
      next: (value) => {
        this.candidates = this.getPaginatedCandidates(value || '').candidates;
        this.maxPages = this.getPaginatedCandidates(value || '').maxPage;
        this.prepareDataSource();

      }
    })
  }

  handlePagination(paginationData: any) {
    this.candidates = this.getPaginatedCandidates('', paginationData.pageNumber).candidates;
    this.maxPages = this.getPaginatedCandidates('', paginationData.pageNumber).maxPage;
    this.getCalendarData();
  }
}
