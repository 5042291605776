import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../structs';
import { AuthService } from './../../services/auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class SessionGuard implements CanActivate {

	constructor(private authService: AuthService, private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// if url contains 'admin' then the user will be navigated directly to that route.
		if((state.url.includes('admins')) && !(this.authService.getCurrentRole()===PRIVATE_ROUTES.superAdmin)){
			this.router.navigate([this.authService.getCurrentRole()])
			return false;
		}
		if (state.url.includes('admin') || state.url.includes(PRIVATE_ROUTES.superAdmin)) {
			return true;
		}
		else {
			/*if url does not contain 'admin' and the user is logged in then route to that url and attach /admin to the entered string and navigate accordingly.
			 If that route is a wildcard route, then it has been handled in admin routing.*/
			if (this.authService.isLoggedIn()) {
				this.router.navigate([`${this.authService.getCurrentRole()}/${state.url}`]);
				return true;
			}
			//if user is not logged In then navigate them back to the login page.
			else {
				this.router.navigate([PUBLIC_ROUTES.auth]);
			}
		}
		return false;
	}

}
