import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InterviewerService } from 'src/app/core/services/interviewer/interviewer.service';
import { MentorService } from 'src/app/core/services/mentor/mentor.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { OPERATIONS } from 'src/app/core/structs/operations.enum';
import { MentorFeedbackCommentComponent } from 'src/app/modules/mentor/components/mentor-feedback-comment/mentor-feedback-comment.component';
@Component({
  selector: 'app-interviewers-feedback-dialog',
  templateUrl: './interviewers-feedback-dialog.component.html',
  styleUrls: ['./interviewers-feedback-dialog.component.scss']
})
export class InterviewersFeedbackDialogComponent implements OnInit {
  editPayload:any;
  submitClicked = false;
  control !: FormControl;
  injectingData = false;
  feedbackLength: number | undefined = 0;
  error = false;
  valuesChanged = false;
  keyMapping: any = {
    logic: 'logic',
    logicComment: 'logicComment',
    proActiveness: 'proactiveness',
    proActivenessComment: 'proactivenessComment',
    ProblemSolving: 'problemSolving',
    ProblemSolvingComment: 'problemSolvingComment',
    communication: 'communication',
    communicationComment: 'communicationComment',
  }
  keys=[{key:'logic',comment:'logicComment'},{key:'proActiveness',comment:'proactivenessComment'},{key:'problemSolving',comment:'problemSolvingComment'},{key:'communication',comment:'communicationComment'}]
  feedbackParameters = [{
    id: 0, key: "Logic", param: 'logic', value: 0, comment: '', icon: 'comment-icon',
    showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
  },
  {
    id: 1, key: "Proactiveness", param: 'proActiveness', value: 0, comment: '', icon: 'comment-icon',
    showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
  },
  {
    id: 3, key: "Problem Solving", param: 'problemSolving', value: 0, comment: '', icon: 'comment-icon',
    showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
  },
  {
    id: 4, key: "Communication", param: 'communication', value: 0, comment: '', icon: 'comment-icon',
    showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
  }]
  feedbackFormGroup = new FormGroup({
    logic: new FormGroup({
      slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
      comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
    }),
    proActiveness: new FormGroup({
      slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
      comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
    }),
    communication: new FormGroup({
      slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
      comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
    }),
    problemSolving: new FormGroup({
      slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
      comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
    }),
    overallFeedback: new FormControl('', [Validators.required, Validators.maxLength(255)])
  }) as FormGroup<any>;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InterviewersFeedbackDialogComponent>,
    private _snackbar: SnackbarService,
    private interviewService:InterviewerService) { }
    ngOnInit(): void {
    
      if (this.data.operation === OPERATIONS.EDIT) {
        for(const parameter of this.keys){
          
          if(parameter.key === 'proActiveness'){
            this.feedbackFormGroup?.get(`${parameter.key}`)?.get('comment')?.patchValue(this.data.data.feedbackDto[parameter.comment]);
            this.feedbackFormGroup?.get(`${parameter.key}`)?.get('slider')?.patchValue(this.data.data.feedbackDto['proactiveness']);
          }else{
            this.feedbackFormGroup?.get(`${parameter.key}`)?.get('comment')?.patchValue(this.data.data.feedbackDto[parameter.comment]);
            this.feedbackFormGroup?.get(`${parameter.key}`)?.get('slider')?.patchValue(this.data.data.feedbackDto[parameter.key]);
          }
        }
        this.injectingData = false;
        this.feedbackFormGroup.get('overallFeedback')?.patchValue(this.data.data.feedbackDto.overAllFeedback);
      }
      this.feedbackFormGroup.get('overallFeedback')?.valueChanges.subscribe({
        next: (value: any) => {
          this.feedbackLength = value.length;
        }
      });
      this.markSliderControlsAsTouched();
      this.valuesChangeDetector();
      this.feedbackLength = this.feedbackFormGroup.get('overallFeedback')?.value?.length;
    }
  getControl(index: number, control: string) {
    return this.feedbackFormGroup.get(`${this.feedbackParameters[index].param}`)?.get(control) || new FormControl('') as FormControl;
  }
  markSliderControlsAsTouched() {
    this.feedbackParameters.forEach((parameter: any, index: number) => {
      this.feedbackFormGroup?.get(`${parameter.param}`)?.get('slider')?.valueChanges.subscribe({
        next: (value: any) => {
          
          this.feedbackFormGroup?.get(`${parameter.param}`)?.get('slider')?.markAsTouched()
        }
      })
    })
  }
  getKey(key: string) {
    return this.keyMapping[key];
  }
  onCancelClick() {
    this.feedbackFormGroup.reset();
    this.dialogRef.close();
  }
  formSubmission() {
    this.submitClicked = true;
    const message="Feedback added successful";
    if(this.feedbackFormGroup.valid){
     
      const payload: any = {
        "interviewId": this.data.data.interviewId,
        "batchId": this.data.data.batchId,
        "userId": this.data.data.studentId,
        "interviewLevel": this.data.data.interviewLevel,
        "interviewDate": this.data.data.interviewDate
      }
      
      this.feedbackParameters.forEach((parameter: any) => {
        payload[`${this.getKey(parameter.param)}`] = this.feedbackFormGroup.get(parameter.param)?.get('slider')?.value;
        payload[this.getKey(`${parameter.param}Comment`)] = this.feedbackFormGroup.get(parameter.param)?.get('comment')?.value;
      });
      payload.problemSolving=this.feedbackFormGroup.get('problemSolving')?.get('slider')?.value;
      payload.problemSolvingComment=this.feedbackFormGroup.get('problemSolving')?.get('comment')?.value;
      payload.overAllFeedback=this.feedbackFormGroup.value.overallFeedback;
      
      if (this.data.operation === OPERATIONS.ADD) {
        this.interviewService.addInterviewFeedback(payload).subscribe({
          next: (response: any) => {
           
            this._snackbar.openSnackBar(response.message, 'snackbar-success');
            this.dialogRef.close('success');
          },
          error: (error: any) => {
            this._snackbar.openSnackBar(error.error.message, 'snackbar-error');
          }
        })
      }else if (this.data.operation === OPERATIONS.EDIT) {
        const editPayload : any = {
          "feedbackId" : this.data.data.feedbackDto.feedbackId,
        }
        this.feedbackParameters.forEach((parameter: any) => {
          editPayload[`${this.getKey(parameter.param)}`] = this.feedbackFormGroup.get(parameter.param)?.get('slider')?.value;
          editPayload[this.getKey(`${parameter.param}Comment`)] = this.feedbackFormGroup.get(parameter.param)?.get('comment')?.value;
        });
        editPayload.problemSolving=this.feedbackFormGroup.get('problemSolving')?.get('slider')?.value;
        editPayload.problemSolvingComment=this.feedbackFormGroup.get('problemSolving')?.get('comment')?.value;
        editPayload.overAllFeedback=this.feedbackFormGroup.value.overallFeedback;
        
        this.interviewService.updateInterviewFeedback(editPayload).subscribe({
          next: (response: any) => {
            
            this._snackbar.openSnackBar(response.message, 'snackbar-success');
            this.dialogRef.close('success');
          },
          error: (error: any) => {
            this._snackbar.openSnackBar(error.error.message, 'snackbar-error');
          }
        })
      }
    }
  }
  valuesChangeDetector() {
    this.feedbackFormGroup.valueChanges.subscribe({
        next: () => {
            this.valuesChanged = true;
        }
    })
  }
}
