<div>
    <form [formGroup]="feedbackFormGroup">
        <div class="feedback-header">
            <mat-icon svgIcon="feedback-dialog-logo" class="feedback-dialog-logo"></mat-icon>
            <h5>{{ data.operation === "edit" ? "Edit Feedback" : "Add Feedback"}}</h5>
        </div>
        <form class="date-picker" *ngIf="data.operation!=='edit'" [formGroup]="$any(feedbackFormGroup.get('date'))">
            <label>Duration<span class="asterisk"> *</span></label>
            <mat-form-field appearance="fill" floatLabel="always" (click)="picker.open()"
                [ngClass]="{'invalid-input-box':dateError}">
                <mat-icon matPrefix svgIcon="datepicker"></mat-icon>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" formControlName="startDate" id="startdate"
                        (dateInput)="setMaxDate()">
                    <input matEndDate formControlName="endDate" placeholder="End date" (dateInput)="setMaxDate()">
                </mat-date-range-input>
                <mat-date-range-picker #picker [calendarHeaderComponent]="exampleHeader">
                </mat-date-range-picker>
            </mat-form-field>
            <mat-error
                *ngIf="$any(feedbackFormGroup.get('date')).invalid && submitClicked || ($any(feedbackFormGroup.get('date')).touched && $any(feedbackFormGroup.get('date')).invalid )"
                class="validation-msg">
                Please choose the duration
            </mat-error>
        </form>
        <div class="feedback-options-wrapper">
            <form *ngFor="let parameter of feedbackParameters; let i = index" class="feedback-parameter-wrapper">
                <p class="feedback-parameter">{{parameter.key}}</p>
                <div class="parameter-field">
                    <app-slider [parameterId]="parameter.id" [initialValue]="getControl(i, 'slider')?.value"
                        (valueSelected)="getControl(i, 'slider').patchValue($event.value)"
                        [error]="(getControl(i, 'slider').invalid && submitClicked || (getControl(i, 'slider').touched && getControl(i, 'slider').invalid))">
                    </app-slider>
                    <div class="feedback-comment">
                        <app-mentor-feedback-comment [control]="$any(getControl(i, 'comment'))"
                            [error]="(getControl(i, 'comment').invalid && submitClicked || (getControl(i, 'comment').touched && getControl(i, 'comment').invalid)) || getControl(i, 'comment')?.hasError('maxlength')? true : false"></app-mentor-feedback-comment>
                    </div>
                </div>
                <mat-error
                    *ngIf="(getControl(i, 'comment').invalid && submitClicked || (getControl(i, 'comment').touched && getControl(i, 'comment').invalid))"
                    class="validation-msg">{{getControl(i, 'comment')?.hasError('maxlength')? 'Max character limit : 255' : 'You need to add feedback' }}</mat-error>
            </form>
            <div class="overall-feedback-container">
                <p class="feedback-parameter overall-feedback"><span>Overall feedback</span>
                    <span>{{feedbackLength}}/255</span>
                </p>

                <textarea class="comment-textarea" placeholder="Enter a description..."
                    [ngClass]="{'textarea-error': ($any(feedbackFormGroup.get('overallFeedback')).touched && $any(feedbackFormGroup.get('overallFeedback')).invalid) || ($any(feedbackFormGroup.get('overallFeedback')).invalid && submitClicked)}"
                    [formControl]="$any(feedbackFormGroup.get('overallFeedback'))" maxlength="255"></textarea>
                <mat-error
                    *ngIf="($any(feedbackFormGroup.get('overallFeedback')).touched && $any(feedbackFormGroup.get('overallFeedback')).invalid) || ($any(feedbackFormGroup.get('overallFeedback')).invalid && submitClicked)"
                    [formControl]="$any(feedbackFormGroup.get('overallFeedback'))">Overall
                    Feedback Is
                    Required!
                </mat-error>
            </div>
        </div>
        <div class="feedback-dialog-footer">
            <button mat-flat-button disableRipple class="secondary sm" type="button" (click)="onCancelClick()">
                Cancel
            </button>
            <button mat-flat-button color="primary" disableRipple class="sm" type="submit" (click)="formSubmission()"  [disabled]=" !valuesChanged">
                {{ data.operation === "edit" ? "Update" : "Submit"}}
            </button>
        </div>
    </form>
</div>