import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-feedback-comment',
	templateUrl: './feedback-comment.component.html',
	styleUrls: ['./feedback-comment.component.scss']
})
export class FeedbackCommentComponent implements OnChanges, OnInit{

	feedbackLength = 0;
	enableEdit=false;
	commentValue = new FormControl('');
	placeholderText = ``;
	@Input() parameter = 0;
	@Input() feedbackComment = '';
	@Input() mode !: string;
	@Output() saveClicked = new EventEmitter();
	@Output() cancelClicked = new EventEmitter();
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	constructor() { }

	ngOnChanges(): void {
		this.commentValue.patchValue(this.feedbackComment);
		this.feedbackLength=this.feedbackComment.length;
		this.commentValue.valueChanges.subscribe({
			next:(response:any)=>{
				this.feedbackLength=response.length;
			}
		})
		this.enableEdit = this.mode==='edit'?false : true;
	}

	ngOnInit(): void {
		if(this.mode==='edit')
		this.onEditClick();
	}

	onEditClick(){
		this.enableEdit=!this.enableEdit;
	}

	onCancelClick() {
		this.onEditClick();
		this.cancelClicked.emit({ id: this.parameter, comment: '' });
	}
	onSaveClick() {
		this.saveClicked.emit({ id: this.parameter, comment: this.commentValue.value });
	}

	isTextAreaDisabled(){
		return !this.enableEdit;
	}

}
