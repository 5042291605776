import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, PUBLIC_ROUTES, RegexContainer } from 'src/app/core';
import { GoogleApiService } from 'src/app/core/services/google-api/google-api.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ICustomLoginResponse } from './../../../../core/models/custom-login-response';


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	token = '';
	error = '';
	data: any;
	authCode: string | null = "";
	authToken: string | null = "";
	env = true;

	constructor(private router: Router, private authService: AuthService, private _snackBar: SnackbarService, private route: ActivatedRoute, private googleApiService: GoogleApiService) {

		route.fragment.subscribe((fragments: any) => {
            const params = new URLSearchParams(fragments);
            this.authCode = params.get('code');
            this.authToken = params.get('id_token');
        })

		if(this.authCode && this.authToken)	{
			this.router.navigate([PUBLIC_ROUTES.auth, PUBLIC_ROUTES.authChooseRole])
			this.fetchGoogleSignInData();
		}

		this.data = localStorage.getItem('userDetails')
		const userDetails = JSON.parse(this.data)
		
		if(userDetails!==null){
			if (userDetails.firstTimeLogin === 2) {
				authService.logout()
			}
		}
	}

	showPassword = false;

	loginForm = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.pattern(RegexContainer.emailPattern)]),
		password: new FormControl('', [Validators.required]),
		rememberMe: new FormControl(false)
	})

	ngOnInit(): void {
		// if(this.authCode && this.authToken)	this.findUserDetails();
		if (this.error) {
			this._snackBar.openSnackBar(this.error, 'snackbar-error');
		}
	}

	/**
	 * sends login details to the service to login an external user into the system
	 * @param loginDetails external user's email and password
	 */
	loginWithData(loginDetails: FormGroup) {
		this.authService.customLogin(loginDetails.value).subscribe({
			next: (response: ICustomLoginResponse) => {
				if (response) {
					this.authService.setToken(response.token);
					if (response.firstTimeLogin == 2) {
						const firstTimeLogin = response.firstTimeLogin;
						this.authService.findUserDetails().subscribe({
							next: (res: any) => {
								this.authService.setUserDetails({ ...res, firstTimeLogin });
								this.router.navigate([`${PUBLIC_ROUTES.auth}/${PUBLIC_ROUTES.authResetPassword}`]);
							},
						});
						
					}
					else {
						this.findUserDetails();
						// this.router.navigate(['/admin']);
					}

				}
			},
			error: (error: HttpErrorResponse) => {
				this._snackBar.openSnackBar(error.error.message, 'snackbar-error', 'bottom', 'center');
			}
		})
		//send login Data to authService
	}

	/** to navigate the user to forgot password page.
	 *
	 */
	forgotPassword() {
		this.router.navigate(['auth/forgot-password']);
	}

	/** to toggle the visibility of the password on the login screen
	 *
	 */
	togglePassword() {
		this.showPassword = !this.showPassword;
	}

	/** to sign-in an existing Coditas user
	 *
	 */
	googleSignIn() {
		// window.open(AUTH_URL, '_self');
		this.googleApiService.login();
	}

	/**
	 * verifies Token
	 * verify the token and as per incoming data redirect to respective page
	 */
	findUserDetails() {
		this.authService.findUserDetails().subscribe({
			next: (res: any) => {
				this.authService.setUserDetails(res);
				const roles = this.authService.getRoles();
				if(roles?.length>1)
				this.router.navigate([PUBLIC_ROUTES.auth,PUBLIC_ROUTES.authChooseRole])
				else
				{
					this.router.navigate([this.authService.getCurrentRole()]);
				}
			},
		});
	}

	fetchGoogleSignInData() {
		const authPayload = {
			code: this.authCode,
			idToken: this.authToken,
			env: window.location.host !== 'localhost:4200'? true : false
		}
		this.authService.getAuthData(authPayload).subscribe({
			next: (response: any) => {
				this.authService.setUserDetails(response);
				const roles = this.authService.getRoles();
				if(roles?.length>1)
				this.router.navigate([PUBLIC_ROUTES.auth,PUBLIC_ROUTES.authChooseRole])
				else
				{
					this.router.navigate([this.authService.getCurrentRole()]);
				}
			},
			error: (error: any) => {
				this.router.navigate([PUBLIC_ROUTES.auth]);
				if(error.error?.message) this._snackBar.openSnackBar(error.error?.message, 'snackbar-error')
			}
		});
	}
}

