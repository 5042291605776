<div class="login-container">
	<h2>Sign in to your</h2>
	<h2>account</h2>

	<form
		[formGroup]="loginForm"
		(ngSubmit)="loginWithData(loginForm)"
		class="form-container"
	>
		<div>
			<label>Email</label>
			<mat-form-field appearance="fill" floatLabel="always" class="social-login">
				<mat-icon matPrefix svgIcon="envelop"></mat-icon>
				<input
					type="email"
					matInput
					formControlName="email"
					placeholder="Enter your email"
				/>
				<mat-error
					*ngIf="
						loginForm.controls['email'].hasError('email') &&
						!loginForm.controls['email'].hasError('required')
					"
				>
					Please enter a valid email address
				</mat-error>
				<mat-error
					*ngIf="loginForm.controls['email'].hasError('required')"
				>
					Email required
				</mat-error>
			</mat-form-field>
		</div>

		<div>
			<label>Password</label>
			<mat-form-field appearance="fill" floatLabel="always" class="social-login">
				<mat-icon matPrefix svgIcon="lock"></mat-icon>
				<input
					[type]="showPassword ? 'text' : 'password'"
					matInput
					formControlName="password"
					placeholder="Enter password"
				/>
				<mat-icon
					matSuffix
					[svgIcon]="showPassword ? 'eye' : 'eye-slash'"
					(click)="togglePassword()"
				>
				</mat-icon>
				<mat-error
					*ngIf="loginForm.controls['password'].hasError('required')"
				>
					Password required
				</mat-error>
			</mat-form-field>
		</div>

		<div class="actions-container">
			<div class="top-actions">
				<div class="checkbox">
					<mat-checkbox formControlName="rememberMe" value="{{!loginForm.controls['rememberMe']}}">Remember me </mat-checkbox>
				</div>
				<button mat-flat-button disableRipple class="tertiary forgot-password" (click)="forgotPassword()" type="button">
					Forgot Password?
				</button>
			</div>
			<button mat-flat-button color="primary" disableRipple class="sm sign-in-btn" type="submit">
				Sign In
			</button>
		</div>
	</form>
	<div class="bottom-actions">
		<div class="separator caption-1">
			<mat-divider></mat-divider>
			<span>Only for Coditas Users </span>
			<mat-divider></mat-divider>
		</div>
		<button mat-flat-button class="social-login" (click)="googleSignIn()">
			<mat-icon svgIcon="google-signin"></mat-icon>
			Sign In With Google
		</button>
	</div>
</div>
