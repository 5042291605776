import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BatchService } from './admin';
import { TrainerUserService } from './trainer/trainer.service';
import { AuthService } from './auth/auth.service';
import _ from 'lodash';

export interface ICalendarReq {
  batchIdList: number[],
  startDate: string | Date,
  endDate: string | Date
}

export interface ICandidatesRecords {
  "candidateRecordsId": number,
  "candidateId": number,
  "recordDate": string,
  "assignmentStatus": 0 | 1 | null,
  "attendanceStatus": 0 | 1 | null

}

export interface ICalendarRes {
  batchId: number,
  batchName: string,
  candidateRecordsResponseDtoList: ICandidatesRecords[]
}

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  #candidates : {candidateId : number, batchId : number, candidateName : string}[] = [];
  #pageNumber : number = 0;
  #pageSize : number = 4;
  constructor(private http: HttpClient, private batchService: BatchService, private trainerService : TrainerUserService, private authService : AuthService) { }

  getCalendarData(reqBody: ICalendarReq): Observable<ICalendarRes[]> {
    return this.http.post('candidateRecords/getAllCandidateRecords', reqBody) as Observable<ICalendarRes[]>
  }

  getCandidates(batchId: number) {
    const payload = {
      column: " ",
      isActive: 1,
      order: "",
      page: 0,
      pageSize: 1000,
      searchParams: [],
      searchText: " "
    }

    return this.batchService.getParticularBatchDetails(batchId, payload);

  }

  submitRecord(records : any) {
    return this.http.post('candidateRecords/addCandidateRecords', records)
  }

  getTrainerCandidates() {
    const payload = {
      isActive : 1,
      page : 0,
      pageSize : 1000,
      searchText : '',
      totalAverageRangeFilter : {
        minAvg : 0,
        maxAvg : 10
      },
      trackIds: null,
      trainerId : this.authService.getUserDetails().userId
    }
    return this.trainerService.getAllCandidatesForTrainer(this.http, payload);
  }

  setPageSize(size : number) {
    this.#pageSize = size;
  }

  setPageNumber(pageNumber : number) {
    this.#pageNumber = pageNumber
  }

  getPaginatedCandidates(searchText? : string, pageNumber? : number) :  { candidates : {candidateId : number, batchId : number, candidateName : string}[], maxPage : number} {
    if(searchText) {
      const candidates = _.cloneDeep(this.#candidates).filter(candidate=>candidate.candidateName.toLowerCase().includes(searchText.toLowerCase()))
      return {
        candidates : candidates.slice(this.#pageNumber, (this.#pageSize * this.#pageNumber)+ this.#pageSize),
        maxPage : candidates.length/this.#pageSize
      }
    }

    if(pageNumber) {
      return  {
        candidates : _.cloneDeep(this.#candidates).slice(pageNumber*this.#pageSize, (this.#pageSize * pageNumber) + this.#pageSize),
        maxPage : _.cloneDeep(this.#candidates).length/this.#pageSize
      }
    }
      return {
        candidates : _.cloneDeep(this.#candidates).slice(this.#pageNumber*this.#pageSize, (this.#pageSize * this.#pageNumber)+ this.#pageSize),
        maxPage : _.cloneDeep(this.#candidates).length/this.#pageSize
      }
      
      
  }

  setCandidates(candidates : {candidateId : number, batchId: number, candidateName : string}[]) {
    this.#candidates = candidates;
  }
}
