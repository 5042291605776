import { Injectable } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { PUBLIC_ROUTES } from 'src/app/core';
import { AuthService, SnackbarService } from '../../services';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router, private snackbar: SnackbarService, private activatedRoute: ActivatedRoute) {

	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const url = route.url[0]
		// check reset password route
		if (state.url.includes(PUBLIC_ROUTES.authResetPassword)) {
			if (this.authService.isLoggedIn()) return true;
			this.router.navigate([PUBLIC_ROUTES.auth]);
			return false;
		}

		if (url && url.path.includes(PUBLIC_ROUTES.auth)) {
			if (!this.authService.getToken()) {
				return true;
			}
			else {
				try {
					if (state.url.includes(PUBLIC_ROUTES.authChooseRole) && this.authService.getCurrentRole()) {
						this.router.navigate([this.authService.getCurrentRole()])
						return true;
					}
					// get appropriate route for the current user's role
					if (state.url.includes(PUBLIC_ROUTES.authChooseRole)) {
						if (!this.authService.isLoggedIn()) {
							return true;
						}
						else {
							if (this.authService.getRoles().length > 1 && !this.authService.getCurrentRole())
								this.router.navigate([this.authService.getCurrentRole()]);
							return false;
						}
					}
					this.router.navigate([this.authService.getCurrentRole()]);
					return true;
				} catch (error) {
					return true;
				}
			}
		} else {
			//give permission to redirect only after successfull login
			if (this.authService.isLoggedIn()) {
				return true;
			} else {
				this.router.navigate([PUBLIC_ROUTES.auth]);
				return false;
			}
		}

	}
}
