import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainerUserService } from 'src/app/core/services/trainer/trainer.service';
import { AdminCandidateFeedbackComponent } from 'src/app/modules/admin/pages/admin-candidates/components/admin-candidate-feedback/admin-candidate-feedback/admin-candidate-feedback.component';
import { InterviewersFeedbackDialogComponent } from 'src/app/modules/interview/components/interviewers-feedback-dialog/interviewers-feedback-dialog.component';
import { MentorFeedbackDialogComponent } from 'src/app/modules/mentor/components/feedback-dialog/feedback-dialog.component';
import { FeedbackDialogComponent } from 'src/app/modules/trainer/components/feedback-dialog/feedback-dialog.component';
import { AuthService, DialogService } from '../../../app/core';
import { ParameterPromptComponent } from '../parameter-prompt/parameter-prompt.component';
import { ScoreChipComponent } from '../score-chip/score-chip.component';
import { OPERATIONS } from './../../core/structs/operations.enum';


@Component({
	selector: 'app-score-card',
	standalone: true,
	imports: [CommonModule, ScoreChipComponent, MatCardModule, MatChipsModule, MatDividerModule, MatIconModule, MatTooltipModule, MatButtonModule],
	templateUrl: './score-card.component.html',
	styleUrls: ['./score-card.component.scss']
})
export class ScoreCardComponent implements OnInit {
	currentRole!: string;

	@Input() interviewerFeedback: any;
	id: number;
	candidateId!: number;
	@Output() feedbackUpdated = new EventEmitter();

	constructor(private dialogService: DialogService, private trainerUserService: TrainerUserService, private authService: AuthService, private router: Router, private route: ActivatedRoute) {
		this.id = route.snapshot.params['id'];
	}
	ngOnInit(): void {

		this.currentRole = this.authService.getCurrentRole() || '';
		this.candidateId = this.id;
	}


	@Input() emptyFeedbackDetails: any;
	@Input() feedbackDetails: any;
	@Input() scoreDetails: any;
	@Input() editFeedback: any;
	@Input() feedbackType: any;
	@Input() interviewFeedbackType: any;
	@Input() role: any;
	@Output() interviewFeedbackAdded = new EventEmitter();
	@Input() interviewFeedbackKeys: any;
	@Input() feedbackTab = "";

	showFeedbackDialog() {
		if (this.role === "interviewer") {
			this.dialogService.openDialog(AdminCandidateFeedbackComponent, {
				data: this.feedbackDetails,
				width: '368px',
				panelClass: 'dialog',
				role: 'interviewer',
				keys: this.interviewFeedbackKeys
			})
		}
		else if (this.interviewerFeedback === true) {
			this.dialogService.openDialog(AdminCandidateFeedbackComponent, {
				data: this.scoreDetails,
				width: '368px',
				panelClass: 'dialog',
				role: 'interviewer',
				keys: this.interviewFeedbackKeys
			})
		}
		else {
			this.dialogService.openDialog(AdminCandidateFeedbackComponent, {
				data: this.scoreDetails,
				width: '368px',
				panelClass: 'dialog'
			})
		}
	}





	openEditFeedbackDialog() {
		switch (this.currentRole) {
			case 'trainer':
				this.dialogService.openDialog(FeedbackDialogComponent, {
					data: this.scoreDetails,
					candidateId: this.candidateId,
					operation: OPERATIONS.EDIT,
					panelClass: 'feedback-dialog'
				}).subscribe({
					next: (response: any) => {
						this.trainerUserService.emitFeedbackUpdated('updated');
					}
				})
				break;
			case 'mentor':
				this.dialogService.openDialog(MentorFeedbackDialogComponent, {
					data: this.scoreDetails,
					candidateId: this.candidateId,
					operation: OPERATIONS.EDIT,
					panelClass: 'feedback-dialog'
				}).subscribe({
					next: (response: any) => {
						this.feedbackUpdated.emit('success');
					}
				})
				break;
			case 'interview':
				this.dialogService.openDialog(MentorFeedbackDialogComponent, {
					data: this.scoreDetails,
					candidateId: this.candidateId,
					operation: OPERATIONS.EDIT,
					panelClass: 'feedback-dialog'
				}).subscribe({
					next: (response: any) => {
						this.feedbackUpdated.emit('success');
					}
				})
				break;
		}
	}

	showDialog(operation: string) {
		this.dialogService.openDialog(ParameterPromptComponent, {}).subscribe({
			next: (response) => {
				if (response) {

					switch (operation) {
						case 'add':

							this.dialogService.openDialog(InterviewersFeedbackDialogComponent, {
								data: this.emptyFeedbackDetails,
								panelClass: 'feedback-dialog',
								operation: OPERATIONS.ADD,
							}).subscribe({
								next: (resp: string) => {
									this.feedbackUpdated.emit('added');
								}
							})

							break;
						case 'edit':

							this.feedbackDetails.feedbackId = this.feedbackDetails.feedbackId;
							this.dialogService.openDialog(InterviewersFeedbackDialogComponent, {
								data: this.feedbackDetails,
								panelClass: 'feedback-dialog',
								operation: OPERATIONS.EDIT,
							}).subscribe({
								next: (resp: string) => {
									this.feedbackUpdated.emit('updated');
								}
							})
							break;
					}
				}
			}
		})
	}
}
