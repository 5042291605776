import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IQueryParams } from '../../models';

@Injectable({
	providedIn: 'root'
})
export class ServicesUtilityService {
	totalCount = new BehaviorSubject(1);

	/**
	   * Converts simple array into array of objects
	   *
	   * @param array The array to be converted
	   * @param key The key of an object
	   * @returns The array with object
	   */
	toObjectArray(array: [], key: string): { [key: string]: number }[] {
		return array.map((x: number) => {
			return { [key]: x };
		});
	}

	/**
	 * Creates & returns HttpParams object from IQueryParams object
	 *
	 * @param queryParams The query params object
	 * @returns The object of HttpParams
	 */
	createHttpParams(queryParams: IQueryParams): HttpParams {
		const { searchText, pageIndex, pageSize, page, role, order, column, isActive, trainerType, trainerId, programCategoryId, userId, status,batchId} = queryParams;
		let params = new HttpParams();
		searchText && (params = params.append('searchText', searchText));
		pageSize !== undefined && pageSize >= 0 && (params = params.append('pageSize', pageSize.toString()));
		page !== undefined && (params = params.append('page', page));
		pageIndex !== undefined && pageIndex >= 0 && (params = params.append('page', pageIndex.toString()));
		column && (params = params.append('column', column));
		order && (params = params.append('order', order));
		isActive !== undefined && isActive >= 0 && (params = params.append('isActive', isActive));
		role && (params = params.append('role', role));
		trainerType && (params = params.append('type', trainerType));
		trainerId !== undefined && trainerId >= 0 && (params = params.append('trainerId', trainerId));
		status !== undefined && status !== '' && (params = params.append('status', status));
		userId !== undefined && userId >= 0 && (params = params.append('userId', userId));
		batchId !== undefined && batchId >= 0 && (params = params.append('batchId', batchId));
		programCategoryId?.forEach((categoryId:any)=>{
			params= params.append('programCategoryId',categoryId);
		})

		return params;
	}
}
