import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EventService } from 'src/app/core/services/event/event.service';

@Component({
  selector: 'app-attendance-assignment',
  standalone: true,
  imports: [CommonModule, MatRadioModule, MatTooltipModule, ReactiveFormsModule],
  templateUrl: './attendace-assignment.component.html',
  styleUrls: ['./attendace-assignment.component.scss']
})
export class AttendanceAssignmentComponent implements OnInit, OnChanges {
  @Input() formGroup !: FormGroup;
  @Input() date !: Date ;
  @Input() candidateId !: number;
  @Input() formGroupInitialValue !: any;
  attendanceFormControl = new FormControl();
  assignmentFormControl = new FormControl();
  constructor(private eventService : EventService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.attendanceFormControl = this.formGroup.get('attendance') as FormControl || new FormControl();
    this.assignmentFormControl = this.formGroup.get('assignment') as FormControl || new FormControl();
  }

  ngOnInit(): void {
    this.attendanceFormControl = this.formGroup.get('attendance') as FormControl || new FormControl();
    this.assignmentFormControl = this.formGroup.get('assignment') as FormControl || new FormControl();

    this.eventService.reset.subscribe({
      next : (data:  {candidateId : number, date : string})=>{
        if(this.candidateId===data.candidateId && this.date.toDateString()===data.date) {
          this.formGroup.patchValue(this.formGroupInitialValue);
          this.attendanceFormControl = this.formGroup.get('attendance') as FormControl || new FormControl();
          this.assignmentFormControl = this.formGroup.get('assignment') as FormControl || new FormControl();
        }
      }
    })
  }
  assignAppropriateValue(controlValue: MatRadioChange, toChangeOf : 'Attendance' | 'Assignment') {
    // alert(this.date)
    switch (controlValue.value) {
      case 'true':
          switch(toChangeOf) {
            case 'Assignment' :
              this.assignmentFormControl.patchValue(true);
              break;
              case 'Attendance' :
                this.attendanceFormControl.patchValue(true);
                break;
          }
        break;
      case 'false':
        switch(toChangeOf) {
          case 'Assignment' :
            this.assignmentFormControl.patchValue(false);
            break;
            case 'Attendance' :
              this.attendanceFormControl.patchValue(false);
              break;
        }
        break;
    }
  }
}
