import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService, DialogService, SnackbarService, UtilityService } from 'src/app/core';
import { IDialogData } from 'src/app/core/models';
import { MentorService } from 'src/app/core/services/mentor/mentor.service';
import { DatepickerComponent } from 'src/app/shared/datepicker/datepicker.component';
import { ParameterPromptComponent } from 'src/app/shared/parameter-prompt/parameter-prompt.component';
import { OPERATIONS } from './../../../../core/structs/operations.enum';

@Component({
	selector: 'app-feedback-dialog',
	templateUrl: './feedback-dialog.component.html',
	styleUrls: ['./feedback-dialog.component.scss']
})
export class MentorFeedbackDialogComponent implements OnInit {
	exampleHeader = DatepickerComponent;
	maxAllowedDate!: Date;
	currentRange = 7;
	startDate = '';
	endDate = '';
	candidateId = 0;
	batchId!: number;
	mentorId!: number;
	injectingData = false;
	feedbackLength: number | undefined = 0;
	dateError = false;
	submitClicked = false;
	valuesChanged = false;
	keyMapping: any = {
		logic: 'logic',
		logicComment: 'logicComment',
		proActiveness: 'proactiveness',
		proActivenessComment: 'proactivenessComment',
		ProblemSolving: 'problemSolving',
		ProblemSolvingComment: 'problemSolvingComment',
		grasping: 'grasping',
		graspingComment: 'graspingComment',
		communication: 'communication',
		communicationComment: 'communicationComment',
		assignments: 'assignment',
		assignmentsComment: 'assignmentComments'
	}
	@ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) matAutocomplete: MatAutocompleteTrigger | undefined;

	feedbackParameters = [{
		id: 0, key: "Logic", param: 'logic', value: 0, comment: '', icon: 'comment-icon',
		showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
	},
	{
		id: 1, key: "Proactiveness", param: 'proActiveness', value: 0, comment: '', icon: 'comment-icon',
		showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
	},
	{
		id: 2, key: "Grasping", param: 'grasping', value: 0, comment: '', icon: 'comment-icon',
		showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
	},
	{
		id: 3, key: "Problem Solving", param: 'ProblemSolving', value: 0, comment: '', icon: 'comment-icon',
		showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
	},
	{
		id: 4, key: "Communication", param: 'communication', value: 0, comment: '', icon: 'comment-icon',
		showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
	},
	{
		id: 5, key: "Assignments", param: 'assignments', value: 0, comment: '', icon: 'comment-icon',
		showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true
	}]

	feedbackFormGroup = new FormGroup({
		date: new FormGroup({
			startDate: new FormControl('', [Validators.required]),
			endDate: new FormControl('', [Validators.required])
		}),
		logic: new FormGroup({
			slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
			comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
		}),
		proActiveness: new FormGroup({
			slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
			comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
		}),
		communication: new FormGroup({
			slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
			comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
		}),
		assignments: new FormGroup({
			slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
			comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
		}),
		grasping: new FormGroup({
			slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
			comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
		}),
		ProblemSolving: new FormGroup({
			slider: new FormControl(-1, [Validators.required, Validators.min(1)]),
			comment: new FormControl('', [Validators.required, Validators.maxLength(255)])
		}),
		overallFeedback: new FormControl('', [Validators.required, Validators.maxLength(255)])
	}) as FormGroup<any>;

	constructor(@Inject(MAT_DIALOG_DATA) public data: IDialogData<any>,
		public dialogRef: MatDialogRef<MentorFeedbackDialogComponent>,
		private _snackbar: SnackbarService,
		private mentorService: MentorService,
		private _utility: UtilityService,
		private authService: AuthService,
		private dialogService: DialogService) {
	}

	ngOnInit(): void {
		this.getMentorId();
		this.markSliderControlsAsTouched();
		this.candidateId = this.data.candidateId;
		this.batchId = this.data.batchId;
		// this.trainingNameInputChanges();
		if (this.data.operation === OPERATIONS.EDIT) {
			this.batchId = this.data.data.batchId;
			this.feedbackFormGroup?.get('date')?.get('startDate')?.setValue(this.data.data.startDate);
			this.setMaxDate();
			this.data.data.category.forEach((category: any) => {
				this.feedbackFormGroup?.get(`${category.key.split(' ').join('')}`)?.get('comment')?.patchValue(category.comment);
				this.feedbackFormGroup?.get(`${category.key.split(' ').join('')}`)?.get('slider')?.patchValue(category.value);
			})
			this.injectingData = false;
			this.feedbackFormGroup.get('overallFeedback')?.patchValue(this.data.data.comments);
			this.startDate = this.data.data.startDate;
			this.endDate = this.data.data.endDate;
		}
		this.feedbackFormGroup.get('overallFeedback')?.valueChanges.subscribe({
			next: (value: any) => {
				this.feedbackLength = value.length;
			}
		});
		this.valuesChangeDetector();
		this.feedbackLength = this.feedbackFormGroup.get('overallFeedback')?.value?.length;
	}


	setMaxDate() {
		if (this.data.operation === OPERATIONS.EDIT) {
			this.feedbackFormGroup?.get('date')?.get('endDate')?.patchValue(this.data.data.endDate);
		} else {
			const startDate = (this.feedbackFormGroup?.get('date')?.get(['startDate'])?.value as any)?._i;
			const startingDate = this._utility.getDateFormat(startDate);
			this.feedbackFormGroup?.get('date')?.get(['startDate'])?.patchValue(startingDate);
			const value = new Date(startingDate);
			value.setDate(value.getDate() + 6);
			const dateArray = value.toLocaleDateString().split('/');
			const currentDateObject = {
				year: Number(dateArray[2]),
				month: Number(dateArray[0]) - 1,
				date: Number(dateArray[1])
			}
			const currentDateInFormat = this._utility.getDateFormat(currentDateObject);
			this.feedbackFormGroup?.get('date')?.get('endDate')?.patchValue(currentDateInFormat);
		}
	}

	getMentorId() {
		this.mentorId = this.authService.getUserDetails()?.userId;
	}

	onCancelClick() {
		this.feedbackFormGroup.reset();
		this.dialogRef.close();
	}

	getControl(index: number, control: string) {
		return this.feedbackFormGroup.get(`${this.feedbackParameters[index].param}`)?.get(control) || new FormControl('') as FormControl;
	}

	markSliderControlsAsTouched() {
		this.feedbackParameters.forEach((parameter: any, index: number) => {
			this.feedbackFormGroup?.get(`${parameter.param}`)?.get('slider')?.valueChanges.subscribe({
				next: (value: any) => {
					this.feedbackFormGroup?.get(`${parameter.param}`)?.get('slider')?.markAsTouched()
				}

			})
		})
	}

	getKey(key: string) {
		return this.keyMapping[key];
	}

	formSubmission() {
		this.submitClicked = true;
		if (this.feedbackFormGroup.valid) {
			const payload: any = {
				batchId: this.batchId,
				userId: this.candidateId,
				mentorId: this.mentorId,
				startDate: this.feedbackFormGroup.get('date')?.get('startDate')?.value,
				endDate: this.feedbackFormGroup.get('date')?.get('endDate')?.value,
				overAllFeedback: this.feedbackFormGroup.get('overallFeedback')?.value
			}
			this.feedbackParameters.forEach((parameter: any) => {
				payload[`${this.getKey(parameter.param)}`] = this.feedbackFormGroup.get(parameter.param)?.get('slider')?.value;
				payload[this.getKey(`${parameter.param}Comment`)] = this.feedbackFormGroup.get(parameter.param)?.get('comment')?.value;
			});

			if (this.data.operation === OPERATIONS.ADD) {
				payload.evaluationTypeName = 'Weekly'
				this.mentorService.addMentorFeedback(payload).subscribe({
					next: (response: any) => {
						this._snackbar.openSnackBar(response.message, 'snackbar-success');
						this.dialogRef.close('success');
					},
					error: (error: any) => {
						this._snackbar.openSnackBar(error.error.message, 'snackbar-error');
					}
				})
			} else if (this.data.operation === OPERATIONS.EDIT) {
				payload.evaluationId = this.data.data.evaluationId;
				payload.feedbackId = this.data.data.feedbackId;
				this.mentorService.updateMentorFeedback(payload).subscribe({
					next: (response: any) => {
						this._snackbar.openSnackBar(response.message, 'snackbar-success');
						this.dialogRef.close('success');

					},
					error: (error: any) => {
						this._snackbar.openSnackBar(error.error.message, 'snackbar-error');
					}
				})
			}
		}
	}

	valuesChangeDetector() {
		this.feedbackFormGroup.valueChanges.subscribe({
			next: () => {
				this.valuesChanged = true;
			}
		})
	}
}
