import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, PRIVATE_ROUTES } from 'src/app/core';

@Component({
  selector: 'app-choose-role',
  templateUrl: './choose-role.component.html',
  styleUrls: ['./choose-role.component.scss']
})
export class ChooseRoleComponent implements OnInit {
  roleSvgArray !: string[];
  constructor(private authService : AuthService, private router : Router) { }

  ngOnInit(): void {
    this.roleSvgArray = this.authService.getRoles().map((role:string)=>`${role}-role`);
  }

  getRole(role:string){
    if(role.includes(PRIVATE_ROUTES.superAdmin))
    return 'Super Admin'
    return `${role.split('-')[0][0].toUpperCase()}${role.split('-')[0].split('').splice(1).join('')}`;
  }

  chooseRole(role:string){
    this.authService.switchRole(role.split('-')[0])
  }

}
