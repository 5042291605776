<div class="status-dropdown-container">
	<mat-form-field appearance="fill" floatLabel="always" class="status-dropdown">
		<mat-icon matPrefix class="status-icon" svgIcon="status-icon"
			[ngClass]="{ 'training-yellow' : statusControl.value === 'In Training', 'exit-red': statusControl.value === 'Process Exit', 'resigned-red': statusControl.value === 'Resigned', 'confirmed-green': statusControl.value === 'Confirmed' }"></mat-icon>
		<mat-select [formControl]="statusControl" panelClass="demo">
			<mat-option *ngFor="let option of status" (click)="clickedStatus(option.value,statusControl.value)"
				[value]="option.viewValue" >
				<p>{{option.viewValue}}</p>
				<mat-icon svgIcon="right-tick" *ngIf="statusControl.value === option.viewValue"></mat-icon>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>	
