import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

const materialModules = [
	MatIconModule,
	MatToolbarModule,
	MatButtonModule,
	MatDividerModule,
];

@Component({
	selector: 'app-toolbar',
	standalone: true,
	imports: [CommonModule, ...materialModules],
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
	@Input() title!: string;
	constructor(public dialog: MatDialog) {}

	
}
