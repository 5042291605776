import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-candidate-feedback',
  templateUrl: './admin-candidate-feedback.component.html',
  styleUrls: ['./admin-candidate-feedback.component.scss']
})
export class AdminCandidateFeedbackComponent implements OnInit {

  sortedCategory!: any;
  keys: any
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AdminCandidateFeedbackComponent>) { }

  ngOnInit() {
    this.keys = this.data.keys
    this.sortedCategory = this.data.data?.category;
    this.getSortedCategory()
  }
  getSortedCategory() {
    this.sortedCategory?.sort((a: any, b: any) => {
      return a.comment.length - b.comment.length
    })
  }

  onCancel() {
    this.dialogRef.close();
  }
}
