import { CommonModule } from "@angular/common";
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { AuthRoutingModule } from "./auth-routing.module";
import { SuccessMsgComponent } from "./components";
import { AuthComponent } from "./pages";
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ChooseRoleComponent } from './pages/choose-role/choose-role.component';
import { LoaderComponent } from "src/app/shared";


const materialModules = [MatDividerModule,
	MatFormFieldModule, MatButtonModule,
	MatIconModule, MatInputModule, MatCheckboxModule,MatSnackBarModule];
	
@NgModule({
	declarations: [AuthComponent,
		LoginComponent,
		ResetPasswordComponent,
		ForgotPasswordComponent,
		SuccessMsgComponent,
  ChooseRoleComponent],
	imports: [
		CommonModule,
		...materialModules,
		AuthRoutingModule,
		HttpClientModule,
		ReactiveFormsModule,
		FormsModule,
		LoaderComponent
	],
	exports: []
})

export class AuthModule { }
