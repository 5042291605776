<div>
    <form [formGroup]="feedbackFormGroup">
        <div class="feedback-header">
            <mat-icon svgIcon="feedback-dialog-logo" class="feedback-dialog-logo"></mat-icon>
            <h5>{{ data.operation === "edit" ? "Edit Interview Feedback" : "Add Interview Feedback"}}</h5>
        </div>
        <div>
            <p class="interview-date">Interview Date: {{data.data.interviewDate | date :'mediumDate'}} </p>
        </div>
        <mat-divider class="interview-mat-divider"></mat-divider>
        <div class="feedback-options-wrapper">
            <form *ngFor="let parameter of feedbackParameters; let i = index" class="feedback-parameter-wrapper">
                <p class="feedback-parameter">{{parameter.key}}</p>
                <div class="parameter-field">
                    <app-slider [parameterId]="parameter.id" [initialValue]="getControl(i, 'slider')?.value" (valueSelected)="getControl(i, 'slider').patchValue($event.value)"
                    [error]="(getControl(i, 'slider').invalid && submitClicked || (getControl(i, 'slider').touched && getControl(i, 'slider').invalid))">
                    </app-slider>
                    <div class="feedback-comment">
                        <textarea  cols="30" rows="10" class="feedback-comment-area"
                            placeholder="Enter a description..." [formControl]="$any(getControl(i, 'comment'))"
                            error="(getControl(i, 'comment').invalid && submitClicked || (getControl(i, 'comment').touched && getControl(i, 'comment').invalid)) || getControl(i, 'comment')?.hasError('maxlength')? true : false"></textarea>
                    </div>
                </div>
                <mat-error
                    *ngIf="(getControl(i, 'comment').invalid && submitClicked || (getControl(i, 'comment').touched && getControl(i, 'comment').invalid))"
                    class="validation-msg">{{getControl(i, 'comment')?.hasError('maxlength')? 'Max character limit : 255' : 'You need to add feedback' }}</mat-error>
            </form>
            <div class="overall-feedback-container">
                <p class="feedback-parameter overall-feedback"><span>Overall feedback</span>
                    <span>{{0}}/255</span>
                </p>
                <textarea class="comment-textarea" placeholder="Enter a description..." [ngClass]="{'textarea-error': ($any(feedbackFormGroup.get('overallFeedback')).touched && $any(feedbackFormGroup.get('overallFeedback')).invalid) || ($any(feedbackFormGroup.get('overallFeedback')).invalid && submitClicked)}"
                [formControl]="$any(feedbackFormGroup.get('overallFeedback'))" maxlength="255"
                     maxlength="255"></textarea>
                <mat-error  *ngIf="($any(feedbackFormGroup.get('overallFeedback')).touched && $any(feedbackFormGroup.get('overallFeedback')).invalid) || ($any(feedbackFormGroup.get('overallFeedback')).invalid && submitClicked)"
                [formControl]="$any(feedbackFormGroup.get('overallFeedback'))"
                    >Overall
                    Feedback Is
                    Required!</mat-error>
            </div>
        </div>
        <div class="feedback-dialog-footer">
            <button mat-flat-button disableRipple class="secondary sm" type="button" (click)="onCancelClick()">
                Cancel
            </button>
            <button mat-flat-button color="primary" disableRipple class="sm" type="submit" (click)="formSubmission()" [disabled]="!valuesChanged">
                {{ data.operation === "edit" ? "Update" : "Submit"}}
            </button>
        </div>
    </form>
</div>
