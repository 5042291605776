export interface IUserDetails {
  userId?: number;
  firstName?: string;
  lastName?: string;
  emailId?: string;
  imageUrl?: string;
  dateOfJoining?: Date;
  userRole?: string;
  role?: string[];
  isActive?: boolean;
}
