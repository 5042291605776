<mat-stepper [linear]="isLinear" #stepper class="feedback-stepper-class"
	[selectedIndex]="data.operation==='edit' ? 1:0">

	<div>
		<mat-step [stepControl]="detailsForm">
			<form [formGroup]="detailsForm">
				<header mat-dialog-header>
					<mat-icon svgIcon="feedback-dialog-logo" class="feedback-dialog-logo"></mat-icon>
					<h3>{{ data.operation === "edit" ? "Edit Feedback" : "Add Feedback"}}</h3>
					<span class="caption-1">Weekly</span>
				</header>
				<div class="details-form">
					<label>Training <span class="asterisk"> *</span></label>
					<div class="training-name-field">
						<mat-form-field appearance="fill" floatLabel="always">
							<input matInput #myInput type="text" placeholder="Training name..."
								[formControl]="trainingNameInput" [matAutocomplete]="auto"
								(click)="toggleDropdown($event)" required>
							<mat-icon matSuffix svgIcon="dropdown-arrow" class="dropdown-icon"
								(click)="toggleDropdown($event)"></mat-icon>
							<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" #autocomplete>
								<mat-option *ngFor="let item of trainingsArray" [value]="item.trainingName">
									{{item.trainingName}}
									<mat-icon svgIcon="checkmark-icon"
										*ngIf="detailsForm.get('trainingId')?.value === item.trainingId"
										class="tick-icon"></mat-icon>
								</mat-option>
							</mat-autocomplete>
							<mat-error *ngIf="detailsForm.get('trainingId')?.hasError('required')">
								Training name required
							</mat-error>
						</mat-form-field>
					</div>
					<div class="date-picker">
						<label>Duration<span class="asterisk"> *</span></label>
						<mat-form-field appearance="fill" floatLabel="always" (click)="picker.open()">
							<mat-icon matPrefix svgIcon="datepicker"></mat-icon>
							<mat-date-range-input [rangePicker]="picker">
								<input matStartDate placeholder="Start date" formControlName="startDate" id="startdate"
									(dateInput)="setMaxDate()">
								<input matEndDate formControlName="endDate" placeholder="End date"
									(dateInput)="setMaxDate()">
							</mat-date-range-input>
							<mat-date-range-picker #picker [calendarHeaderComponent]="exampleHeader">
							</mat-date-range-picker>
							<mat-error
								*ngIf="detailsForm.get('startDate')?.hasError('required') || detailsForm.get('endDate')?.hasError('required') ">
								Please choose the duration
							</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="feedback-dialog-footer">
					<button mat-flat-button disableRipple class="secondary sm" type="button" (click)="onCancelClick()">
						Cancel
					</button>
					<button mat-flat-button color="primary" disableRipple class="sm" type="button"
						(click)="onNextClick()" (click)="isLinear= !detailsForm.valid " matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>
	</div>
	<div>
		<mat-step [stepControl]="feedbackForm">
			<form [formGroup]="feedbackForm">
				<mat-icon svgIcon="feedback-dialog-logo" class="feedback-dialog-logo"></mat-icon>
				<h5>{{ data.operation === "edit" ? "Edit Feedback" : "Add Feedback"}}</h5>
				<div class="selected-data-display-wrapper">
					<p class="caption-1 weekly-text">Weekly :{{startDate | date:'dd MMMM'}} - {{endDate | date:'dd
						MMMM'}}</p>
					<mat-divider></mat-divider>
					<p class="selected-training-name">{{selectedTrainingName}}</p>
				</div>
				<div class="feedback-options-wrapper">
					<div *ngFor="let parameter of feedbackParameters">
						<p class="feedback-parameter">{{parameter.key}}</p>
						<div class="parameter-field">
							<app-slider [parameterId]="parameter.id" [initialValue]="parameter.value"
								(valueSelected)="valueIsSelected($event)">
							</app-slider>
							<button mat-icon-button class="mat-button-wrapper"
								(click)="showCommentBoxTooltip(parameter.key)" *ngIf="parameter.showCommentIcon"
								[disabled]="parameter.commentIsRequired"
								[ngClass]="{'comment-active-state':parameter.showCommentBox===true || parameter.icon==='comment-icon-active' && !parameter.showError, 'comment-error-state' : parameter.showError}">
								<mat-icon svgIcon="{{parameter.icon}}"></mat-icon>
							</button>
							<app-feedback-comment [parameter]="parameter.id" *ngIf="parameter.showCommentBox"
								[feedbackComment]="parameter.comment" (saveClicked)="saveCommentClicked($event)"
								(cancelClicked)="cancelCommentClicked($event)" [mode]="data.operation"></app-feedback-comment>
						</div>
						<!-- <mat-error *ngIf="parameter.showError" class="validation-msg">Feedback is required</mat-error> -->
					</div>
					<div class="overall-feedback-container">
						<p class="feedback-parameter overall-feedback"><span>Overall feedback</span> <span>{{feedbackLength}}/255</span></p>

						<textarea class="comment-textarea" placeholder="Enter a description..." 
							[ngClass]="{'textarea-error': overallFeedback.hasError('required') && overallFeedback.touched}"
							[formControl]="overallFeedback" maxlength="255"></textarea>
							<mat-error *ngIf="overallFeedback.touched && overallFeedback.hasError('required')">Overall Feedback Is Required!</mat-error>
					</div>
				</div>
				<div class="feedback-dialog-footer">
					<button mat-flat-button disableRipple *ngIf="data.operation === 'add'" class="secondary sm"
						type="button" (click)="isLinear=true" matStepperPrevious>
						Back
					</button>
					<button mat-flat-button *ngIf="data.operation === 'edit'" disableRipple class="secondary sm"
						type="button" (click)="onCancelClick()">
						Cancel
					</button>
					<button mat-flat-button color="primary" disableRipple class="sm" type="submit"
						(click)="onFormSubmit()" [disabled]="(!feedbackForm.valid || !overallFeedback.valid) || !valuesChanged">
						{{ data.operation === "edit" ? "Update" : "Submit"}}
					</button>
				</div>
			</form>
		</mat-step>
	</div>
</mat-stepper>