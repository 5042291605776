import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PUBLIC_ROUTES } from './core';
import { ComponentLibraryComponent } from './shared';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule)
	},
	{
		path: PUBLIC_ROUTES.uiLibrary,
		component: ComponentLibraryComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
