import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { AuthService } from 'src/app/core';

const materialModules = [
  MatSliderModule,
  MatFormFieldModule
];

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [CommonModule, ...materialModules, FormsModule],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit{

  max = 10;
  min = 0;
  value = 0;
  valueISChanged = false;
  @Input() parameterId=0;
  @Input() initialValue=0;
  @Input() error  = false;

  role!: string;
  @Output() valueSelected = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private authService: AuthService) { }
  ngOnInit(): void {
    this.role = this.authService.getCurrentRole() || '';
    this.value=this.initialValue>=0? this.initialValue : 0;
  }

  valueChanged() {
    this.valueISChanged = true; 
    this.valueSelected.emit({ id: this.parameterId, value: this.value });
  }
}
