<div class="grid-container spacer">
	<table mat-table matSort [dataSource]="dataSource" (matSortChange)="columnSort($event)"
		[matSortActive]="initialSortedColumn" matSortDirection="desc">
		<ng-container *ngFor="let column of columnHead; let i = index">
			<ng-container [matColumnDef]="column.id">
				<ng-container *ngIf="column.id !== 'action';else actionColumn">
					<ng-container *ngIf="column.id!=='totalAverage';else scoreColumn">
						<ng-container *ngIf="column.id!=='candidates';else candidatesColumn">
							<ng-container *ngIf="column.id!=='namedAvatar';else namedAvatar">
								<ng-container *ngIf="column.id!=='rank';else rank">
									<ng-container *ngIf="column.id!=='status'; else statusColumn">
										<ng-container *ngIf="column.id!=='ellipsisChip';else ellipsedChipColumn">
											<ng-container *ngIf="column.id!=='mentor'; else mentorColumn">
												<ng-container *ngIf="column.id!=='pipDuration'; else durationColumn">
													<ng-container *ngIf="column.id!=='checkbox';else checkboxColumn">
														<!-- <ng-container *ngIf="column.id!=='pipCandidates'; else pipCandidates"> -->
														<ng-container
															*ngIf="column.id!=='autoComplete-dropdown';else usersDropdown">
															<ng-container
																*ngIf="column.id!=='batchDuration';else batchDuration">
																<!-- <ng-container
																	*ngIf="column.id !=='startTime' ;else interviewTimeField"> -->
																	<th mat-header-cell class="mat-head" *matHeaderCellDef
																		mat-sort-header
																		sortActionDescription="Sort by {{column.label}}"
																		[disabled]="!column.sortable">
																		{{column.label}} <span class="asterisk"
																			*ngIf="column.id==='pipcandidates' "
																			style="margin-left : 0.25rem">*</span></th>
																	<!-- Other Columns -->
																	<td mat-cell *matCellDef="let element"
																		(click)="gridElementClick(element)"
																		[ngClass]="{'blue-highlight': column.clickable===true}">
																		{{element[column.id]}}
																		<div class="pip-candidate">

																			<div *ngIf="element.imageUrl!=='' && element.pipCandidates"
																				class="user-image action-btn">
																				<img [src]="element.imageUrl"
																					class="default large" />
																			</div>
																			<!-- <ng-template *ngIf="true"> -->
																			<div class="no-image-large position user-image"
																				*ngIf="element.pipCandidates && element.imageUrl===''">
																				<p>{{ element.replacementText }}</p>
																			</div>
																			<!-- </ng-template> -->
																			{{element.pipCandidates}}
																		</div>
																	</td>
																<!-- </ng-container> -->
																<!-- <ng-template #interviewTimeField>
																	<th mat-header-cell class="mat-head" *matHeaderCellDef
																		mat-sort-header [disabled]="!column.sortable">
																		{{column.label}}</th>
																	<td mat-cell *matCellDef="let element">
																		<p>{{element | timeConversion}}</p>
																	</td>
																</ng-template> -->
															</ng-container>
															<ng-template #batchDuration>
																<th mat-header-cell class="mat-head" *matHeaderCellDef
																	mat-sort-header [disabled]="!column.sortable">
																	{{column.label}}</th>
																<td mat-cell *matCellDef="let element">
																	<p>{{element?.startDate | date}} -
																		{{element?.endDate | date}}</p>
																</td>
															</ng-template>
														</ng-container>
														<ng-template #usersDropdown>
															<th mat-header-cell class="mat-head" *matHeaderCellDef
																mat-sort-header [disabled]="!column.sortable">
																{{column.label}}</th>
															<td mat-cell *matCellDef="let element">
																<app-autocomplete-dropdown [currentUser]="element"
																	(userSelected)="userClicked($event)"></app-autocomplete-dropdown>
															</td>
														</ng-template>

													</ng-container>
													<ng-template #checkboxColumn>
														<th mat-header-cell class="mat-head" *matHeaderCellDef
															mat-sort-header [disabled]="!column.sortable">
															<!-- <mat-checkbox [indeterminate]="true" 
																(change)="headerCheckboxClicked($event)" [checked]="headerCheckboxChecked && !statusError"></mat-checkbox> -->
															<mat-checkbox
																[indeterminate]="intermediate"
																#headerCheckbox
																[checked]="!uncheckHeader"
																(change)="headerCheckboxClicked($event)"></mat-checkbox>
															<div #arrowDown>
																<mat-icon svgIcon="dropdown-arrow"
																	[matMenuTriggerFor]="menu"></mat-icon>
															</div>
															<mat-menu #menu="matMenu">
																<app-header-checkbox-dropdown ngClass=""
																	[options]="column.dropDown"
																	[mode]="'single'"></app-header-checkbox-dropdown>
															</mat-menu>

														</th>
														<td mat-cell *matCellDef="let element">
															<mat-checkbox (change)="checkboxClicked(element,$event)"
																[(indeterminate)]="element.indeterminate"
																[checked]="element.formControl?.value">
															</mat-checkbox>
														</td>
													</ng-template>
												</ng-container>
												<ng-template #durationColumn>
													<th mat-header-cell class="mat-head" *matHeaderCellDef>
														{{column.label}} <span class="asterisk">*</span></th>
													<!-- Other Columns -->
													<td mat-cell *matCellDef="let element"
														[ngClass]="{'error' : element.error && !element.durationFormGroup?.get('startDate')?.value && !element.durationFormGroup?.get('endDate')?.value}">
														<app-date-input [data]="element"
															[formGroup]="element.durationFormGroup"></app-date-input>
													</td>
												</ng-template>
											</ng-container>
											<ng-template #mentorColumn>
												<th mat-header-cell class="mat-head" *matHeaderCellDef>
													{{column.label}} <span class="asterisk">*</span></th>
												<!-- Other Columns -->
												<td mat-cell *matCellDef="let element"
													[ngClass]="{'error': element.error, 'selected' : element.mentorFormControl.value!==-1 || select.isOpen}">
													<mat-form-field>
														<!-- <mat-label
															[ngStyle]="{'display' : element.mentorFormControl.value || select.opened ? 'block' : 'block'}">
															Select
														</mat-label> -->
														<input [formControl]="element.searchMentor"
															[matAutocomplete]="auto"
															[placeholder]="select.isOpen || element.searchMentor.value?'' :'Select...'"
															matInput>
														<mat-icon matSuffix svgIcon="dropdown-arrow"
															class="dropdown-icon"></mat-icon>
														<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
															#autocomplete [formControl]="element.mentorFormControl"
															#select>
															<mat-option *ngFor="let mentor of element.filteredMentors"
																[value]="mentor.mentorId">{{mentor.firstName}}
																{{mentor.lastName}}</mat-option>
														</mat-autocomplete>
													</mat-form-field>
											</ng-template>
										</ng-container>


										<ng-template #ellipsedChipColumn>
											<th mat-header-cell class="mat-head" *matHeaderCellDef mat-sort-header
												disabled="!column.sortable">
												{{column.label}}</th>
											<td mat-cell *matCellDef="let element">
												<app-ellipsis-chip [userData]="element[column.id]"
													(trainingCountClick)="handleCountClick($event)"></app-ellipsis-chip>
											</td>
										</ng-template>
									</ng-container>

									<ng-template #statusColumn>
										<th mat-header-cell class="mat-head" *matHeaderCellDef mat-sort-header
											[disabled]="!column.sortable">
											{{column.label}}</th>
										<td mat-cell *matCellDef="let element">
											<app-drop-down [userData]="element" (changedStatus)="updatedStatus($event)"
												class="app-dropdown"></app-drop-down>
										</td>
									</ng-template>
								</ng-container>
								<ng-template #rank>
									<th mat-header-cell class="mat-head" *matHeaderCellDef mat-sort-header
										disabled="!column.sortable">
										{{column.label}}</th>
									<td mat-cell *matCellDef="let element">
										<app-rank [userData]="element[column.id]"></app-rank>
									</td>
								</ng-template>
							</ng-container>
							<ng-template #namedAvatar>
								<ng-container *ngIf="isModal; else notModal">
									<th mat-header-cell class="mat-head" *matHeaderCellDef>
										{{column.label}}
										<span class="asterisk" *ngIf="column.showMandatory">*</span>
									</th>
								</ng-container>

								<ng-template #notModal>
									<th mat-header-cell class="mat-head named-avatar-head" *matHeaderCellDef
										mat-sort-header [disabled]="!column.sortable">
										{{column.label}}
										<span class="asterisk" *ngIf="column.showMandatory">*</span>
									</th>

								</ng-template>
								<td mat-cell *matCellDef="let element" class="named-avatar-cell">
									<app-named-avatar [userData]="element[column.id]" [clickable]="column.clickable"
										(click)="gridElementClick(element)"></app-named-avatar>
								</td>
							</ng-template>
						</ng-container>

						<ng-template #candidatesColumn>
							<th mat-header-cell class="mat-head" *matHeaderCellDef mat-sort-header
								[disabled]="!column.sortable">
								{{column.label}}</th>
							<td mat-cell *matCellDef="let element">
								<app-avatar-list [avatarList]="element[column.id]" [n]="4"
									[userType]="'student'"></app-avatar-list>
							</td>
						</ng-template>
					</ng-container>
					<ng-template #scoreColumn>
						<th mat-header-cell class="mat-head" *matHeaderCellDef mat-sort-header
							[disabled]="!column.sortable">
							{{column.label}}</th>
						<td mat-cell *matCellDef="let element" class="score-chip-component">
							<app-score-chip [score]="element[column.id]" matTooltip="Feedback not added yet"
								matTooltipPosition="above"
								[matTooltipDisabled]="element[column.id]!==0"></app-score-chip>
						</td>
					</ng-template>
				</ng-container>

				<!-- Action Column -->
				<ng-template #actionColumn>
					<th mat-header-cell *matHeaderCellDef class="mat-head ">{{column.label}}</th>
					<td mat-cell *matCellDef="let element"
						[ngClass]="(column.actions.length===2)?'gap-large':'gap-small'">
						<ng-container *ngFor="let button of column.actions">
							<button mat-icon-button color="primary" matTooltip="{{button.text}}"
								matTooltipPosition="below" (click)="gridRowClick(element, button.type)">
								<mat-icon [svgIcon]="button.type"></mat-icon>
							</button>
						</ng-container>
					</td>
				</ng-template>
			</ng-container>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumn;"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>
	</table>

	<app-loader class="table-loader" *ngIf="fetchingData"></app-loader>
	<div *ngIf="totalCount === 0">
		<div class="no-data">
			<ng-content></ng-content>
		</div>
	</div>

	<!-- <div class="spacer"></div> -->
	<app-pagination *ngIf="totalCount >9 " [count]="totalCount" [typeOfData]="typeOfData"
		(pageDetails)="getPageDetails($event)"></app-pagination>
</div>