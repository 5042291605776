import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { AuthService, PRIVATE_ROUTES, SnackbarService, UtilityService, WebsocketService } from 'src/app/core';
import { INavListItem } from 'src/app/core/interfaces';

const materialModules = [MatSidenavModule, MatButtonModule, MatMenuModule, MatListModule, MatToolbarModule, MatTooltipModule, MatIconModule];

@Component({
	selector: 'app-sidebar',
	standalone: true,
	imports: [CommonModule, ...materialModules, RouterModule],
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

	data!: any
	userDetails!: any
	// userRole!: string
	replacementText = '';
	currentIcon = '';
	@Input() navListItems: INavListItem[] = [];
	@Input() userRole = '';
	@Input() roles !: string[];
	@Input() unreadNotificationCount!: any;
	@ViewChild('switchRoleContainer', { read: ElementRef }) switchRoleContainer !: ElementRef;
	@ViewChild('switchRoleOption', { read: ElementRef }) switchRoleOption !: ElementRef;
	rolesLength = false;
	wantsToSwitch = false;
	alternateRole = '';
	@Output() openNotificationPannel = new EventEmitter();
	constructor(private authService: AuthService, private router: Router, private _utility: UtilityService, private snackbar: SnackbarService, private renderer: Renderer2, private webSocketService: WebsocketService) {
		this.renderer.listen('window', 'click', (e: Event) => {
			if (!this.switchRoleOption?.nativeElement.contains(e.target)) {
				this.wantsToSwitch = false;
			}
		});
	}

	ngOnInit() {
		this.getUserDetails();
		this.userRole = this.userRole?.toLowerCase();
		this.roles?.splice(this.roles.indexOf(this.userRole), 1);
		if (this.roles?.length > 1)
			this.rolesLength = true;
		else if (this.roles?.length === 1)
			this.alternateRole = this.roles[0]
	}

	get select() {
		return this._utility.filterOpen;
	}

	/**
	 * Changes the Icon for the list item as per the active status
	 * @param isActive whether the route is active or not
	 * @param item the object of INavListItem of the route for which the event has been emitted
	 */
	changeActiveIcon(isActive: boolean, item: INavListItem) {
		[item['icon'], item['activeIcon']] = [item['activeIcon'], item['icon']];
	}

	/**
	 * Logout the user
	 */
	logout() {
		this.authService.logout();
		this.webSocketService._disconnect();
		this.router.navigate(['']);
	}

	switchRole(role: string) {
		this.authService.switchRole(role.split('-')[0])
		this.router.navigate([role.split('-')[0]]);
		this.snackbar.openSnackBar(`Switched to ${this.getRole(role)}`)
		this.wantsToSwitch = false;
	}

	getUserDetails() {
		this.data = localStorage.getItem('userDetails');
		this.userDetails = JSON.parse(this.data) || '';
		this.replacementText = this.userDetails.firstName?.charAt(0) + this.userDetails.lastName?.charAt(0);
		const roleArray = (this.userDetails.role?.[0])?.split('_') || '';
		// this.userRole = roleArray?.[1];
	}

	getRole(role: string) {
		if (role.includes(PRIVATE_ROUTES.superAdmin))
			return 'Super Admin'
		return `${role.split('-')[0][0]?.toUpperCase()}${role.split('-')[0].split('').splice(1).join('')}`;
	}

	notificationToggled() {
		this.openNotificationPannel.emit();
	}
}
