<main ngClass="options-container"  >
    <div *ngFor="let option of options; let i = index" [ngClass]="{'multiple' : mode==='many'}" class="option">
        <mat-checkbox (change)="optionsFormControl.controls[i].patchValue($event.checked)" *ngIf="mode==='many'" [checked]="optionsFormControl.controls[i].value"></mat-checkbox>
        <ng-container *ngIf="option.options; else noOptions">
            <p class="option-label" mat-menu-item [matMenuTriggerFor]="menu" (click)="optionClicked(i)">
                <span>
                    {{option.label}}
                </span>
                <mat-icon svgIcon="arrow-right-small" *ngIf="option.options" ></mat-icon>
                <mat-menu *ngIf="option.options" #menu="matMenu" [overlapTrigger]="false" [xPosition]="'after'">
                    <app-header-checkbox-dropdown [options]="option.options" [mode]="'single'"></app-header-checkbox-dropdown>
                </mat-menu>
            </p>
        </ng-container>
        <ng-template #noOptions>
            <p class="option-label" (click)="optionClicked(i)">
                <span>
                    {{option.label}}
                </span>
                <mat-icon svgIcon="arrow-right-small" *ngIf="option.options" ></mat-icon>
                <mat-menu *ngIf="option.options" #menu="matMenu"  [xPosition]="'after'">
                    <app-header-checkbox-dropdown [options]="option.options" [mode]="'single'"></app-header-checkbox-dropdown>
                </mat-menu>
            </p>
        </ng-template>
    </div>
</main>


