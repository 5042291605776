<!-- <p>snackbar works!</p> -->
<div class="wrapper">
	<div [ngClass]="{'left-icon' : icon_name}" class="container">
		<ng-container *ngIf="icon_name">
			<div ngClass="icon">
				<mat-icon [svgIcon]="icon_name" class="icon"></mat-icon>
			</div>
		</ng-container>
		<p>{{ data.message }}</p>
		<div
		class="action"
		[ngClass]="{
			'close-icon-success': data.type === 'snackbar-success',
			'close-icon-error': data.type === 'snackbar-error',
			'close-icon-warning': data.type === 'snackbar-warning'
		}"
	>
		<button class="button-margin" mat-icon-button (click)="snackBarRef.dismiss()">
			<mat-icon class="cross-icon" svgIcon="close"></mat-icon>
		</button>
	</div>
	</div>
	
</div>
