import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'app-named-avatar',
  standalone: true,
  imports: [CommonModule, MatChipsModule],
  templateUrl: './named-avatar.component.html',
  styleUrls: ['./named-avatar.component.scss']
})
export class NamedAvatarComponent implements OnChanges {

  @Input() imgSrc = '';
  @Input() clickable: any;
  @Input() userData:any;
  replacementText='';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  ngOnChanges() {   
    const replacementArray = this.userData.name.split(" ");
    this.replacementText = replacementArray[0].charAt(0);
  }

}
