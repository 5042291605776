export interface IStatusCard{
    isSetInterview:boolean;
    isScheduled:boolean;
    isCompleted:boolean;
    isViewHistory:boolean;
}

export interface IStatusData{
    title:string;
    value:string;
}
