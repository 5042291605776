import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegexContainer } from 'src/app/core';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { AuthService } from './../../../../core/services/auth/auth.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

	displaySuccessMessage = false;
	condition = 'forgot';
	forgotEmail = '';
	constructor(private router: Router, private authService: AuthService,private _snackbar:SnackbarService) { }

	forgotPassword = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.pattern(RegexContainer.emailPattern)])
	})

	/**
	 * to send values of the email entered by the user to the authentication service
	 * @param emailDetails email of external user as entered in email field
	 */
	submitEmailClicked(emailDetails: FormGroup) {
		const emailValue = Object(emailDetails.value);

		if (emailValue.length != 0) {
			this.forgotEmail = emailValue.email;
			this.authService.forgotPassword(emailValue).subscribe({
				next: (response: any) => {
					this.displaySuccessMessage = true;
				},
				error:
					(error: any) => {
						this._snackbar.openSnackBar(error.error.message, 'snackbar-error', 'bottom', 'center');
					}
			});
		}
		else {
			this._snackbar.openSnackBar('Empty email sent', 'bottom', 'center');
		}

	}

	/** to route the user back to the login page from the forgot-password page
	 *
	 */
	routeToLogin() {
		this.router.navigate(['auth/login']);
	}

}
