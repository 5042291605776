<main class="records-container">
    <div class="attendance"
        [ngClass]="{'no-assignment' : attendanceFormControl.value===null, 'yes' : attendanceFormControl.value===true, 'no' : attendanceFormControl.value === false}">
        <p>Attendance</p>
        <div class="radio-container">
            <mat-radio-group aria-label="Select an option" (change)="assignAppropriateValue($event, 'Attendance')">
                <mat-radio-button #True value="true" [checked]="attendanceFormControl.value===true"
                [ngClass]="{'no-assignment' : attendanceFormControl.value===null, 'yes' : attendanceFormControl.value===true}">P</mat-radio-button>
                <mat-radio-button #False value="false" [checked]="attendanceFormControl.value===false"
                [ngClass]="{'no-assignment' : attendanceFormControl.value===null, 'no' : attendanceFormControl.value === false}">A</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="assignment attendance"
        [ngClass]="{'no-assignment' : assignmentFormControl.value===null, 'yes' : assignmentFormControl.value===true, 'no' : assignmentFormControl.value === false}">
        <p>Assignment</p>
        <div class="radio-container">
            <mat-radio-group aria-label="Select an option" (change)="assignAppropriateValue($event, 'Assignment')">
                <mat-radio-button value="true" [checked]="assignmentFormControl.value===true"
                [ngClass]="{'no-assignment' : assignmentFormControl.value===null, 'yes' : assignmentFormControl.value===true}">Y</mat-radio-button>
                <mat-radio-button value="false" [checked]="assignmentFormControl.value===false"
                [ngClass]="{'no-assignment' : assignmentFormControl.value===null,'no' : assignmentFormControl.value === false}">N</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</main>