import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { UsersService } from 'src/app/core';
import { DropDownComponent } from '../drop-down/drop-down.component';
import { SnackbarService } from './../../core/services/snackbar/snackbar.service';

const materialModules = [MatIconModule, MatButtonModule];

@Component({
	selector: 'app-confirmation-dialog',
	standalone: true,
	imports: [CommonModule, DropDownComponent, MatFormFieldModule,
		MatAutocompleteModule, FormsModule, ReactiveFormsModule, MatInputModule,
		...materialModules],
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

	constructor(@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		private userService: UsersService,
		private _snackbar: SnackbarService) {

		this.managerForm = new FormGroup({
			managerId: new FormControl('', Validators.required),
		});
	}
	myControl = new FormControl('');
	filteredOptions: any;
	selectedValue: any;
	filteredCandidates!: any;
	reportingMangerArray: any = [];
	searchText = '';
	managerForm: FormGroup;
	managerList: Array<any> = [];
	managerId: any;
	managerName!: any;
	usersInput = new FormControl('', Validators.required);
	showEmptyLastNameError = false;
	isInvalid = false;
	isVisible = false;
	isManagerSelected=false;
	selectedManagerId = -1;
	@ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) matAutocomplete: MatAutocompleteTrigger | undefined;
	@ViewChild('auto', { static: false }) autocomplete: MatAutocomplete | undefined;

	ngOnInit() {
		
		
		if(this.data.data?.action.toUpperCase() !== 'CONFIRM' || this.data.data?.action.toUpperCase() !== 'CONFIRMED'){
			this.userInputChanges();
		}
	}

	toggleDropdown(event: any) {
		if (this.isVisible) {
			setTimeout(() => {
				this.matAutocomplete?.closePanel();
			}, 10)
			this.isVisible = false;
		} else {
			this.isVisible = true;
		}
	}

	closeDialog(action: string) {
		this.dialogRef.close(action);

	}

	getReportingManager(userSearch?: string) {
		let payload = {};
		if (this.isManagerSelected) {
			payload = {
				searchText: '',
				userRole: 'ALL'
			}
			this.isManagerSelected = false

		} else {
			payload = {
				searchText: userSearch,
				userRole: 'ALL'
			}
		}
		this.userService.getUsers(this.userService.http, payload).subscribe({
			next: (response: any) => {
				this.reportingMangerArray = [...response];
				this.reportingMangerArray = response.map((item: any) => {
					item.managerId = item.userId,
						item.managerName = `${item.firstName} ${item.lastName}`
				})
				this.managerList = [...response] || [];
			},
			error: (error: any) => {
				this._snackbar.openSnackBar(error.error.message, 'snackbar-error');
			}
		})
	}

	_filter(value: any) {
		const filterValue = value.toLowerCase();
		return this.managerList.filter(option =>
			option.managerName.toLowerCase().includes(filterValue)
		);
	}

	userInputChanges() {
		this.managerForm.get('managerId')?.valueChanges.pipe(startWith(''),
			debounceTime(1000),
			distinctUntilChanged()
		).subscribe({
			next: (response: any) => {
				this.getReportingManager(response);
			}
		})
	}

	managerOptionClicked(manager:any) {
		// this.internalTrainerId = trainer.userId;
		this.selectedManagerId = manager.managerId;
		this.isManagerSelected = true;
	}

	onSubmit() {
		if (this.managerForm.valid) {
			this.dialogRef.close({ data: this.managerForm.get('managerId')?.value, managerId: this.selectedManagerId });
		}
	}
}
