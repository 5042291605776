import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IQueryParams } from '../../models';
import { API_ENDPOINTS } from '../../structs';
import { ServicesUtilityService } from '../utility/services-utility.service';

@Injectable({
	providedIn: 'root'
})
export class TrainerUserService {

	private feedbackUpdated=new BehaviorSubject<string>('');

	constructor(public http: HttpClient, private servicesUtilityService: ServicesUtilityService) { }

	getAllCandidatesForTrainer(
		http: HttpClient,
		reqBody: any
	): Observable<any> {
		return http.post(API_ENDPOINTS.getAllCandidatesForTrainer, reqBody);
	}

	/**
	   * function to get list of all trainings for trainer to select training so that feedback for that training can be added.
	   * @param reqBody reqBody must contain an object with values of trainerId, batchId and searchText from that input tag
	   * @returns list of all trainings in that particular batch
	   */
	getAllTrainingsForFeedback(
		http: HttpClient,
		reqBody: any
	): Observable<any> {
		return http.post(API_ENDPOINTS.getAllTrainingsForFeedback, reqBody);
	}

	getCandidateOverview(
		http: HttpClient,
		reqBody: any
	): Observable<any> {
		return http.post(API_ENDPOINTS.getCandidateOverview, reqBody);
	}

	getCandidateGraphById(reqBody:any) {
		return this.http.post(`${API_ENDPOINTS.getGraphDataForTrainerDashboard}`,reqBody);
}

	/**
	 * function to add feedback for a particular candidate by the trainer
	 * @param reqBody contains reqBody as specified in BE documentation containing feedback content entered by user.
	 * @returns success/failure message from BE
	 */
	addFeedback(reqBody: any): Observable<any> {
		return this.http.post(API_ENDPOINTS.addFeedback, reqBody);
	}

	/**
	 * function to update feedback for a particular candidate by the trainer
	 * @param reqBody contains reqBody as specified in BE documentation containing feedback content entered by user.
	 * @returns success/failure message from BE
	 */
	updateFeedback(reqBody:any):Observable<any>{
		return this.http.put(API_ENDPOINTS.updateFeedback,reqBody);
	}

	emitFeedbackUpdated(message:string){
		this.feedbackUpdated.next(message);
	}

	feedbackEventListener(){
		return this.feedbackUpdated.asObservable();
	}

	searchCandidate(
		http: HttpClient,
		queryParams: IQueryParams
	): Observable<any> {
		const params = this.servicesUtilityService.createHttpParams(queryParams);
		return http.get(API_ENDPOINTS.searchCandidate,
			{
				params,
			});
	}
}
