<div class="feedback-score">
    <div class="feedback-score-header">
      
            <div *ngIf ="data.role==='interviewer';else otherBlock">
                <h6>Interview Level - {{data.data.interviewLevel}}</h6>
            </div>
            <ng-template #otherBlock>
                <div>
                    <h6>{{data.data?.trainingName}}</h6>
                </div>
            </ng-template>
            <div>
                <mat-icon svgIcon="close" class="close-icon action-btn" (click)="onCancel()"></mat-icon>
            </div>
        
    </div>
    
    <div class="sub-card-container">
        <div class="sub-card">
            <mat-card-subtitle class="subcard-subtite">Score</mat-card-subtitle>
            <app-score-chip [score]="data.data?.feedbackDto.totalAverage" *ngIf="data.role==='interviewer';else otherScore"></app-score-chip>
            <ng-template #otherScore>
                <app-score-chip [score]="data.data?.totalAverage"></app-score-chip>
            </ng-template>
        </div>
        <div class="sub-card">
            <div *ngIf="data.role==='interviewer';else otherTitle"><mat-card-subtitle class="subcard-subtite">Interview Date</mat-card-subtitle></div>
            <ng-template #otherTitle><mat-card-subtitle class="subcard-subtite">Duration</mat-card-subtitle></ng-template>
            <div *ngIf="data.role==='interviewer';else otherDuration"><span class="caption-3 md">{{data.data.interviewDate | date:'mediumDate'}}</span></div>
            <ng-template #otherDuration><span class="caption-3 md">{{data.data?.startDate | date:'MMM dd'}} - {{data.data?.endDate | date:'MMM dd'}}</span></ng-template>
        </div>
    </div>
    <!-- <div class="feedback-parameters"> -->
    <div *ngIf="data.role==='interviewer';else otherContent">
        <div class="feedback-comment-container interview-feedback" *ngFor="let key of data.keys">
                <div class="feedback-category-score caption-5">
                    <span>{{key}}</span>
                    <span>{{data.data?.feedbackDto[key]}}</span>
                </div>
        </div>
    </div>
    <ng-template #otherContent>
        <div class="feedback-comment-container" *ngFor="let element of sortedCategory">
                <div class="feedback-category-score caption-5">
                    <span>{{element.key | titlecase}}</span>
                    <span>{{element.value}}</span>
                </div>
            <ng-container *ngIf="element.comment!==null">
                <div class="caption-5" [ngClass]="{'feedback-comment' : element.comment}">
                    <span>{{element.comment}}</span>
                </div>
            </ng-container>
        </div>
    </ng-template>
    <div  class="feedback-comment-container overall">
        <span class="caption-6">Overall Feedback</span>
        <div class="feedback-comment caption-5" *ngIf="data.role==='interviewer'">
            <span>{{data.data?.feedbackDto.overAllFeedback}}</span>
        </div>
        <div class="feedback-comment caption-5">
            <span>{{data.data?.comments}}</span>
        </div>
    </div>
<!-- </div> -->
</div>
