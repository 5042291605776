import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';

@Injectable({
	providedIn: 'root',
})
export class SnackbarService {
	constructor(private snackBar: MatSnackBar) {}
	/**
	 * alert for the user
	 * @param message message fo the snackbar
	 * @param type type of the snackbar (snackbar-success ,snackbar-error,snackbar-warning)
	 */
	openSnackBar(message: string, type?: string, positionX?: string , positionY?:string) {
		const data = {
			message: message,
			type: type,

		};
		this.snackBar.openFromComponent(SnackbarComponent, {
			data: data,
			panelClass: type,
			duration: 3000,
			verticalPosition: positionX==='bottom' ? `bottom` : 'top',
			horizontalPosition: positionY==='center' ? 'right' : 'end'
		});
	}
}
