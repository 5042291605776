import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IQueryParams, IResponse } from 'src/app/core/models';
import { API_ENDPOINTS } from 'src/app/core/structs';
import { ServicesUtilityService } from '../../utility/services-utility.service';
import { IPrograms } from './../../../models/programs';


@Injectable({
	providedIn: 'root'
})
export class ProgramsService {

	constructor(public http: HttpClient, private servicesUtilityService: ServicesUtilityService) { }

	/**
	 * function to get list of all programs from the BE.
	 * @param http http client for all http requests
	 * @param reqBody redBody for POST method as per Swagger URL 
	 * @returns  list of all programs alongwith programID from the BE.
	 */
	getPrograms(
		http: HttpClient,
		reqBody: IQueryParams
	): Observable<any> {
		return http.post<IResponse<any>>(API_ENDPOINTS.getPrograms, reqBody);
	}

	/**
	 * to add a new program
	 * @param data contains data of the program which is to be added
	 * @returns success/error message
	 */
	addProgram(data: any): Observable<any> {
		return this.http.post<any>(
			API_ENDPOINTS.addProgram,
			data
		);
	}

	/**
	 * to update the details of any given program
	 * @param data data contains all the data of the program after editing has been done by the admin
	 * @returns success/error message
	 */
	updateProgram(data: any): Observable<any> {
		return this.http.put<any>(API_ENDPOINTS.updateProgram, data);
	}

	/**
	 * to update status of program-archive/unarchive
	 * @param program program details
	 * @returns success/error message
	 */
	updateProgramStatus(program: IPrograms): Observable<any> {
		return this.http.patch<any>(
			`${API_ENDPOINTS.updateProgramStatus}/${program.programId}/${program.status}`, {}
		)
	}

	/**
	* Fetch program instances according to search query
	* @param http The HttpClient object for api call
	* @param queryParams The get params for the api
	* @returns program Instances
	*/
	getProgramCategory(
		http: HttpClient,
		queryParams: IQueryParams
	): Observable<IResponse<IPrograms>> {
		const params = this.servicesUtilityService.createHttpParams(queryParams);
		return http.get<IResponse<IPrograms>>(API_ENDPOINTS.getProgramCategory,
			{
				params,
			}
		);
	}

	/**
	 * to get all the details of the program with its batches, trainers and candidates
	 * @param id id of the program whose data is to be fetched
	 * @returns 
	 */
	getProgramDetails(id: number): Observable<any> {
		return this.http.get(`${API_ENDPOINTS.programDetails}/${id}`)
	}

	/**
	 * function to return list of tracks according to the program category ID's selected.
	 * @param reqBody containing list of selected program categories and the searchText if present
	 * @returns list of tracks according to the categoryId selected in the filter
	 */
	getTracksByProgramCategory(reqBody: any) {
		return this.http.post(`${API_ENDPOINTS.getTracksByProgramCategory}`, reqBody)
	}

	/**
	 * function to get list of programs according to the text entered in the input field
	 * @param queryParams queryParam must contain the text entered in the input field in batches dialog
	 * @returns list of programs corresponding to the value entered
	 */
	searchProgram(queryParams: IQueryParams): Observable<any> {
		const params = this.servicesUtilityService.createHttpParams(queryParams);
		return this.http.post(API_ENDPOINTS.searchPrograms, {}, {
			params: params
		}
		);
	}

	/**
	 * function to get list of programs from the programCategoryId's selected from the filter.
	 * @param reqBody reqBody reqBody contains the program CategoryId's list according to which the corresponding programs are to be found.
	 * @returns list of programs according to the program categories selected in the filter.
	 */
	getProgramsByProgramCategory(reqBody: any) {
		const reqBodyParams = this.servicesUtilityService.createHttpParams(reqBody);
		return this.http.get(`${API_ENDPOINTS.getProgramsByProgramCategoryIds}`, {
			params: reqBodyParams
		})
	}

	/**
	* function to get list of tracks as per program Id's selected in the filterbar.
	* @param reqBody reqBody contains list of program Id's selected from the filter.
	* @returns list of tracks filtered according to programId's
	*/
	getTracksByProgramId(reqBody: any) {
		return this.http.post(`${API_ENDPOINTS.getTracksByProgramId}`, reqBody);
	}

}