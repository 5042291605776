<div class="forgot-password-container" *ngIf="!displaySuccessMessage">
    <div>
        <h1>Forgot Password?</h1>
    </div>
    <div>
        <p>Don't worry! We'll email you a temporary</p>
        <p>password.</p>
    </div>
    <div >
        <form [formGroup]="forgotPassword" (ngSubmit)="submitEmailClicked(forgotPassword)" class="functionality">
            <div>
                <label>Email</label>
                <mat-form-field appearance="fill" floatLabel="always" class="social-login">
                    <mat-icon matPrefix svgIcon="envelop"></mat-icon>
                    <input type="email" matInput formControlName="email" placeholder="Enter your email" />
                    <mat-error *ngIf="
                        forgotPassword.controls['email'].hasError('pattern') &&
                        !forgotPassword.controls['email'].hasError('required')
                    ">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="forgotPassword.controls['email'].hasError('required')">
                        Email required
                    </mat-error>
                </mat-form-field>
            </div>
            <button type="submit" mat-flat-button class="mat-flat-button mat-primary lg">
                Get a temporary password
            </button>
        </form>
    </div>
    <div class="back-to-login-footer">

        <button mat-flat-button disableRipple class="tertiary lg" (click)="routeToLogin()">
            <mat-icon svgIcon="left-arrow" class="left-arrow-icon"></mat-icon>
            Back To Login
        </button>
    </div>
</div>

<app-success-msg *ngIf="displaySuccessMessage" [condition]="'forgot'" [forgotEmail]="forgotEmail"></app-success-msg>