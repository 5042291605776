import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { IStatusCard, IStatusData } from 'src/app/core';

@Component({
  selector: 'app-candidate-subheader',
  standalone: true,
  imports: [CommonModule,MatChipsModule,MatButtonModule,MatIconModule],
  templateUrl: './candidate-subheader.component.html',
  styleUrls: ['./candidate-subheader.component.scss']
})
export class CandidateSubheaderComponent {

  
  @Input() statusCard!: IStatusCard;
  @Input() status!: string;
  @Input() dataKeys !:string[];
  @Input() dataValues !:string[];
  @Input() dataList !:IStatusData[];
 
  cssStyles={}

  chipClass(statusName:string){
    if(statusName==="Unmarked"){
      this.cssStyles={'orange':true}
      return this.cssStyles;
    }
    else{
      return {'red':true}
    }
    
  }

}
 