import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { UtilityService } from 'src/app/core';
import { DatepickerComponent } from '../datepicker/datepicker.component';

const DATE_FORMATS = {
	parse: {
		dateInput: 'MMM D,YYYY',
	},
	display: {
		dateInput: 'MMM D,YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
  selector: 'app-date-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatDatepickerModule, MatFormFieldModule, MatIconModule],
  providers:
		[
			MatMomentDateModule,
			{
				provide: DateAdapter,
				useClass: MomentDateAdapter,
				deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
			},

			{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
			{
				provide: MatDialogRef,
				useValue: {}
			},
		],
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit {
	@Input() data: any;
	@Input()formGroup: FormGroup = new FormGroup({});
	@Input() error = false;
	exampleHeader = DatepickerComponent;
	startDate = new FormControl();
	endDate = new FormControl();
	minAllowedDate: Date = new Date();
	constructor(private _utilityService: UtilityService) { }

	ngOnInit() {
		if(this.data?.pipEndDate)
		this.minAllowedDate = this.data?.pipEndDate
	}

	setDate() {
		this.formGroup.get('startDate')?.patchValue(this._utilityService.getDateFormat(this.startDate.value?._i));
		this.formGroup.get('endDate')?.patchValue(this._utilityService.getDateFormat(this.endDate.value?._i));
	}

}
