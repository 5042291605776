<div class="container">
	<h1>UI Component Library</h1>

	<!---drop down------->

	<div class="container">
		<div class="dropdown">
				<mat-form-field appearance="fill" floatLabel="always" class="status-dropdown">
					<mat-icon matPrefix class="status-icon" svgIcon="status-icon"
						[ngClass]="{ 'training-yellow' : statusControl.value === 'in-training', 'exit-red': statusControl.value === 'process-exit', 'resigned-red': statusControl.value === 'resigned', 'confirmed-green': statusControl.value === 'confirmed' }"></mat-icon>
					<mat-select [formControl]="statusControl">
						<mat-option *ngFor="let option of status" [value]="option.value">
							<p>{{option.viewValue}}</p>
							<mat-icon svgIcon="right-tick" *ngIf="statusControl.value===option.value"></mat-icon>
						</mat-option>
					</mat-select>
				</mat-form-field>
		</div>
	</div>

	<!-- filter-sidebar -->
	<div>
		<button mat-flat-button disableRipple class="secondary sm" (click)="showFilterSidebar()">
			Filter
		</button>
	</div>
	<app-filter-sidebar *ngIf="isFilterSidebarActive">
		<mat-drawer-container class="filter-sidebar-container">
			<mat-drawer #sideNav mode="over" opened="true" position="end">

			</mat-drawer>
			<mat-drawer-content>
				<h3>Filter</h3>
				<p>Coming Soon...</p>
			</mat-drawer-content>
		</mat-drawer-container>
	</app-filter-sidebar>

	<!-- Paginator -->
	<h2>Paginator</h2>
	<app-pagination [count]="500"></app-pagination>

	<!-- batches card -->
	<h2>Batches card</h2>
	<mat-card class="batches-card">
		<mat-card-title>Batch 1</mat-card-title>
		<mat-card-subtitle>Track:Data Science & Machine Learning</mat-card-subtitle>
		<mat-divider></mat-divider>
		<mat-card-content>
			<div class="images-wrapper">
				<div class="trainers">
					<p>Trainers</p>
					<app-avatar-list [avatarList]="imagesSrc" [userType]="'trainer'"></app-avatar-list>
					<div class="images-list" *ngFor="let source of imagesSrc">
						<img src="{{source}}" class="default medium" />
						<div *ngIf="(source!==null || source!==''); else noImgSrcMedium">
							<img [src]="source" class="default medium" />
						</div>
						<ng-template #noImgSrcMedium>
							<div class="no-image-medium">
								<p>{{ replacementText }}</p>
							</div>
						</ng-template>
					</div>
				</div>
				<div class="candidates">
					<p>Candidates</p>
					<app-avatar-list [avatarList]="imagesSrc" [userType]="'candidate'"></app-avatar-list>
				</div>
			</div>
		</mat-card-content>
	</mat-card>


	<!-- Typography -->
	<h2>Typography</h2>
	<div class="container">
		<h1>H1 - The quick brown fox jumps over the lazy dog</h1>
		<h2>H2 - The quick brown fox jumps over the lazy dog</h2>
		<h3>H3 - The quick brown fox jumps over the lazy dog</h3>
		<h4>H4 - The quick brown fox jumps over the lazy dog</h4>
		<h5>H5 - The quick brown fox jumps over the lazy dog</h5>
		<h6>H6 - The quick brown fox jumps over the lazy dog</h6>
		<div class="para-2">
			Para 2 - The quick brown fox jumps over the lazy dog
		</div>
		<div class="para-1">
			Para 1 - The quick brown fox jumps over the lazy dog
		</div>
		<div class="base md">
			Base M - The quick brown fox jumps over the lazy dog
		</div>
		<div class="base">
			Base - The quick brown fox jumps over the lazy dog
		</div>
		<div class="caption-1 md">
			Caption 1 M - The quick brown fox jumps over the lazy dog
		</div>
		<div class="caption-1">
			Caption 1 - The quick brown fox jumps over the lazy dog
		</div>
		<div class="caption-2 md">
			Caption 2 M - The quick brown fox jumps over the lazy dog
		</div>
		<div class="caption-2">
			Caption 2 - The quick brown fox jumps over the lazy dog
		</div>
		<div class="caption-3">
			Caption 3 - The quick brown fox jumps over the lazy dog
		</div>
		<div class="primary-caption">
			Primary caption - The quick brown fox jumps over the lazy dog
		</div>
	</div>

	<!-- Tab Group -->
	<h2>Tab Group</h2>
	<div>
		<mat-tab-group>
			<mat-tab>
				<ng-template mat-tab-label>
					My details
					<mat-chip class="primary-chip">25</mat-chip>
				</ng-template>My details
			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
					My details
					<mat-chip class="gray-chip">25</mat-chip>
				</ng-template>My details
			</mat-tab>
			<mat-tab label="My details"> My details</mat-tab>
		</mat-tab-group>
	</div>

	<!-- snackbar buttons -->
	<h2>Snackbar Buttons</h2>
	<div class="snackbar-buttons">
		<button mat-flat-button disableRipple class="secondary sm" (click)="openSnackBar()">
			Default Snackbar
		</button>
		<button mat-flat-button disableRipple class="secondary sm" (click)="openSnackBar('snackbar-success')">
			Success Snackbar
		</button>
		<button mat-flat-button disableRipple class="secondary sm" (click)="openSnackBar('snackbar-warning')">
			Warning Snackbar
		</button>
		<button mat-flat-button disableRipple class="secondary sm" (click)="openSnackBar('snackbar-error')">
			Error Snackbar
		</button>
	</div>

	<!-- Buttons -->
	<h2>Buttons</h2>
	<div class="buttons">
		<div class="wrapper">
			<div>
				<button mat-flat-button color="primary" disableRipple class="sm">
					Primary Small
				</button>
			</div>
			<div>
				<button mat-flat-button color="primary" disableRipple class="lg">
					Primary Large
				</button>
			</div>
			<div>
				<button mat-flat-button color="primary" disableRipple disabled class="sm">
					Primary Small
				</button>
			</div>
			<div>
				<button mat-flat-button color="primary" disableRipple disabled class="lg">
					Primary Large
				</button>
			</div>
			<div>
				<button mat-flat-button color="primary" disableRipple class="sm">
					<mat-icon svgIcon="logout"></mat-icon>
					Logout
				</button>
			</div>
			<div>
				<button mat-flat-button color="primary" disableRipple class="lg">
					<mat-icon svgIcon="logout"></mat-icon>
					Logout
				</button>
			</div>
		</div>
		<div class="wrapper">
			<div>
				<button mat-flat-button disableRipple class="secondary sm">
					Secondary Small
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple class="secondary lg">
					Secondary Large
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple disabled class="secondary sm">
					Secondary Small
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple disabled class="secondary lg">
					Secondary Large
				</button>
			</div>

			<div>
				<button mat-flat-button disableRipple class="secondary sm error-red">
					Secondary Small
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple class="secondary lg success-green">
					Secondary Large
				</button>
			</div>

			<div>
				<button mat-flat-button disableRipple class="secondary sm">
					<mat-icon svgIcon="logout"></mat-icon>
					Logout
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple class="secondary lg">
					<mat-icon svgIcon="logout"></mat-icon>
					Logout
				</button>
			</div>
		</div>
		<div class="wrapper">
			<div>
				<button mat-flat-button disableRipple class="tertiary sm">
					Tertiary Small
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple class="tertiary lg">
					Tertiary Large
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple disabled class="tertiary sm">
					Tertiary Small
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple disabled class="tertiary lg">
					Tertiary Large
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple class="tertiary sm">
					<mat-icon svgIcon="logout"></mat-icon>
					Logout
				</button>
			</div>
			<div>
				<button mat-flat-button disableRipple class="tertiary lg">
					<mat-icon svgIcon="logout"></mat-icon>
					Logout
				</button>
			</div>
		</div>
		<div class="wrapper">
			<div>
				<button mat-flat-button disableRipple class="social-login">
					<mat-icon svgIcon="google-signin"></mat-icon>
					Signin With Google
				</button>
			</div>
		</div>
	</div>

	<!-- Input -->
	<h2>Input</h2>
	<div class="wrapper inputs">
		<div>
			<label>Name</label>
			<mat-form-field appearance="fill" floatLabel="always" [ngClass]="{ filled: nameFormControl.value }">
				<input type="text" matInput [formControl]="nameFormControl" placeholder="John Doe" />
				<mat-error *ngIf="nameFormControl.hasError('required')">
					Name is required
				</mat-error>
			</mat-form-field>
		</div>
		<div>
			<label>Email</label>
			<mat-form-field appearance="fill" floatLabel="always" [ngClass]="{ filled: emailFormControl.value }">
				<mat-icon matPrefix svgIcon="envelop"></mat-icon>
				<input type="email" matInput [formControl]="emailFormControl" placeholder="john@example.com" />
				<mat-error *ngIf="
						emailFormControl.hasError('email') &&
						!emailFormControl.hasError('required')
					">
					Please enter a valid email address
				</mat-error>
				<mat-error *ngIf="emailFormControl.hasError('required')">
					Email is required
				</mat-error>
			</mat-form-field>
		</div>
		<div>
			<label>Password</label>
			<mat-form-field appearance="fill" floatLabel="always" [ngClass]="{ filled: passwordFormControl.value }">
				<mat-icon matPrefix svgIcon="lock"></mat-icon>
				<input [type]="showPassword ? 'text' : 'password'" matInput [formControl]="passwordFormControl"
					placeholder="Enter Password" />
				<mat-icon matSuffix [svgIcon]="showPassword ? 'eye' : 'eye-slash'" (click)="togglePassword()">
				</mat-icon>
				<mat-error *ngIf="passwordFormControl.hasError('required')">
					Password is required
				</mat-error>
			</mat-form-field>
		</div>
		<div>
			<label>Text</label>
			<mat-form-field appearance="fill" floatLabel="always">
				<mat-icon matPrefix svgIcon="lock"></mat-icon>
				<input type="text" matInput [formControl]="disableFormControl" placeholder="Enter text" />
				<mat-icon matSuffix [svgIcon]="showPassword ? 'eye' : 'eye-slash'" (click)="togglePassword()">
				</mat-icon>
				<mat-error *ngIf="disableFormControl.hasError('required')">
					Name is required
				</mat-error>
			</mat-form-field>
		</div>
		<div>
			<mat-form-field appearance="fill" floatLabel="always" [ngClass]="{ filled: nameFormControl.value }">
				<mat-icon matPrefix svgIcon="search"></mat-icon>
				<input type="text" matInput [formControl]="nameFormControl" placeholder="Search" />
			</mat-form-field>
		</div>
		<div>
			<label>Address</label>
			<mat-form-field appearance="fill">
				<textarea matInput placeholder="Ex 100 Main St" rows="10">
				</textarea>
			</mat-form-field>
		</div>
		<div>
			<label>Phone Number</label>
			<mat-form-field appearance="fill" floatLabel="always" [ngClass]="{ filled: phoneFormControl.value }">
				<span matPrefix class="phone">
					<span class="country-code"> +91 </span>
				</span>
				<input type="tel" matInput [formControl]="phoneFormControl" placeholder="12345 67890" />
				<mat-error *ngIf="phoneFormControl.hasError('pattern')">
					Please enter a valid Phone Number
				</mat-error>
			</mat-form-field>
		</div>
	</div>

	<!-- Checkbox -->
	<h2>Checkbox</h2>
	<div class="container">
		<div class="checkbox">
			<mat-checkbox>Remember me </mat-checkbox>
		</div>
	</div>

	<!-- Dropdown -->
	<h2>Input Dropdown</h2>

	<div class="container">
		<div class="dropdown">
			<div>
				<label class="label">Label</label>
				<mat-form-field appearance="fill" floatLabel="always">
					<mat-select [(ngModel)]="selectedValue" placeholder="Select">
						<mat-option value="p">p<mat-icon svgIcon="checkmark-icon" *ngIf="selectedValue === 'p'"
								class="tick-icon">
							</mat-icon>
						</mat-option>
						<mat-option value="a">a<mat-icon svgIcon="checkmark-icon" *ngIf="selectedValue === 'a'"
								class="tick-icon">
							</mat-icon>
						</mat-option>
						<mat-option value="b">b<mat-icon svgIcon="checkmark-icon" *ngIf="selectedValue === 'b'"
								class="tick-icon">
							</mat-icon>
						</mat-option>
						<mat-option value="c">c<mat-icon svgIcon="checkmark-icon" *ngIf="selectedValue === 'c'"
								class="tick-icon">
							</mat-icon>
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<!-- dropdown with checkbox -->
			<h3>Checkbox Dropdown with text</h3>
			<div class="checkbox-dropdown">
				<label class="label">Label</label>
				<mat-form-field appearance="fill" floatLabel="always">
					<mat-select placeholder="Select" multiple>
						<mat-option value="">one</mat-option>
						<mat-option value="">two</mat-option>
						<mat-option value="">three</mat-option>
						<mat-option value="">four</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<!-- dropdown with chip -->
			<h3>dropdown with chip</h3>
			<div class="chip-dropdown">
				<label class="label">Label</label>
				<mat-form-field appearance="fill" floatLabel="always">
					<mat-select placeholder="Select" multiple [formControl]="itemsControl">
						<mat-select-trigger>
							<mat-chip-list>
								<mat-chip *ngFor="let item of itemsControl.value" [removable]="true"
									(removed)="removeChip(item)">
									{{ item }}
									<mat-icon svgIcon="cancel-icon" class="cancel-icon" class="cancel-icon"
										matChipRemove></mat-icon>
								</mat-chip>
							</mat-chip-list>
						</mat-select-trigger>
						<mat-option *ngFor="let item of data" [value]="item">{{
							item
							}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>

	<!-- Tooltip -->
	<h2>Tooltip</h2>
	<div class="container tooltips">
		<button mat-flat-button color="primary" disableRipple class="lg" matTooltip="Lorem Ipsum"
			matTooltipPosition="below" matTooltipHideDelay="300000">
			Below
		</button>
		<button mat-flat-button color="primary" disableRipple class="lg" matTooltip="Lorem Ipsum"
			matTooltipPosition="above" matTooltipHideDelay="300000">
			Above
		</button>
		<button mat-flat-button color="primary" disableRipple class="lg" matTooltip="Lorem Ipsum"
			matTooltipPosition="right" matTooltipHideDelay="300000">
			Right
		</button>
		<button mat-flat-button color="primary" disableRipple class="lg" matTooltip="Lorem Ipsum"
			matTooltipPosition="left" matTooltipHideDelay="300000">
			Left
		</button>

		<div class="wrapper inputs">
			<div>
				<label class="label">Label</label>
				<mat-form-field appearance="fill" floatLabel="always">
					<mat-select [(ngModel)]="selectedValue" placeholder="Select">
						<mat-option value="p">p<mat-icon svgIcon="checkmark-icon" *ngIf="selectedValue === 'p'"
								class="tick-icon">
							</mat-icon>
						</mat-option>
						<mat-option value="a">a<mat-icon svgIcon="checkmark-icon" *ngIf="selectedValue === 'a'"
								class="tick-icon">
							</mat-icon>
						</mat-option>
						<mat-option value="b">b<mat-icon svgIcon="checkmark-icon" *ngIf="selectedValue === 'b'"
								class="tick-icon">
							</mat-icon>
						</mat-option>
						<mat-option value="c">c<mat-icon svgIcon="checkmark-icon" *ngIf="selectedValue === 'c'"
								class="tick-icon">
							</mat-icon>
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<!-- dropdown with checkbox -->
		<h3>Checkbox Dropdown with text</h3>
		<div class="inputs wrapper">
			<div>
				<label class="label">Label</label>
				<mat-form-field appearance="fill" floatLabel="always">
					<mat-select placeholder="Select" multiple>
						<mat-option value="">one</mat-option>
						<mat-option value="">two</mat-option>
						<mat-option value="">three</mat-option>
						<mat-option value="">four</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<!-- dropdown with chip -->
		<h3>dropdown with chip</h3>
		<div class="inputs wrapper">
			<div>
				<label class="label">Label</label>
				<mat-form-field appearance="fill" floatLabel="always">
					<mat-select placeholder="Select" multiple [formControl]="itemsControl">
						<mat-select-trigger>
							<mat-chip-list>
								<mat-chip *ngFor="let item of itemsControl.value" [removable]="true"
									(removed)="removeChip(item)">
									<span class="chip-content">{{ item }}</span>
									<mat-icon svgIcon="cancel-icon" class="cancel-icon" matChipRemove></mat-icon>
								</mat-chip>
							</mat-chip-list>
						</mat-select-trigger>
						<mat-option *ngFor="let item of data" [value]="item">{{
							item
							}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>

	<!-- Tooltip -->
	<h2>Tooltip</h2>
	<div class="container tooltips">
		<button mat-flat-button color="primary" disableRipple class="lg" matTooltip="Lorem Ipsum"
			matTooltipPosition="below" matTooltipHideDelay="300000">
			Below
		</button>
		<button mat-flat-button color="primary" disableRipple class="lg" matTooltip="Lorem Ipsum"
			matTooltipPosition="above" matTooltipHideDelay="300000">
			Above
		</button>
		<button mat-flat-button color="primary" disableRipple class="lg" matTooltip="Lorem Ipsum"
			matTooltipPosition="right" matTooltipHideDelay="300000">
			Right
		</button>
		<button mat-flat-button color="primary" disableRipple class="lg" matTooltip="Lorem Ipsum"
			matTooltipPosition="left" matTooltipHideDelay="300000">
			Left
		</button>
	</div>

	<!-- badge -->
	<div class="inputs container">
		<h3>Badges</h3>
		<div>
			<!-- small-badge -->
			<div>
				<mat-chip-list>
					<mat-chip [removable]="true" class="small">
						<span class="chip-content">Small</span>
						<mat-icon svgIcon="cancel-icon" class="cancel-icon" matChipRemove></mat-icon>
					</mat-chip>
				</mat-chip-list>
			</div>
			<!-- Large-Badge -->
			<div>
				<mat-chip-list>
					<mat-chip [removable]="true" class="large">
						<span class="chip-content">Large</span>
						<mat-icon svgIcon="cancel-icon" class="cancel-icon" matChipRemove></mat-icon>
					</mat-chip>
				</mat-chip-list>
			</div>
		</div>

		<div class="container">
			<div class="avatar">
				<div class="default-with-image avatar-list">
					<img src="{{ imgSrc }}" class="default small" />
					<img src="{{ imgSrc }}" class="default medium" />
					<img src="{{ imgSrc }}" class="default large" />
					<img src="{{ imgSrc }}" class="default larger" />
					<img src="{{ imgSrc }}" class="default largest" />
				</div>
				<div class="focussed-with-image avatar-list">
					<img src="{{ imgSrc }}" class="focussed small" />
					<img src="{{ imgSrc }}" class="focussed medium" />
					<img src="{{ imgSrc }}" class="focussed large" />
					<img src="{{ imgSrc }}" class="focussed larger" />
					<img src="{{ imgSrc }}" class="focussed largest" />
				</div>

				to display initials of the user incase image in not available at
				<div class="default-without-image avatar-list">

					<div *ngIf="noImg.length !== 0; else noImgSrc">
						<img src="{{ imgSrc }}" class="default small" />
					</div>
					<ng-template #noImgSrc>
						<div class="no-image-small">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>

					<div *ngIf="noImg.length !== 0; else noImgSrcMedium">
						<img src="{{ imgSrc }}" class="default medium" />
					</div>
					<ng-template #noImgSrcMedium>
						<div class="no-image-medium">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>

					<div *ngIf="noImg.length !== 0; else noImgSrcLarge">
						<img src="{{ imgSrc }}" class="default large" />
					</div>
					<ng-template #noImgSrcLarge>
						<div class="no-image-large">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>

					<div *ngIf="noImg.length !== 0; else noImgSrcLarger">
						<img src="{{ imgSrc }}" class="default larger" />
					</div>
					<ng-template #noImgSrcLarger>
						<div class="no-image-larger">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>
					<div *ngIf="noImg.length !== 0; else noImgSrcLargest">
						<img src="{{ imgSrc }}" class="default largest" />
					</div>

					<ng-template #noImgSrcLargest>
						<div class="no-image-largest">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>
				</div>

				<div class="focussed-without-image avatar-list">
					<div *ngIf="noImg.length !== 0; else noImgSrcFocussed">
						<img src="{{ imgSrc }}" class="focussed small" />
					</div>
					<ng-template #noImgSrcFocussed>
						<div class="no-image-small-focussed">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>

					<div *ngIf="noImg.length !== 0; else noImgSrcMediumFocussed">
						<img src="{{ imgSrc }}" class="focussed medium" />
					</div>
					<ng-template #noImgSrcMediumFocussed>
						<div class="no-image-medium-focussed">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>

					<div *ngIf="noImg.length !== 0; else noImgSrcLargeFocussed">
						<img src="{{ imgSrc }}" class="focussed large" />
					</div>
					<ng-template #noImgSrcLargeFocussed>
						<div class="no-image-large-focussed">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>

					<div *ngIf="noImg.length !== 0; else noImgSrcLargerFocussed">
						<img src="{{ imgSrc }}" class="focussed larger" />
					</div>
					<ng-template #noImgSrcLargerFocussed>
						<div class="no-image-larger-focussed">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>
					<div *ngIf="noImg.length !== 0; else noImgSrcLargestFocussed">
						<img src="{{ imgSrc }}" class="focussed largest" />
					</div>

					<ng-template #noImgSrcLargestFocussed>
						<div class="no-image-largest-focussed">
							<p>{{ replacementText }}</p>
						</div>
					</ng-template>
				</div>
			</div>
		</div>

		<!-- Radio button -->
		<div class="inputs wrapper">
			<mat-radio-group aria-label="Select an option">
				<mat-radio-button value="1" checked="checked">Internal</mat-radio-button>
				<mat-radio-button value="2">External</mat-radio-button>
			</mat-radio-group>
		</div>
	</div>
</div>

<form class="example-form">
	<mat-form-field class="example-full-width" appearance="fill">
		<input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
			[matAutocomplete]="auto">
		<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
			<mat-option *ngFor="let option of filteredOption | async" [value]="option">
				{{option}}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>

</form>

<mat-chip-list #chipList>
	<ng-container *ngFor="let select of selectData">
		<mat-chip class="cardinal-colors" (click)="removeChip(select)">
			{{ select.item }}
			<mat-icon class="mat-chip-remove">cancel</mat-icon>
		</mat-chip>
	</ng-container>
</mat-chip-list>

<mat-form-field class="example-full-width" appearance="fill">
	<input matInput type="text" placeholder="placeholder" [matAutocomplete]="auto" [formControl]="selectControl">
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
	<mat-option *ngFor="let data of filteredData | async">
		<div (click)="optionClicked($event, data)">
			<mat-checkbox [checked]="data.selected" (change)="toggleSelection(data)" (click)="$event.stopPropagation()">
				{{ data.item }}
			</mat-checkbox>
		</div>
	</mat-option>
</mat-autocomplete>

<!-- ng5 slider -->
<div class="inputs">
	<p class="caption-7 md">Average Score</p>
	<ngx-slider [value]="value" [highValue]="highValue" [options]="options"></ngx-slider>
</div>

<!-- mat-slider -->
<div class="inputs">
	<app-slider>
	</app-slider>
</div>


<app-candidate-subheader [status]="'Unmarked'" [statusCard]="statusCard" [dataList]="statusData">
</app-candidate-subheader>

<!-- Date Picker -->
<div class="date-picker" [formGroup]="programForm">
	<label>Date<span class="asterisk"> *</span></label>
	<mat-form-field appearance="fill" floatLabel="always" (click)="datePicker.open()">
		<input matInput placeholder="Select date" formControlName="date" [matDatepicker]="datePicker">
		<mat-icon matPrefix svgIcon="datepicker"></mat-icon>
		<mat-datepicker #datePicker [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
		<mat-error
			*ngIf="programForm.get('date')?.hasError('required') ">
			Please choose the date
		</mat-error>
	</mat-form-field>
</div>



