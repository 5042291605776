import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScoreChipComponent } from "../../score-chip/score-chip.component";

@Component({
    standalone: true,
    selector: 'app-admin-candidates-empty-state',
    templateUrl: './admin-candidates-empty-state.component.html',
    styleUrls: ['./admin-candidates-empty-state.component.scss'],
    imports: [CommonModule, MatIconModule, ScoreChipComponent, MatTooltipModule]
})
export class AdminCandidatesEmptyStateComponent {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  @Input() graphType!:string;
  @Input() svgIcon = '';
  @Input() msgTitle = '';
	@Input() msgLine1 = '';
	@Input() msgLine2 = '';
  @Input() title!:string;
  @Input() emptyScoreChip=false;

}
