<div *ngIf="data.data?.action === 'Confirm'; else anotherDialog">
	<header mat-dialog-header class="confiramtion-header">
		<mat-icon svgIcon="{{ data.data?.logo }}" class="confirmation-icon"></mat-icon>
		<h6>{{ data.data?.itemName }}</h6>
	</header>
	<div class="confiramtion-subtext">
		<div *ngIf="data.data?.itemName === 'Confirmation'; else another">
			<div class="caption-1">
				<form [formGroup]="managerForm" (ngSubmit)="onSubmit()">
					<div class="assigning-manager">
						<label class="label-manager">Assign Reporting manager<span class="asterisk"> *</span></label>
						<mat-form-field appearance="fill" floatLabel="always"
							[ngClass]="{'invalid-input-box': showEmptyLastNameError===true}">
							<input matInput #myInput type="text" placeholder="Select" formControlName="managerId"
								[matAutocomplete]="auto">
							<mat-icon matSuffix svgIcon="dropdown-arrow" class="dropdown-icon"
								(click)="toggleDropdown($event)"></mat-icon>
							<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" #autocomplete>
								<mat-option *ngFor="let manager of managerList" [value]="manager.managerName"
									(click)="managerOptionClicked(manager)">
									{{manager.managerName}}
									<mat-icon svgIcon="checkmark-icon"
										*ngIf="managerForm.get('managerId')?.value === manager.managerName"
										class="tick-icon"></mat-icon>
								</mat-option>
							</mat-autocomplete>
							<mat-error *ngIf="managerForm.get('managerId')?.hasError('required')">
								Please assign reporting manager
							</mat-error>
						</mat-form-field>
					</div>
					<div class="mat-dialog-actions confirm-dialog">
						<button mat-flat-button disableRipple class="secondary sm" type="button"
							(click)="closeDialog('cancel')">
							Cancel
						</button>
						<button mat-flat-button color="primary" disableRipple class="sm" type="submit">
							{{data.data?.action}}
						</button>
					</div>
				</form>
			</div>
		</div>
		<ng-template #another>
			<div class="caption-1">
				<p class=" dialog-message">{{data.data.message}}</p>
			</div>
			<div class="mat-dialog-actions">
				<button mat-flat-button disableRipple class="secondary sm" type="button"
					(click)="closeDialog('cancel')">
					Cancel
				</button>
				<button mat-flat-button color="primary" disableRipple class="sm" type="button"
					(click)="closeDialog(data.data?.action)">
					{{data.data?.action}}
				</button>
			</div>
		</ng-template>
	</div>
</div>

<ng-template #anotherDialog>

	<header mat-dialog-header class="confiramtion-header">
		<mat-icon svgIcon="{{ data.data?.logo }}" class="confirmation-icon"></mat-icon>
		<h6 *ngIf="data.data?.name === 'unmarkSingle'">{{ data.data?.action }} {{ data.data?.itemName }}?</h6>
		<h6 *ngIf="data.data?.name !== 'unmarkSingle' && data.data?.name !== 'unmarkMultiple' && data.data?.name !== 'cancel'">{{ data.data?.action }}
			'{{ data.data?.itemName }}'?</h6>
		<h6 *ngIf="data.data?.name === 'unmarkMultiple'">{{ data.data?.action }} {{ data.data?.count }} Candidates from
			{{ data.data?.itemName }}?</h6>
			<h6 *ngIf="data.data?.name === 'cancel'">{{ data.data?.itemName }}</h6>
	</header>

	<div class="confiramtion-subtext unmark-pip-dialog" [ngSwitch]="data.data?.name" class="standard-dialog-content">

		<div class="caption-1" *ngSwitchCase="'archive'">

			<div *ngIf="data.data?.type!=='Mentor' && data.data?.type!=='Admin' && data.data?.type!=='Trainer' && data.data?.type !== 'Interviewer'; else nonMentors">
				This {{ data.data?.type }} will be moved to the archived section.
			</div>
			<ng-template #nonMentors>
				<div class="caption-1">
					Once you archive this {{ data.data?.type }}, they will permanently
					<p>lose access to the system.</p>
				</div>
			</ng-template>
		</div>

		<div *ngSwitchCase="'unArchive'">

			<div class="caption-1" *ngIf="data.data?.type!=='Mentor' && data.data?.type!=='Admin' && data.data?.type!=='Trainer' && data.data?.type!=='Interviewer';else nonMentors">This {{ data.data?.type }} will be
				moved to the current section</div>
			<ng-template #nonMentors>
				<div class="caption-1">
					Once you unarchive this {{ data.data?.type }}, they will regain
					<p>access to the system.</p>
				</div>
			</ng-template>

		</div>

		<div class="caption-1" *ngSwitchCase="'unmarkSingle'">
			<p>This {{ data.data?.type }} will be removed from PIP</p>
			<p>and added to Training.</p>
		</div>

		<div class="caption-1" *ngSwitchCase="'cancel'">
			<p>Once you cancel this interview, you need to</p>
			<p>set a new interview with this candidate.</p>
		</div>

		<div class="caption-1" *ngSwitchCase="'unmarkMultiple'">
			<p>The selected {{ data.data?.count }} candidates will be removed from</p>
			<p>PIP and added to Training.</p>
		</div>

		<div class="caption-1" *ngSwitchCase="'delete'">
			<p>All the details related to this interview will be</p>
				<p> deleted. If you want to set an interview again with </p>
			<p> this candidate, you have to add new details.</p>
		</div>

	</div>

	<div class="mat-dialog-actions">
		<button *ngIf="data.data?.name !== 'cancel'" mat-flat-button disableRipple class="secondary sm" type="button" (click)="closeDialog('cancel')">
			Cancel
		</button>
		<button *ngIf="data.data?.name === 'cancel'" mat-flat-button disableRipple class="secondary sm" type="button" (click)="closeDialog('cancel')">
			No
		</button>
		<button mat-flat-button color="primary" disableRipple class="sm" type="button"
			(click)="closeDialog(data.data?.action)">
			{{data.data?.action}}
		</button>
	</div>
</ng-template>
