import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';

const materialModules = [MatChipsModule, MatTooltipModule];

@Component({
	selector: 'app-score-chip[score]',
	standalone: true,
	imports: [CommonModule, ...materialModules],
	templateUrl: './score-chip.component.html',
	styleUrls: ['./score-chip.component.scss']
})
export class ScoreChipComponent implements OnChanges {
	@Input() score = 0;
	tooltipText = 'Feedback not added yet';
	tooltipPosition = 'below';
	@Input() tooltipClass='';
	customDigit!: number;
	avgScore!: string

	ngOnChanges(): void {
		this.avgScore = this.score?.toString();
		if (!this.avgScore?.includes('.')) {
			this.customDigit = Number(this.avgScore)
		} else {
			this.avgScore = this.avgScore?.slice(0, (this.avgScore?.indexOf(".")) + 1 + 1);
			this.customDigit = Number(this.avgScore)
		}
	}
}