import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarListComponent } from '../avatar-list/avatar-list.component';
import { DateInputComponent } from '../date-input/date-input.component';
import { EllipsisChipComponent } from '../ellipsis-chip/ellipsis-chip.component';
import { ScoreChipComponent } from '../score-chip/score-chip.component';
import { AutocompleteDropdownComponent } from './../autocomplete-dropdown/autocomplete-dropdown.component';
import { LoaderComponent } from './../loader/loader.component';
import { NamedAvatarComponent } from './../named-avatar/named-avatar.component';
import { PaginationComponent } from './../pagination/pagination.component';

import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { TimeConversionPipe } from 'src/app/core/pipes/time-conversion.pipe';
import { DropDownComponent } from '../drop-down/drop-down.component';
import { HeaderCheckboxDropdownComponent } from '../header-checkbox-dropdown/header-checkbox-dropdown.component';
import { RankComponent } from '../rank/rank.component';

@Component({
	selector: 'app-data-grid',
	standalone: true,
	imports: [
		CommonModule,
		MatTableModule,
		MatInputModule,
		MatTabsModule,
		MatButtonModule,
		MatTooltipModule,
		MatIconModule,
		MatSortModule,
		MatFormFieldModule,
		MatPaginatorModule,
		PaginationComponent,
		MatTooltipModule,
		LoaderComponent,
		MatChipsModule,
		ScoreChipComponent,
		AvatarListComponent,
		NamedAvatarComponent,
		EllipsisChipComponent,
		MatSelectModule,
		ReactiveFormsModule,
		DateInputComponent,
		RankComponent,
		EllipsisChipComponent,
		FormsModule,
		DropDownComponent,
		AutocompleteDropdownComponent,
		MatCheckboxModule,
		MatAutocompleteModule,
		HeaderCheckboxDropdownComponent,
		MatMenuModule,
		TimeConversionPipe
	],
	templateUrl: './data-grid.component.html',
	styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent implements OnChanges {
	newStatus: any;
	initialSortedColumn = "";
	@Input()
	typeOfData = '';
	@Input()
	fetchingData = false;
	dataSource: any;
	@Input() totalCount = 0;
	displayedColumn: Array<any> = [];
	@Input()
	pageObject: any = {};
	@Input()
	columnHead: Array<any> = [];
	@Input()
	columnData: Array<any> = [];
	@Input()
	dropDownOptions !: Array<any>
	@Input() showInDeterminate!:any;
	headerCheckboxChecked  = false;
	@Input() 
	intermediate   = false;
	@Input () 
	uncheckHeader  = true;
	@Input() isModal = false;
	openDropdown  = false;
	@Output() headerDropdownOption = new EventEmitter();
	@ViewChild('headerCheckbox') headerCheckbox !: MatCheckbox;
	@Output()
	gridAction: EventEmitter<any> = new EventEmitter<any>();
	@Output()
	pagination: EventEmitter<any> = new EventEmitter<any>();
	@Output()
	sortGrid: EventEmitter<string> = new EventEmitter<string>();
	@Output()
	elementClick: EventEmitter<string> = new EventEmitter<string>();
	@Output()
	notifyOnClick: EventEmitter<any> = new EventEmitter<any>();
	@Output()
	namedAvatarClicked: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('arrowDown') arrowDown !: ElementRef<HTMLDivElement> 
	@Output() candidateStatus = new EventEmitter;
	@Output() checkboxClick: EventEmitter<any> = new EventEmitter();
	@Output() checkboxHeaderClicked=new EventEmitter();
	@Output() userIsSelected = new EventEmitter();
	dropDownX !: number;
	dropDownY !: number;
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	constructor() { 
		this.handleHeaderDropdown();
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.initialSortedColumn = this.columnHead[0].id;
		this.dataSource = new MatTableDataSource(this.columnData);
		this.displayedColumn = this.columnHead.map(key => key.id);
		
	}

	/**
	 * @description
	 * @author Sameer Khan
	 * @date 2021-07-17
	 * @param {*} event
	 * @memberof SharedTableComponent
	 */
	columnSort(event: any) {
		this.sortGrid.emit(event);
		// this.sortGrid.emit({${event.active},${event.direction}});
	}

	/**
	 * @description
	 * @author Sameer Khan
	 * @date 2021-07-17
	 * @param {*} event
	 * @memberof SharedTableComponent
	 */
	gridRowClick(row: any, action: string) {
		this.gridAction.emit({ row, action });

	}

	/**
	 * @description
	 * @author Sameer Khan
	 * @date 2021-07-17
	 * @param {*} event
	 * @memberof SharedTableComponent
	 */
	paginationClick(event: any) {
		this.pagination.emit(event);
	}

	getPageDetails(event: any) {
		this.pagination.emit(event.pageNumber);
	}

	gridElementClick(event: any) {
		this.elementClick.emit(event);
	}

	handleCountClick(event: any) {
		this.notifyOnClick.emit(event);
	}

	onNamedAvatarClicked(event: any) {
		this.namedAvatarClicked.emit(event);
	}

	currentPagePermissions: any = {};
	@Output()
	statusUpdate: EventEmitter<string> = new EventEmitter<string>();
	get statusUpdateCheck(): boolean {
		if (this.currentPagePermissions.subTabs) {
			const updateFeature = this.currentPagePermissions.subTabs.find((item: any) => item.key === 'update');
			return updateFeature ? updateFeature.checked : false;
		}
		return false;
	}

	onOptionsSelected(row: any) {
		this.statusUpdate.emit(row);
	}

	updatedStatus(status: any) {
		this.newStatus = status;
		this.newStatus['singleSelect'] = true;
		this.candidateStatus.emit(this.newStatus);
	}

	checkboxClicked(row: any, event: any) {
		if(!event.checked && this.headerCheckboxChecked){
			this.columnData.forEach((data:any)=>{
				if(data.userId!==row.userId){
					data.checked = true
				}
			})
			this.headerCheckboxChecked = false;
		}
		this.checkboxClick.emit({ row: row, value: event.checked });
	}
	
	userClicked(event: any) {
		this.userIsSelected.emit(event);
	}

	headerCheckboxClicked(event:any){
		this.checkboxHeaderClicked.emit(event);
		if(this.intermediate){
			// this.headerCheckbox.indeterminate = false;
			this.headerCheckbox.checked = false;
		}
	}

	handleHeaderDropdown(){
		window.addEventListener('click', (e:Event)=>{
			if(e.target!==this.arrowDown.nativeElement && this.openDropdown){
				this.openDropdown = false;
			}
		})
	}

	openDropDown(){
		setTimeout(()=>{
			this.openDropdown = !this.openDropdown
		}, 1)
	}

}
//if using checkbox send this function as an input in the data grid function and whenever a click is made to header 
// checkbox then emit an event which will check if indeterminate or not.We need to update the indeterminate value of that element as well to true/false.
// showInDeterminate(){
	// 	if(this.selectCheckbox.totalSelected>0){
	// 		return true;
	// 	}else{
	// 		return false;
	// 	}
	// }