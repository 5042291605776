import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {

  login() {

    // Oauth Config
    let redirectUri;
    if (window.location.host === 'localhost:4200') redirectUri = encodeURI(`http://${window.location.host}/auth/login`);
    else redirectUri = encodeURI(`https://${window.location.host}/auth/login`);
    const requestUri = "https://accounts.google.com/o/oauth2/v2/auth";
    const scope = "email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/calendar";
    const includeGrantedScope = true;
    const responseType = "code id_token"
    const state = "state_parameter_passthrough_value";
    const clientId = "702571129348-hnur9gmd1jst0u09iq0t54p636543esp.apps.googleusercontent.com";
    const nonce = "fIGNPANmnRGOI5ES4mbeIhWi580K8yvwgILZK9rn5oA";
    const accessType = "offline";

    const uri = `${requestUri}?scope=${scope}&include_granted_scopes=${includeGrantedScope}&response_type=${responseType}&state=${state}&redirect_uri=${redirectUri}&client_id=${clientId}&nonce=${nonce}&access_type=${accessType}`;  //login request URI
    window.open(uri, "_self");  //sign in with google
  }
}
