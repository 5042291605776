export class API_ENDPOINTS {
	// Auth Endpoints
	public static auth = 'auth';
	public static authLogin = `${this.auth}/login`;
	public static forgotPassword = `${this.auth}/forgotPassword`;
	public static resetPassword = `${this.auth}/changePassword`;

	//admin api
	public static admin = 'admin';

	//super-admin api
	public static superAdmin = 'superAdmin';

	//mentor api
	public static mentor = `mentor`;
	// interview feedback tab api
	public static interviewer = `interviewer`;

	//notification api
	public static notifications = 'notifications';

	

	// users api
	public static findUserDetails = `${this.auth}/userInfo`;
	public static oauthData = `${this.auth}/googleUserDetails`;
	public static getUsers = `${this.admin}/users/getAllUsers`;
	public static getAllCandidatesForNewBatch = `${this.admin}/users/getAllCandidatesForNewBatch`;

	//training api
	public static adminTraining = `${this.admin}/training`;
	public static findEvaluations = `${this.admin}/evaluationType/getAllEvaluationType`;
	public static findTrainings = `${this.adminTraining}/getTrainings`;
	public static createTraining = `${this.adminTraining}/addTraining`;
	public static updateTrainingStatus = `${this.adminTraining}/updateTrainingStatus`;
	public static updateTraining = `${this.adminTraining}/updateTraining`;
	public static deleteTraining = `${this.adminTraining}/deleteTraining`;
	public static searchTraining = `${this.adminTraining}/searchTrainings`;

	//track api
	public static adminTrack = `${this.admin}/track`;
	public static getTracks = `${this.adminTrack}/getAllTracks`;
	public static createTrack = `${this.adminTrack}/addTrack`;



	public static updateTrack = `${this.adminTrack}/updateTrack`;
	public static getAllTrainingsFromTracks = `${this.adminTrack}/getAllTrainingFromTracks`;
	public static updateTrackStatus = `${this.adminTrack}/updateTrackStatus`;
	public static getTracksByProgram = `${this.adminTrack}/getTracksByProgram`;
	public static searchTracks = `${this.adminTrack}/searchTracks`;

	//admin-programs api
	public static adminPrograms = `${this.admin}/program`;
	public static getPrograms = `${this.adminPrograms}/getAllPrograms`;
	public static addProgram = `${this.adminPrograms}/addProgram`;
	public static updateProgram = `${this.adminPrograms}/updateProgram`;
	public static updateProgramStatus = `${this.adminPrograms}/updateProgramStatus`;
	public static programDetails = `${this.adminPrograms}/getBatchesOfProgram`;
	public static searchPrograms = `${this.adminPrograms}/searchPrograms`;
	public static getProgramsByProgramCategoryIds = `${this.adminPrograms}/getProgramsByProgramCategoryIds`;
	public static getTracksByProgramId = `${this.adminPrograms}/getTracksByProgramIdList`;

	//program category
	public static adminProgramCategory = `${this.admin}/programCategory`;
	public static getProgramCategory = `${this.adminProgramCategory}/getProgramCategories`;
	public static getTracksByProgramCategory = `${this.adminProgramCategory}/getTracksByProgramCategory`

	//admin-trainer api
	public static adminTrainer = `${this.admin}/trainer`;
	public static getAllUsers = `${this.admin}/users/getAllUsers`
	public static getUsersWithNoRoles = `${this.admin}/users/getUserWithRole`;
	public static getTrainers = `trainer/getTrainers`;
	public static getBatchesForTrainer = `trainer/getBatchesForTrainer`;
	public static getTrainingsForTrainer = `trainer/getTrainingsForTrainer`;
	public static updateTrainerStatus = `trainer/updateTrainerStatus`;
	public static addTrainer = `trainer/addTrainer`;
	public static updateTrainer = `trainer/updateTrainer`;
	public static getTrainerWithSkills = `trainer/getTrainersWithSkills`;

	//batches api
	public static batches = `${this.admin}/batches`;
	public static addBatch = `${this.batches}/addBatch`;
	public static getAllBatches = `${this.batches}/getAllBatches`;
	public static particularBatchDetails = `${this.batches}/getCandidatesByBatchId?batchId=`;
	public static updateBatchStatus = `${this.batches}/updateBatchStatus`;
	public static getTrainingsByBatchId = `${this.batches}/getTrainingByBatchId?batchId=`;
	public static getBatchDetails = `${this.batches}/getBatchDetails`;
	public static updateBatch = `${this.batches}/updateBatch`;

	//admin-candidates api
	public static adminCandidates = `${this.admin}/candidates`
	public static getAllCandidates = `${this.adminCandidates}/getAllCandidates`;
	public static getGraphData = `${this.adminCandidates}/getGraphData`;
	public static getCandidateDashboard = `${this.adminCandidates}/getCandidateDashboard`;
	public static downloadStudentReport = `${this.adminCandidates}/downloadStudentReport`;
	public static downloadAllCandidatesReport = `${this.adminCandidates}/downloadAllCandidatesReport`;
	public static updateCandidateStatus = `${this.adminCandidates}/updateCandidateStatus`;
	public static getCandidateDashboardForMentor = `${this.adminCandidates}/getCandidateDashboardForMentor`;
	public static getGraphDataForMentor = `${this.adminCandidates}/getGraphDataForMentor`;
	public static getGraphDataForPip = `${this.adminCandidates}/getGraphDataForPip`;
	public static unMarkPIP = `${this.adminCandidates}/unMarkPIP`;
	public static getPipDashboardForMentor = `${this.adminCandidates}/getCandidateDashboardForPip`;
	public static markForPip = `${this.admin}/candidates/markPip`;
	public static updateForPip = `${this.admin}/candidates/updatePipDetails`;
	public static getCandidateDashboardForInterview = `${this.admin}/candidates/getCandidateDashboardForInterview`;
	

	//trainer api
	public static trainer = `trainer`;
	public static getAllCandidatesForTrainer = `${this.trainer}/getAllCandidatesForTrainer`;
	public static getAllTrainingsForFeedback = `${this.trainer}/getAllTrainingsForFeedback`;
	public static getCandidateOverview = `${this.trainer}/getCandidateOverview`;
	public static getGraphDataForTrainerDashboard = `${this.trainer}/getGraphDataForTrainerDashboard`;
	public static searchCandidate = `${this.trainer}/searchCandidate`;
	//trainer feedback
	public static trainerFeedback = `${this.trainer}/feedback`;
	public static addFeedback = `${this.trainerFeedback}/addFeedback`;
	public static updateFeedback = `${this.trainerFeedback}/updateFeedback`;

	//super-admin api
	public static addAdmin = `${this.superAdmin}/addAdmin/`;
	public static getAdmins = `${this.superAdmin}/getAdmins`;
	public static updateAdminStatus = `${this.superAdmin}/updateAdminStatus`;

	//superAdmin Mentors api
	public static getMentors = `${this.mentor}/getAllMentors`;
	public static createMentor = `${this.mentor}/addMentor`;
	public static updateMentor = `${this.mentor}/updateMentor`;
	public static updateMentorStatus = `${this.mentor}/updateMentorStatus`;
	public static getMentorsByTrack = `${this.mentor}/getMentorsByTrack`

	//Mentor flow
	public static addMentorFeedback = `${this.mentor}/addMentorFeedback`;
	public static updateMentorFeedback = `${this.mentor}/updateMentorFeedback`;
	public static getMentorDashboard = `${this.mentor}/getMentorDashboard`;
	public static getGraphDataForMentorDashboard = `${this.mentor}/getGraphDataForMentorDashboard`;
	public static getCandidateData = `${this.mentor}/getCandidateOverviewForMentor`;

	//PIP
	public static markPip = `${this.adminCandidates}/markPip`
	public static getMentorListForPip = `${this.mentor}/getMentorListForPIP`;
	public static updatePipDetails = `${this.adminCandidates}/updatePipDetails`;
	public static getMentorsForPip = `${this.mentor}/getMentorListForPIP`;

	//WebSockets


	
	public static webSocketEndPoint=`oneview-websocket/`

	

	//Interview flow
	public static getInterviewerDashboard = `${this.interviewer}/getInterviewerDashboard`;
	public static getCandidateHeaderData = `${this.interviewer}/getCandidateHeaderData`;
	public static addInterviewerFeedback=`${this.interviewer}/addInterviewerFeedback`;
	public static updateInterviewerFeedback=`${this.interviewer}/updateInterviewerFeedback`;
	public static getCandidateOverviewForInterviewer=`${this.interviewer}/getCandidateOverviewForInterviewer`;
	public static getInterviewers = `${this.interviewer}/getAllInterviewers`;
	public static updateInterviewersStatus = `${this.interviewer}/updateInterviewerStatus`;
	public static addInterviewer = `${this.interviewer}/addUpdateInterviewer`;
	public static getInterviewDashboardForInterview=`${this.interviewer}/getCandidateOverviewForInterviewer`;


	//Notifications
	public static markAllRead = `${this.notifications}/markAllAsRead`;
	public static getAllNotifications = 'notifications/getAllNotifications';

	
	
	//Interviewer
	public static schedulingInterview = `${this.interviewer}/scheduleInterview`;
	public static reschedulingInterview = `${this.interviewer}/updateInterviewDetails`;
	public static deleteInterview = `${this.interviewer}/deleteInterviewDetails`;

	//status history
	public static statusHistoy = `${this.adminCandidates}/getStatusHistory`

	//admin-category
	public static addCategory = `${this.adminProgramCategory}/addProgramCategory`
	public static updateCategory = `${this.adminProgramCategory}/updateProgramCategory`
	public static updateCategoryStatus = `${this.adminProgramCategory}/updateProgramCategoryStatus`
}
