import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'app-avatar-list',
	standalone: true,
	imports: [CommonModule,
		MatIconModule],
	templateUrl: './avatar-list.component.html',
	styleUrls: ['./avatar-list.component.scss']
})
export class AvatarListComponent implements OnInit {

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	constructor() { }

	count = '';
	extraCount = 0;
	@Input() avatarList: Array<any> = [];
	list: Array<any> = [];
	@Input() n = 0;
	@Input() userType = '';


	ngOnInit(): void {
		this.avatarList?.map(item => {
			if (!("imgSrc" in item)) {
				item.replacementText = item.firstName[0];
			}
		})

		if (this.avatarList?.length === 5) {
			this.extraCount = 0;
		}
		if (this.userType === 'trainer') {
			this.n = 2;
			this.extraCount = this.avatarList?.length - 2;
			this.count = `+${this.extraCount}`;
			if (this.avatarList?.length < 2) {
				this.extraCount = 0;
			}
		}
		else if (this.userType === 'candidate') {
			this.n = 5;
			this.extraCount = this.avatarList?.length - 5;
			this.count = `+${this.extraCount}`;
			if (this.avatarList?.length <= 5) {
				this.extraCount = 0;
			}
		} 
		else if(this.userType==='student'){
			this.n=5;
			this.extraCount = this.avatarList?.length - 5;
			this.count = `+${this.extraCount}`;
			if (this.avatarList?.length <= 5) {
				this.extraCount = 0;
			}
		}
	}
}
