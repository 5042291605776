<div class="images-list ">
	<div *ngFor="let avatar of avatarList | slice:0:n">
		<div *ngIf="avatar.imgSrc; else noImgSrcMedium">
			<img src={{avatar.imgSrc}} class="default medium avatar" />
		</div>
		<ng-template #noImgSrcMedium>
			<div class="no-image-medium avatar">
				<p>{{avatar.replacementText}}</p>
			</div>
		</ng-template>
	</div>
	<div>
		<div class="no-image-medium avatar count-avatar" *ngIf="extraCount!==0">
			<p>{{ count }}</p>
		</div>
	</div>
</div>