import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { pagination } from 'src/app/core/interfaces';
import { ServicesUtilityService } from './../../core/services/utility/services-utility.service';
import { EventService } from 'src/app/core/services/event/event.service';

const materialModules = [MatIconModule, MatDividerModule, MatButtonModule];
@Component({
	selector: 'app-pagination',
	standalone: true,
	imports: [CommonModule, ...materialModules],
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
	@Input() count!: number;
	@Input() pageSize = 10;
	@Input() maxPages = 10;
	@Input() typeOfData='';

	currentPageNumber = 0;
	startPageNumber = 0;
	lastPageNumber = 0;
	midPage!: number;
	paginationDetails: pagination = {};
	@Output() pageDetails = new EventEmitter<any>();

	constructor(private utility: ServicesUtilityService, private eventService : EventService) {

	}
	ngOnInit(): void {
		this.lastPageNumber = Math.ceil(this.count / this.pageSize) - 1;
		this.currentPageNumber = this.startPageNumber;
		this.midPage = Math.floor(this.maxPages / 2);
		if (this.count <= 40) {
			this.maxPages = Math.ceil(this.count / this.pageSize);
		}

		this.eventService.setPaginationCount.subscribe({
			next : (count : any)=>{
				this.count = count;
			}
		})
	}
	ngOnChanges() {
		this.utility.totalCount.subscribe((res: any) => {
			if(this.typeOfData!=='Attendee') {
				this.count = res;
			}
				this.lastPageNumber = Math.ceil(this.count / this.pageSize) - 1;
			this.startPageNumber = 0;
			this.midPage = Math.floor(this.maxPages / 2);
			if (this.count <= 40) {
				this.maxPages = Math.ceil(this.count / this.pageSize);
			} else {
				this.maxPages = 5;
			}
		})
	}

	updateCurrentPageNumber(pageNumber: number) {
		if (pageNumber <= this.lastPageNumber) {
			this.currentPageNumber = pageNumber;
		}
		this.paginationDetails.pageNumber = this.currentPageNumber;
		this.paginationDetails.pageSize = this.pageSize;
		this.pageDetails.emit(this.paginationDetails);
	}

	previousPage() {
		if (this.currentPageNumber > this.startPageNumber) {
			this.currentPageNumber--;
		}
		this.paginationDetails.pageNumber = this.currentPageNumber;
		this.paginationDetails.pageSize = this.pageSize;
		this.pageDetails.emit(this.paginationDetails);
	}

	nextPage() {
		if (this.currentPageNumber < this.lastPageNumber) {
			this.currentPageNumber+=1;
		}
		this.paginationDetails.pageNumber = this.currentPageNumber;
		this.paginationDetails.pageSize = this.pageSize;
		this.pageDetails.emit(this.paginationDetails);
	}

	isMaxPagesExceeded() {
		return this.lastPageNumber - this.currentPageNumber > this.maxPages - 1;
	}

	getPageNumberForIndex(i: number, hasMid = true) {
		if (hasMid) {
			return i < this.midPage
				? this.currentPageNumber + i
				: this.lastPageNumber - (this.maxPages - i - 1);
		}
		return this.lastPageNumber + 1 - this.maxPages + i;
	}
}
