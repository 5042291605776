<mat-drawer-container autosize>
	<mat-drawer [ngClass]="{'selected': select}" mode="side" [opened]="true" id="menu-bar">
		<div class="company">
			<div class="logo">
				<mat-icon svgIcon="oneView-sidebar-logo"></mat-icon>
			</div>
			<mat-divider></mat-divider>
		</div>
		<div class="nav">
			<mat-nav-list>
				<mat-list-item *ngFor="let item of navListItems" [routerLink]="item.path" routerLinkActive="active"
					(isActiveChange)="changeActiveIcon($event, item)">
					<mat-icon mat-list-icon [svgIcon]="item.icon"></mat-icon>
					<div mat-line>
						{{ item.label }}
					</div>
				</mat-list-item>
			</mat-nav-list>
		</div>
		<span class="spacer"></span>
		<mat-list-item ngClass="notification-container" (click)="notificationToggled()">
			<button mat-flat-button class="notification-button">
				<div class="notification-button-wrapper">
					<div class="notification-logo">
						<mat-icon svgIcon="notifications"></mat-icon>
						<span class="notification" *ngIf="unreadNotificationCount>0">Notifications
							<span class="unread-notification-count"> {{unreadNotificationCount}} New
							</span>
					</span>
						<span class="notification" *ngIf="unreadNotificationCount===0">Notifications</span>
					</div>
				</div>
			</button>
		</mat-list-item>
		<div class="user">
			
			<div class="profile">
				<!-- <img
					class="profile-image"
					src={{userDetails.imageUrl}}
					alt=""
				/> -->
				
				<div *ngIf="userDetails?.imageUrl!==null; else noImgSrcLarge">
					<img [src]="userDetails?.imageUrl" class="default large" />
				</div>
				<ng-template #noImgSrcLarge>
					<div class="no-image-large">
						<p>{{ replacementText }}</p>
					</div>
				</ng-template>
				
				<div class="profile-details">
					
					<div class="name">{{userDetails.firstName}} {{userDetails.lastName}}</div>
					<div class="logout action-btn">
						<mat-icon svgIcon="logout" (click)="logout()"></mat-icon>
					</div>
					<div class="role-container">
						<div class="role">{{getRole(userRole)}}  &#x2022;</div>
						<div ngClass="switch-role" *ngIf="rolesLength" #switchRoleOption>
							<button mat-flat-button disableRipple class="tertiary sm"
								(click)="wantsToSwitch=!wantsToSwitch">
								Switch Role
								<mat-icon svgIcon="switch-role"></mat-icon>
							</button>
						</div>
						<div ngClass="switch-role" *ngIf="alternateRole">
							<button mat-flat-button disableRipple class="tertiary sm"
								(click)="switchRole(alternateRole)">
								Switch Role
							</button>
						</div>
					</div>
				</div>
				
			</div>
			<mat-divider></mat-divider>
			<ng-container *ngIf="wantsToSwitch && rolesLength">
				<div class="roles-container" #switchRoleContainer>
					<button *ngFor="let role of roles" mat-flat-button (click)="switchRole(role)">Switch to
						{{getRole(role)}}</button>
				</div>
			</ng-container>
		</div>
	</mat-drawer>

	<mat-drawer-content>
		<div class="sidenav-content mat-drawer-content">
			<div class="body">
				<ng-content></ng-content>
			</div>
		</div>
	</mat-drawer-content>
</mat-drawer-container>