import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'app-user-profile',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnChanges {

	replacementText = '';
	role='Track Lead';
	@Input() userDetails: any;
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	constructor() { }

	ngOnChanges(): void {
		this.replacementText=`${this.userDetails.firstName[0]}${this.userDetails.lastName[0]}`;
		// this.role=this.userDetails.role;
	}


}
