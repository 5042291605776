<header mat-dialog-header class="prompt-dialog-header">
    <mat-icon svgIcon="reminder" class="reminder-icon"></mat-icon>
    <h4>Instructions!</h4>
</header>

<div class="prompt-dialog-subtext">

    <div class="caption-1">
        <p>‘Please evaluate the candidate basis the parameters shared by LND to you and </p>
        <p>provide your detailed comments in the overall feedback box'</p>
    </div>

</div>

<div class="mat-dialog-actions">
    <button mat-flat-button color="primary" disableRipple class="sm action-buttons" (click)="onCloseClick()">
        Continue to Feedback
    </button>
</div>