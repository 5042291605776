<mat-card class="score-card disable-hover" *ngIf="(currentRole!=='mentor' && currentRole!=='trainer') &&  (!interviewerFeedback && role !== 'interviewer')">
    <div class="card-container">
        <mat-card-header class="score-card-header">
                <mat-card-title *ngIf="feedbackType">
                    {{scoreDetails?.trainingName}}     
                </mat-card-title> 
        </mat-card-header>
        <div class="sub-card-container">
            <div class="sub-card">
                <mat-card-subtitle class="subcard-subtite">Score</mat-card-subtitle>
                <app-score-chip [score]="scoreDetails?.totalAverage"></app-score-chip>
            </div>
            <div class="sub-card">
                <mat-card-subtitle class="subcard-subtite">Duration</mat-card-subtitle>
                <span class="caption-3 md">{{scoreDetails?.startDate | date:'MMM dd'}} - {{scoreDetails?.endDate |
                    date:'MMM dd'}}</span>
                    
            </div>
            
        </div>
        <main class="score-content">
            <div *ngFor="let element of scoreDetails?.category; index as i">
                <div class="score-category caption-5">

                    <span class="key-label">{{element.key | titlecase}}</span>
                    <span class="value-label">{{element.value}}</span>
                </div>
                <mat-divider *ngIf="i !== scoreDetails?.category.length - 1"></mat-divider>
            </div>
        </main>
    </div>
    <mat-divider></mat-divider>
    <div class="card-footer primary-caption md">
        <div class="card-footer primary-caption md" (click)="showFeedbackDialog()">
            <span>View Feedback</span>
            <mat-icon svgIcon="view-feedback" class="feedback-icon"></mat-icon>
        </div>
        
        <div class="card-footer primary-caption md"*ngIf="editFeedback">
            <!-- <mat-icon svgIcon="edit-feedback" class="edit-feedback-icon action-btn" matTooltip="Edit"
                matTooltipPosition="below" matTooltipHideDelay="3000" (click)=""></mat-icon> -->
            <button mat-icon-button class="mat-button-wrapper"  (click)="openEditFeedbackDialog()">
                <span>Edit</span><mat-icon [svgIcon]="'edit-feedback'"></mat-icon>
                
            </button>
            <!-- <button mat-icon-button color="primary" class="mat-button-wrapper" (click)="cardClick(track,button.type)" matTooltip="{{button.text}}"
            matTooltipPosition="below">
            <mat-icon [svgIcon]="button.type"></mat-icon> -->
            <!-- </button> -->
        </div>
    </div>
   
</mat-card>


<mat-card class="score-card disable-hover" *ngIf="(currentRole==='mentor' || currentRole==='trainer') && (!interviewerFeedback && role !== 'interviewer')">
    <div class="card-container">
        <mat-card-header class="score-card-header">
                <mat-card-title *ngIf="feedbackType">
                    <p *ngIf="!(scoreDetails?.trainingName.length>12)" class="title">
                        {{scoreDetails?.trainingName}}
                    </p>
                    <p *ngIf="scoreDetails?.trainingName.length>12" class="title"
                        matTooltip=" {{scoreDetails?.trainingName }}" matTooltipPosition="below">
                        {{scoreDetails?.trainingName }}
                    </p>
                </mat-card-title>
             
            <!-- <div *ngIf="editFeedback"> -->
                <!-- <mat-icon svgIcon="edit-feedback" class="edit-feedback-icon action-btn" matTooltip="Edit"
                    matTooltipPosition="below" matTooltipHideDelay="3000" (click)=""></mat-icon> -->
                <!-- <button mat-icon-button class="mat-button-wrapper" color="primary" matTooltip="Edit"
                    matTooltipPosition="below" (click)="openEditFeedbackDialog()">
                    <mat-icon [svgIcon]="'edit-feedback'"></mat-icon>
                </button> -->
                <!-- <button mat-icon-button color="primary" class="mat-button-wrapper" (click)="cardClick(track,button.type)" matTooltip="{{button.text}}"
                matTooltipPosition="below">
                <mat-icon [svgIcon]="button.type"></mat-icon> -->
                <!-- </button> -->
            <!-- </div> -->
        </mat-card-header>
        <div class="sub-card-container">
            <div class="sub-card">
                <mat-card-subtitle class="subcard-subtite">Score</mat-card-subtitle>
                <app-score-chip [score]="scoreDetails?.totalAverage"></app-score-chip>
            </div>
            <div class="sub-card">
                <mat-card-subtitle class="subcard-subtite">Duration</mat-card-subtitle>
                <span class="caption-3 md">{{scoreDetails?.startDate | date:'MMM dd'}} - {{scoreDetails?.endDate |
                    date:'MMM dd'}}</span>
            </div>
        </div>
        <main class="score-content">
            <div *ngFor="let element of scoreDetails?.category">
                <div class="score-category caption-5">
                    <span class="key-label">{{element.key | titlecase}}</span>
                    <span class="value-label">{{element.value}}</span>
                </div>
                <mat-divider></mat-divider>
            </div>
        </main>
    </div>
    <mat-divider></mat-divider>
    <div class="card-footer-mentor primary-caption md">
        <div class="view-feedback" (click)="showFeedbackDialog()">
            <span>View Feedback</span>
            <mat-icon svgIcon="view-feedback" class="feedback-icon"></mat-icon>
        </div>
        <div class="edit-feedback">
            <button mat-flat-button disableRipple class="tertiary sm" (click)="openEditFeedbackDialog()">
                Edit
                <mat-icon [svgIcon]="'edit-feedback'"></mat-icon>
            </button>
        </div>
    </div>
</mat-card>

<div *ngIf="role ==='interviewer'">
    <div *ngIf="emptyFeedbackDetails; else otherFeedback" class="interview-card">
            <mat-card class="score-card disable-hover">
                <div class="card-container" >
                    <mat-card-header class="score-card-header">
                            <mat-card-title>
                                <p  class="empty-state-text">
                                    Interview Level - {{emptyFeedbackDetails?.interviewLevel}}
                                </p>
                            </mat-card-title>
                    </mat-card-header>
                    <div class="sub-card-container">
                        <div class="sub-card">
                            <mat-card-subtitle class="subcard-subtite">Score</mat-card-subtitle>
                            <app-score-chip [score]="emptyFeedbackDetails.feedbackDto?.totalAverage ? emptyFeedbackDetails.feedbackDto?.totalAverage:0"></app-score-chip>
                        </div>
                        <div class="sub-card">
                            <mat-card-subtitle class="subcard-subtite">Interview Date</mat-card-subtitle>
                            <span class="caption-3 md">{{emptyFeedbackDetails?.interviewDate | date: 'mediumDate'}}</span>
                        </div>
                    </div>
                    <main class="score-content">
                        <div  class="score-content-empty-state ">
                            <div class="score-empty-state interview-feedback-empty-state">
                                <mat-icon svgIcon="score-feedback-empty-state"></mat-icon>
                                <p class="empty-state-text">Feedback not added yet</p>
                            </div>
                            <button mat-flat-button color="primary" disableRipple class="sm" (click)="showDialog('add')" class="interview-add-icon">
                                <mat-icon svgIcon="add" class="add-icon"></mat-icon>
                                <span>Add Feedback</span>
                            </button>
                        </div>
                    </main>
                </div>
            </mat-card>
    </div>
    <ng-template  #otherFeedback>
            <mat-card class="score-card disable-hover">
                <div class="card-container" >
                    <mat-card-header class="score-card-header">
                            <mat-card-title *ngIf="interviewFeedbackType">
                                <p  class="empty-state-text">
                                    Interview Level - {{feedbackDetails?.interviewLevel}}
                                </p>
                            </mat-card-title>
                    </mat-card-header>
                    <div class="sub-card-container">
                        <div class="sub-card">
                            <mat-card-subtitle class="subcard-subtite">Score</mat-card-subtitle>
                            <app-score-chip [score]="feedbackDetails?.feedbackDto.totalAverage"></app-score-chip>
                        </div>
                        <div class="sub-card">
                            <mat-card-subtitle class="subcard-subtite">Interview Date</mat-card-subtitle>
                            <span class="caption-3 md">{{feedbackDetails?.interviewDate | date: 'mediumDate'}}</span>
                        </div>
                    </div>
                    <main class="score-content">
                        <div *ngFor="let key of interviewFeedbackKeys">
                            <div>
                                <div class="score-category caption-5">
                                    <span class="key-label">{{key | titlecase}}</span>
                                    <span class="value-label">{{feedbackDetails?.feedbackDto[key]}}</span>
                                </div>
                                <mat-divider *ngIf="key !== interviewFeedbackKeys[interviewFeedbackKeys.length-1]"></mat-divider>
                            </div>
                        </div>
                    </main>
                </div>
                <mat-divider class="interview-divider"></mat-divider>
                    <div class="card-footer-mentor primary-caption md">
                        <div class="view-feedback" (click)="showFeedbackDialog()">
                            <span>View Feedback</span>
                            <mat-icon svgIcon="view-feedback" class="feedback-icon"></mat-icon>
                        </div>
                        <div class="edit-feedback">
                            <button mat-flat-button disableRipple class="tertiary sm" (click)="showDialog('edit')">
                                Edit
                                <mat-icon [svgIcon]="'edit-feedback'"></mat-icon>
                            </button>
                        </div>
                    </div>
            </mat-card>
    </ng-template>
</div>
<mat-card class="score-card disable-hover" *ngIf="interviewerFeedback && role !=='interviewer'">
    <div class="card-container" >
        <mat-card-header class="score-card-header">
                <mat-card-title>
                    <p class="empty-state-text">
                        Interview Level - {{scoreDetails?.interviewLevel}}
                    </p>
                </mat-card-title>
        </mat-card-header>
        <div class="sub-card-container">
            <div class="sub-card">
                <mat-card-subtitle class="subcard-subtite">Score</mat-card-subtitle>
                <app-score-chip [score]="scoreDetails?.feedbackDto.totalAverage"></app-score-chip>
            </div>
            <div class="sub-card">
                <mat-card-subtitle class="subcard-subtite">Interview Date</mat-card-subtitle>
                <span class="caption-3 md">{{scoreDetails?.interviewDate | date: 'mediumDate'}}</span>
            </div>
        </div>
        <main class="score-content">
            <div *ngFor="let key of interviewFeedbackKeys">
                <div>
                    <div class="score-category caption-5">
                        <span class="key-label">{{key | titlecase}}</span>
                        <span class="value-label">{{scoreDetails?.feedbackDto[key]}}</span>
                    </div>
                    <mat-divider *ngIf="key !== interviewFeedbackKeys[interviewFeedbackKeys.length-1]"></mat-divider>
                </div>
            </div>
        </main>
    </div>
    <mat-divider></mat-divider>
    <div class="card-footer primary-caption md">
        <div class="card-footer primary-caption md" (click)="showFeedbackDialog()">
            <span>View Feedback</span>
            <mat-icon svgIcon="view-feedback" class="feedback-icon"></mat-icon>
        </div>
        
        <div class="card-footer primary-caption md" *ngIf="editFeedback">
            
            <button mat-icon-button class="mat-button-wrapper"  (click)="openEditFeedbackDialog()">
                <span>Edit</span><mat-icon [svgIcon]="'edit-feedback'"></mat-icon>
                
            </button>
            
        </div>
    </div>
    
</mat-card>
