import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  selector: 'app-ellipsis-chip',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatTooltipModule],
  templateUrl: './ellipsis-chip.component.html',
  styleUrls: ['./ellipsis-chip.component.scss']
})
export class EllipsisChipComponent implements OnInit {

  @Output()
  trainingCountClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() userData: any;
  trainingData:any = false;

  showData(data: any) {
    this.trainingCountClick.emit(data);
  }

  ngOnInit(): void | undefined {
    if(this.userData?.trainingNames){
      this.trainingData = true;
    }
  }
}
