import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogService } from 'src/app/core';

const materialModules = [MatButtonModule, MatIconModule, MatDialogModule];

@Component({
  selector: 'app-parameter-prompt',
  standalone: true,
  imports: [CommonModule, ...materialModules],
  templateUrl: './parameter-prompt.component.html',
  styleUrls: ['./parameter-prompt.component.scss']
})
export class ParameterPromptComponent implements OnInit {

  constructor(private dialogService: DialogService, public dialogRef: MatDialogRef<ParameterPromptComponent>) { }

  ngOnInit(): void {
  }

  onCancelClick() {
		this.dialogRef.close('cancel');
	}

  onCloseClick() {
		this.dialogRef.close('close');
	}

}
