import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { STATUS } from 'src/app/core/structs/status';

interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-drop-down',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnChanges {

  @Output() changedStatus = new EventEmitter();
  @Input() userData: any;
  @Input() managerData:any;
  data: any;
  candidateStatus = '';
  candidateId = '';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  ngOnChanges(): void {
    this.data = this.userData;
    this.candidateId = this.userData.userId;
    if (this.userData.candidateStatus.toUpperCase() === STATUS.IN_TRAINING || this.userData.candidateStatus === null) {
      this.candidateStatus = 'In Training';
    }
    else if (this.userData.candidateStatus.toUpperCase() === STATUS.RESIGNED) {
      this.candidateStatus = 'Resigned';
    }
    else if (this.userData.candidateStatus.toUpperCase() === STATUS.CONFIRMED || this.userData.candidateStatus.toUpperCase() === STATUS.CONFIRM) {
      this.candidateStatus = 'Confirmed';
    }
    else if (this.userData.candidateStatus.toUpperCase() === STATUS.PROCESS_EXIT) {
      this.candidateStatus = 'Process Exit';
    }

    this.statusControl.patchValue(this.candidateStatus);

    if(this.userData.pipStatus === 1){
      this.status=[
        { value: 'IN_TRAINING', viewValue: 'In Training' },
        { value: 'RESIGNED', viewValue: 'Resigned' },
        { value: 'PROCESS_EXIT', viewValue: 'Process Exit' },
      ];
    } else {
      this.status=[
        { value: 'IN_TRAINING', viewValue: 'In Training' },
        { value: 'RESIGNED', viewValue: 'Resigned' },
        { value: 'PROCESS_EXIT', viewValue: 'Process Exit' },
        { value: 'CONFIRMED', viewValue: 'Confirmed' }
      ];
    }
  }

  status: Status[] = [
    { value: 'IN_TRAINING', viewValue: 'In Training' },
    { value: 'RESIGNED', viewValue: 'Resigned' },
    { value: 'PROCESS_EXIT', viewValue: 'Process Exit' },
    { value: 'CONFIRMED', viewValue: 'Confirmed' }
  ];

  statusControl = new FormControl('');
  form = new FormGroup({
    statusData: this.statusControl,
  });

  value = '';
  viewValue = '';
  
  clickedStatus(value: any, viewValue: any) {
    this.value = value;
    this.viewValue = viewValue;
    const candidateStatus = {
      value: this.value,
      viewValue: this.viewValue,
      previousStatus: this.candidateStatus,
      userId: this.candidateId,
      batchId: this.userData.batchId,
    }
    this.changedStatus.emit(candidateStatus);
  }
}
