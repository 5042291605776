<div class="slider-container" [ngClass]="{'padding' : error}">
    <mat-slider
        [max]="max"
        [min]="min"        
        [(ngModel)]="value"
        (ngModelChange)="valueChanged()"
        aria-labelledby="slider-value" >
    </mat-slider>
    <h6 class="slider-value" *ngIf="value<10"> 0{{value}}</h6>
    <h6 class="slider-value" *ngIf="value===10"> {{value}}</h6>
    <ng-container *ngIf="error && role!=='trainer'">
        <mat-error  ngClass="validation-msg">Score should be more than 0</mat-error>
    </ng-container>
</div>

