export enum ROLES {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_TRAINER = 'ROLE_TRAINER',
    ROLE_CANDIDATE = 'ROLE_CANDIDATE',
    ROLE_SUPER_ADMIN='ROLE_SUPER_ADMIN',
    ROLE_MENTOR='ROLE_MENTOR'

}

export const ROLE_ROUTES: { [key: string]: string } = {
    admin: ROLES.ROLE_ADMIN,
    trainer: ROLES.ROLE_TRAINER,
    candidate: ROLES.ROLE_CANDIDATE,
    superAdmin:ROLES.ROLE_SUPER_ADMIN,
    mentor:ROLES.ROLE_MENTOR,

};
