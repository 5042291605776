import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core';

@Injectable({
	providedIn: 'root'
})
export class ResetGuardService implements CanActivate {

	constructor(
		private authService: AuthService,
		private router: Router
	) { }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): | boolean | UrlTree | Observable<boolean | UrlTree>
	| Promise<boolean | UrlTree> {
		const url = route.url[0].path;
		if (url === 'reset-password') {
			const details = this.authService.getUserDetails();
			if (details != undefined) {
				if (details.firstTimeLogin === undefined) {
					this.router.navigate(['auth']);
					return false;
				}
				else if (details.firstTimeLogin === 2) {
					return true;
				}
			} else {
				this.router.navigate(['auth']);
				return false;
			}
		}
		else {
			return true;
		}
		return true;

	}
}
