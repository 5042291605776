<div class="outside-container">
	<mat-icon svgIcon="comment-box-top" class="comment-box-top"></mat-icon>
	<div class="feedback-comment-container">
		<div class="comment-container-header" *ngIf="parameter<6">
			<p class="feedback-title">Add a feedback</p>
			<p class="caption-2">{{feedbackLength}}/200</p>
		</div>
		<textarea *ngIf="!enableEdit" [placeholder]="placeholderText" class="comment-textarea" disabled></textarea>
		<textarea *ngIf="enableEdit"
			[placeholder]="feedbackComment.length>0 ? feedbackComment :'Enter a description...'"
			[formControl]="commentValue" class="comment-textarea" maxlength="200"
			[value]="feedbackComment"></textarea>
		<div class="action-btn-container">
			<div *ngIf="!enableEdit && mode==='edit'">
				<button mat-flat-button disableRipple color="primary" class="tertiary sm" (click)="onEditClick()">
					Edit
				</button>
			</div>
			<div *ngIf="enableEdit" class="action-btn-container">
				<button mat-flat-button disableRipple class=" sm comment-cancel-btn" (click)="onCancelClick()">
					Cancel
				</button>
				<button mat-flat-button disableRipple color="primary" class="tertiary sm" (click)="onSaveClick()">
					Save
				</button>
			</div>
		</div>
	</div>
</div>