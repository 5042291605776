import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FormGroup } from '@angular/forms';

export interface ICandidateTree {
  candidateDetails : {
    candidateId : number,
    candidateName : string,
    batchId : number
  },
  Dates : {
    date : string,
    values : {
      attendance : {
        from : null | boolean,
        to : null | boolean
      },
      assignment : {
        from : null | boolean,
        to : null | boolean
      }
    },
    formGroup : FormGroup,
    initialValue : {
      attendance : null | boolean,
      assignment : null | boolean
    },
    recordId : number | null

  }[ ]
}

export interface ITreeData {
  name: string;
  children?: ITreeData[];
}

@Component({
  selector: 'app-tree',
  standalone: true,
  imports: [CommonModule, MatTreeModule, MatButtonModule, MatIconModule],
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit, OnChanges {
  @Input() treeData!: ICandidateTree[];
  hasChild = (_: number, node: ITreeData) => !!node.children && node.children.length > 0;
  treeControl = new NestedTreeControl<ITreeData>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ITreeData>();

  constructor() {}

  ngOnInit(): void {
    try {
      this.prepareDataSource();
    } catch(e) {
      alert(e)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareDataSource();
  }

  prepareDataSource() {
    this.dataSource.data = this.treeData.map((tree: ICandidateTree) => {
      const node: ITreeData = {
        name: tree.candidateDetails.candidateName,
        children: tree.Dates.map(date => {
          const attendanceNode: ITreeData = {
            name: 'Attendance',
            children: [
              {
                name: `From ${date.values.attendance.from} to ${date.values.attendance.to}`
              }
            ]
          };

          const assignmentNode: ITreeData = {
            name: 'Assignment',
            children: [
              {
                name: `From ${date.values.assignment.from} to ${date.values.assignment.to}`
              }
            ]
          };

          return {
            name: date.date,
            children: [attendanceNode, assignmentNode]
          };
        })
      };

      return node;
    });
  }
}



