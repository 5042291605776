<div class="message-container">
    <img src="../../../../../assets/icons/success-check-icon.svg">
    <div class="reset-message" *ngIf="condition==='reset'">
        <h1>Password updated!</h1>
        <p>Use this password to sign in to your account.</p>

    </div>
    <div class="forgot-message" *ngIf="condition==='forgot'">
        <div>
            <h1>Temporary password</h1>
            <h1>sent!</h1>
        </div>
        <div>
            <p>Check your inbox! We've sent an email to</p>
            <p>{{forgotEmail}} with a temporary</p>
            <p>password.</p>
        </div>
    </div>

    <button *ngIf="condition==='reset'" mat-flat-button color="primary" disableRipple class="sign-in-btn"
        (click)="signInClicked()">
        Sign In Again
    </button>

</div>