<div class="ellipsis-chip-container" *ngIf="trainingData">
    <div class="ellipsis-chip-wrapper">
        <span class="ellipsis-chip-training">{{userData?.trainingNames}}</span>
    </div>
    <div *ngIf="userData?.count !== 1">
        <mat-chip class="ellipsis-chip training-names" (click)="showData(userData.trainerData)" *ngIf="userData?.trainingNames.length!==0">
            +{{userData?.count - 1}}
        </mat-chip>
    </div>
</div>

<div *ngIf="!trainingData" class="ellipsis-chip-container">
    <div class="ellipsis-chip-wrapper">
        <span class="ellipsis-chip-training">{{userData?.trackNames}}</span>
    </div>
    <div *ngIf="userData?.count !== 1">
        <mat-chip #tooltip="matTooltip" matTooltip="{{userData?.trackNames.join('\n')}}" class="ellipsis-chip mentor-trainings-chip" matTooltipClass="custom-tooltip" matTooltipPosition="right" (click)="showData(userData.trackData)" *ngIf="userData?.trackNames.length!==0">
            +{{userData?.count - 1}}
        </mat-chip>
    </div>

</div>
