<mat-drawer-container>
    <mat-drawer mode="side" [opened]="changesArray.length === 0 ? false : false" #drawer>
        <!-- <app-tree [treeData]="changesArray"></app-tree> -->
    </mat-drawer>
    <mat-drawer-content>

        <section class="calendar">
            <div class="search-bar">
                <div class="actions-container">
                    <button mat-flat-button disableRipple class="secondary sm" [ngStyle]="{'visibility' : changesArray.length ? 'visible' : 'hidden'}" (click)="onResetClick()">
                        Reset
                    </button>
                    <button mat-flat-button disableRipple class="secondary sm" [ngStyle]="{'visibility' : changesArray.length ? 'visible' : 'hidden'}" (click)="onSubmitClick()">
                        Submit
                    </button>
                </div>
                <!-- <div class="search"> -->
                    <!-- <mat-form-field>
                        <input matInput type="text" placeholder="Search">
                    </mat-form-field> -->
                    <!-- <div class="search-menu">
                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-icon matPrefix svgIcon="search"></mat-icon>
                            <input type="text" matInput [formControl]="searchField" placeholder="Search" />
                        </mat-form-field>
                    </div> -->
                <!-- </div> -->
            </div>
            <div class="calendar-header">
                <h3>{{days[0] | date : 'MMM dd, YYYY'}} - {{days[6] | date : 'MMM dd, YYYY'}}</h3>

                <div class="date-picker">

                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" [formControl]="datePicker"  placeholder="Select date">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <button mat-flat-button color="primary" [disabled]="toDisableToday()" disableRipple class="lg" (click)="onTodayClicked()">
                        Today
                    </button>
                    <div class="carousal">
                        <mat-icon class="navigation-icon" svgIcon="Graph-Scroll-nav-left"
                        (click)="[decrementWeek()]"></mat-icon>
                        <mat-icon class="navigation-icon" svgIcon="Graph-Scroll-nav-right"
                        (click)="[incrementWeek()]"></mat-icon>
                    </div>
                </div>
            </div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 calendar-table">

                <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="candidates">
                    <th mat-header-cell *matHeaderCellDef> Candidates </th>
                    <td mat-cell *matCellDef="let element"> {{element.candidate.candidateName}} </td>
                </ng-container>

                <!-- Name Column -->
                <!-- <ng-container *ngFor="let day of weekDays">
                    <ng-container [matColumnDef]="day">
                        <th mat-header-cell *matHeaderCellDef>
                            <p class="day">{{day}}</p>
                            <p>{{daysMap[day] | date : 'dd'}}</p>
                        </th>
                        <td mat-cell *matCellDef="let element"> <app-attendance-assignment ></app-attendance-assignment>
                        </td>
                    </ng-container>
                </ng-container> -->
                <ng-container matColumnDef="Mon">
                    <th mat-header-cell *matHeaderCellDef>
                        <p class="day">Mon</p>
                        <p>{{daysMap['Mon'] | date : 'dd'}}</p>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        <!-- {{element.Mon.date | date}} -->
                        <app-attendance-assignment
                            [formGroup]="element.Mon.formGroup" [candidateId]="element.candidate.candidateId" [formGroupInitialValue]="element.Mon.initialValues" [date]="daysMap['Mon']"></app-attendance-assignment>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Tue">
                    <th mat-header-cell *matHeaderCellDef>
                        <p class="day">Tue</p>
                        <p>{{daysMap['Tue'] | date : 'dd'}}</p>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        <!-- {{element.Tue.date | date}} -->
                        <app-attendance-assignment
                            [formGroup]="element.Tue.formGroup" [candidateId]="element.candidate.candidateId" [formGroupInitialValue]="element.Tue.initialValues" [date]="daysMap['Tue']"></app-attendance-assignment>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Wed">
                    <th mat-header-cell *matHeaderCellDef>
                        <p class="day">Wed</p>
                        <p>{{daysMap['Wed'] | date : 'dd'}}</p>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        <!-- {{element.Wed.date | date}} -->
                        <app-attendance-assignment
                            [formGroup]="element.Wed.formGroup" [candidateId]="element.candidate.candidateId" [formGroupInitialValue]="element.Wed.initialValues" [date]="daysMap['Wed']"></app-attendance-assignment>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Thu">
                    <th mat-header-cell *matHeaderCellDef>
                        <p class="day">Thu</p>
                        <p>{{daysMap['Thu'] | date : 'dd'}}</p>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        <!-- {{element.Thu.date | date}} -->
                        <app-attendance-assignment
                            [formGroup]="element.Thu.formGroup" [candidateId]="element.candidate.candidateId" [formGroupInitialValue]="element.Thu.initialValues" [date]="daysMap['Thu']"></app-attendance-assignment>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Fri">
                    <th mat-header-cell *matHeaderCellDef>
                        <p class="day">Fri</p>
                        <p>{{daysMap['Fri'] | date : 'dd'}}</p>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        <!-- {{element.Fri.date | date}} -->
                        <app-attendance-assignment
                            [formGroup]="element.Fri.formGroup" [candidateId]="element.candidate.candidateId" [formGroupInitialValue]="element.Fri.initialValues" [date]="daysMap['Fri']"></app-attendance-assignment>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Sat">
                    <th mat-header-cell *matHeaderCellDef>
                        <p class="day">Sat</p>
                        <p>{{daysMap['Sat'] | date : 'dd'}}</p>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <!-- {{element.Sat.date | date}} -->
                        <app-attendance-assignment
                            [formGroup]="element.Sat.formGroup" [candidateId]="element.candidate.candidateId" [formGroupInitialValue]="element.Sat.initialValues" [date]="daysMap['Sat']"></app-attendance-assignment> </td>
                </ng-container>

                <ng-container matColumnDef="Sun">
                    <th mat-header-cell *matHeaderCellDef>
                        <p class="day">Sun</p>
                        <p>{{daysMap['Sun'] | date : 'dd'}}</p>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <!-- {{element.Sun.date | date}} -->
                        <app-attendance-assignment
                            [formGroup]="element.Sun.formGroup" [candidateId]="element.candidate.candidateId" [formGroupInitialValue]="element.Mon.initialValues" [date]="daysMap['Sun']"></app-attendance-assignment>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="weekDays"></tr>
                <tr mat-row *matRowDef="let row; columns: weekDays;"></tr>
            </table>
            <div class="pagination-container">
                <app-pagination [count]="totalCount" [typeOfData]="'Attendee'" [maxPages]="maxPages" [pageSize]="pageSize" (pageDetails)="handlePagination($event)"></app-pagination>
            </div>
        </section>
    </mat-drawer-content>
</mat-drawer-container>