import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegexContainer, SnackbarService } from 'src/app/core';
import { CustomValidators} from 'src/app/core/validators/custom.validator';
import { passwordMatchValidator } from 'src/app/core/validators/custom-validator.validator';
import { AuthService } from './../../../../core/services/auth/auth.service';
@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	error = "";
	displaySuccessMsg = false;
	showNewPassword = false;
	showConfirmPassword = false;
	passwordValid: any = {
		hasUpperCase: false,
		hasLowerCase: false,
		hasNumeric: false,
		hasSpecialCharacter: false,
		lengthValidity: false,
	};

	constructor(private authService: AuthService,private _snackbar: SnackbarService,) { }

	ngOnInit(): void {
		this.closePopup();
	}
	showPassword = false;
	spaceKeyDown = false;
	validationMessages: any = {
		password: [
			{ type: 'hasAlphaNumeric', label: 'Be alphanumeric.' },
			{ type: 'hasCapitalCase', label: 'Contain an uppercase character.' },
			{ type: 'hasLowerCase', label: 'Contain a lowercase character.' },
			{ type: 'hasSpecialCharacters', label: 'Contain a special character ( Eg: !, @, #, $).' },
			{ type: 'lengthValidity', label: 'Be 8-11 characters long.' },
		]
	};

	resetPasswordGroup = new FormGroup({
		newPassword: new FormControl('',
			Validators.compose([null, Validators.compose([
				Validators.required,
				//  check whether the entered password has a number
				CustomValidators.patternValidator(/[0-9]/, { hasNumber: true }),
				//  check whether the entered password contains alphanumeric characters
				CustomValidators.patternValidator(/(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+/, { hasAlphaNumeric: true }),
				// check whether the entered password has upper case letter
				CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
				// check whether the entered password has lower case letter
				CustomValidators.patternValidator(/[a-z]/, { hasLowerCase: true }),
				//  check whether the entered password has a special character
				CustomValidators.patternValidator(/[!@#$%^&*(),.?":{}|<>]/, { hasSpecialCharacters: true }),
				//  Has a minimum length of 8 characters and maximum length of 11 characters.
				CustomValidators.patternValidator(/^[\s\S]{8,11}$/, { lengthValidity: true }),
			])
			])),
		confirmPassword: new FormControl('', [Validators.required, Validators.pattern(RegexContainer.passwordPattern)])
	},
		{
			validators: passwordMatchValidator
		})

	toggleNewPassword() {
		this.showNewPassword = !this.showNewPassword;
	}
	toggleConfirmPassword() {
		this.showConfirmPassword = !this.showConfirmPassword;
	}



	meterPopup: any;

	openPopup(event: any) {
		this.meterPopup = {
			'display': 'block',
			'position': 'absolute',
		}
	}

	closePopup() {
		this.meterPopup = {
			'display': 'none'
		}
	}

	passwordValidation(inputText: any) {
		const passwordText = inputText.target.value;
		const hasUpperCase = /[A-Z]+/.test(passwordText);
		const hasNumeric = /[0-9]/.test(passwordText);
		const hasLowerCase = /[a-z]+/.test(passwordText);
		const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(passwordText);
		const lengthValidity = /^[\s\S]{8,11}$/.test(passwordText);
		this.passwordValid.hasUpperCase = hasUpperCase;
		this.passwordValid.hasSpecialCharacter = hasSpecialCharacter;
		this.passwordValid.hasAlphaNumeric = hasNumeric && (hasUpperCase || hasLowerCase);
		this.passwordValid.hasLowerCase = hasLowerCase;
		this.passwordValid.lengthValidity = lengthValidity;
		if (this.passwordValid.hasUpperCase && this.passwordValid.hasSpecialCharacter && this.passwordValid.hasAlphaNumeric && this.passwordValid.hasLowerCase && this.passwordValid.lengthValidity) {
			setTimeout(() => {
				this.closePopup();
			}, 2000)

		}
	}

	resetPasswordSubmit(passwordDetails: FormGroup) {
		if (passwordDetails.valid) {
			this.authService.resetPassword(passwordDetails.value).subscribe({
				next: (response: any) => {
					this.displaySuccessMsg = true;
					this._snackbar.openSnackBar(response.message, 'snackbar-success');
				}, error: (error: any) => {
					this.displaySuccessMsg = false;
					this._snackbar.openSnackBar(error.error.message[0], 'snackbar-error');
				},
				complete: () => {
					this.error = "complete";
				}
			});
		}
	}
}
