export enum PRIVATE_ROUTES {
	// Home
	home = '',

	// Admin Routes
	admin = 'admin',
	adminDashboard = 'dashboard',
	adminPrograms = 'programs',
	adminTracks = 'tracks',
	adminTrainings = 'trainings',
	adminTrainers = 'trainers',
	adminMentors = 'mentors',
	adminInterviewers = 'interviewers',
	adminCategories = 'categories',
	adminBatches = 'batches',
	adminCandidates = 'candidates',
	adminProgramsById = 'programs/:id',
	batchDetailsById = 'programs/:id/:batchId',
	programCandidateDetails = 'programs/:id/:batchId/:id',
	adminBatchDetailsById = 'batches/:batchId',
	adminBatchCandidateDetails = 'batches/:batchId/:id',
	adminCandidatesById = 'candidates/:batchId/:id',

	// Trainer Routes
	trainer = 'trainer',
	trainerDashboard = 'dashboard',
	trainerCandidatesById = ':batchId/:id',
	trainerViewAttendance = 'viewAttendance',

	//Candidate Routes
	candidateUser = 'candidate',

	//Super Admin Routes
	superAdmin = 'super_admin',
	superAdminAdmin = 'admins',

	//mentor Routes
	mentor='mentor',
	mentorCandidates = 'candidates',
	mentorCandidatesById = 'candidates/:batchId/:id',

	//interviewer Routes
	interviewer = "interviewer",
	interviewCandidate = ":batchId/:candidateId"
}

export enum PUBLIC_ROUTES {
	// UI Library
	uiLibrary = 'ui-library',

	// Auth Routes
	auth = 'auth',
	authLogin = 'login',
	authForgotPassword = 'forgot-password',
	authResetPassword = 'reset-password',
	authChooseRole = 'choose-role'
}

export enum WILDCARD_ROUTES {
	// 404
	ERROR404 = 'error/404',

	// 404
	ERROR500 = 'error/500',
}
