import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as _moment from 'moment';
import { map, Observable, startWith } from 'rxjs';
import { DialogService, IStatusData } from 'src/app/core';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { CandidateSubheaderComponent } from '../candidate-subheader/candidate-subheader.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { DropDownComponent } from '../drop-down/drop-down.component';
import { FilterSidebarComponent } from '../filter-sidebar/filter-sidebar/filter-sidebar.component';
import { PaginationComponent } from '../pagination/pagination.component';
import { ScoreCardComponent } from '../score-card/score-card.component';
import { SliderComponent } from '../slider/slider.component';
import { AvatarListComponent } from './../avatar-list/avatar-list.component';
import { NamedAvatarComponent } from './../named-avatar/named-avatar.component';

// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
	parse: {
		dateInput: 'MMM D,YYYY',
	},
	display: {
		dateInput: 'MMM D,YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

const materialModules = [
	MatAutocompleteModule,
	MatButtonModule,
	MatIconModule,
	MatTabsModule,
	MatInputModule,
	MatFormFieldModule,
	MatSnackBarModule,
	MatTooltipModule,
	MatPaginatorModule,
	MatRadioModule,
	MatTabsModule,
	MatSelectModule,
	MatCheckboxModule,
	MatFormFieldModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatChipsModule,
	MatCardModule,
	MatDividerModule,
	MatSidenavModule
];
interface Status {
	value: string;
	viewValue: string;
}

@Component({
	selector: 'app-component-library',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PaginationComponent,
		...materialModules,
		MatNativeDateModule,
		DatepickerComponent,
		AvatarListComponent,
		FilterSidebarComponent,
		NamedAvatarComponent,
		ScoreCardComponent,
		SliderComponent,
		NgxSliderModule,
		DropDownComponent,
		CandidateSubheaderComponent
	],
	templateUrl: './component-library.component.html',
	styleUrls: ['./component-library.component.scss'],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		MatMomentDateModule,
		{
		provide: DateAdapter,
		useClass: MomentDateAdapter,
		deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
	},
	{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentLibraryComponent implements OnInit {

	pipStatus=''


	date = new FormControl(moment());
	initialUpdateValues: any = {
		date: ''
	}

	programForm = new FormGroup({
		date: new FormControl('', Validators.required)
	});


	ngOnInit() {
		this.filteredOption = this.myControl.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value || '')),
		);

		this.pipStatus=this.dialogService.status;
	}

	value = 40;
	highValue = 60;
	options: Options = {
		floor: 0,
		ceil: 100,
		translate: (value: number): string => {
			if (value < 10) {
				return "0" + value
			} else {
				return value.toString()
			}
		}
	};

	candidateTraining = new FormControl([]);
	selectControl = new FormControl();
	myControl = new FormControl('');
	option: string[] = ['One', 'Two', 'Three'];
	filteredOption: any;

	startDate = new FormControl(null);
	endDate = new FormControl(null);
	exampleHeader = DatepickerComponent;
	itemsControl = new FormControl([]);
	selectedValue = '';
	replacementText = 'CE';
	imgSrc = "../../../assets/icons/avatar-icon.svg";
	noImg = "";
	data = ['one', 'two', 'three'];
	nameFormControl = new FormControl('');
	phoneFormControl = new FormControl('', [Validators.pattern('[0-9]{10}')]);
	emailFormControl = new FormControl('', [
		Validators.required,
		Validators.email,
	]);
	passwordFormControl = new FormControl('', [Validators.required]);
	disableFormControl = new FormControl({ value: '', disabled: true });
	showPassword = false;
	isFilterSidebarActive = false
	arrayItems = [{
		id: 1,
		title: 'hellow'
	}, {
		id: 2,
		title: 'hellow'
	}]

	imagesSrc = []
	rawData: Array<any> = [];
	selectData: Array<any> = [];

	filteredData: Observable<Array<any>> | undefined;
	filterString = '';
	ispip=false;
	constructor(private _snackBar: SnackbarService, private _formBuilder: FormBuilder ,public dialogService:DialogService) {
		this.filteredData = this.selectControl.valueChanges.pipe(
			startWith<string>(''),
			map(value => typeof value === 'string' ? value : this.filterString),
			map(filter => this.filterResult(filter))
		);
	}

	demoForm = this._formBuilder.group({
		demoArray: this._formBuilder.array(
			[]
		)
	});

	statusCard={
		'isSetInterview':false,
		'isScheduled':true,
		'isCompleted':false,
		'isViewHistory':false
	}

	// statusData={
	// 	'Mentor':'Alena Bergson',
	// 	'Duration':'May 06,2023-Jun 05,2023'
	// }

	statusData:IStatusData[]=[
		{
			'title':'mentor',
			'value':'Alena Bergson'
		},
		{
			'title':'Duration',
			'value':'May 06,2023-Jun 05,2023'
		}
	]
	
	// datakeys=Object.keys(this.statusData);
	// dataValues=Object.values(this.statusData);

	togglePassword() {
		this.showPassword = !this.showPassword;
	}

	displayFn = (): string => '';

	// need to pass the type of the snackbar
	openSnackBar(type?: string) {
		this._snackBar.openSnackBar('hi', type);
	}

	removeChip(item: string) {
		const items = this.itemsControl.value || [];
		this.removeFirst(items, item);
		this.itemsControl.setValue(items);
	}

	private removeFirst<T>(array: T[], toRemove: T): void {
		const index = array.indexOf(toRemove);
		if (index !== -1) {
			array.splice(index, 1);
		}
	}

	showFilterSidebar() {
		this.isFilterSidebarActive = !this.isFilterSidebarActive
		return this.isFilterSidebarActive
	}

	get demoArray() {
		return this.demoForm.get('demoArray') as FormArray;
	}
	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();

		return this.option.filter(ele => ele.toLowerCase().includes(filterValue));
	}

	optionClicked = (event: Event, data: any): void => {
		event.stopPropagation();
		this.toggleSelection(data);
	};

	toggleSelection = (data: any): void => {
		data.selected = !data.selected;

		if (data.selected === true) {
			this.selectData.push(data);
		} else {
			const i = this.selectData.findIndex(value => value.item === data.item);
			this.selectData.splice(i, 1);
		}

		this.selectControl.setValue(this.selectData);
		this.emitAdjustedData();
	};

	emitAdjustedData = (): void => {
		const results: Array<string> = []
		this.selectData.forEach((data: any) => {
			results.push(data.item);
		});
	};

	filterResult = (filter: string): Array<any> => {
		this.filterString = filter;
		if (filter.length > 0) {
			return this.rawData.filter(option => {
				return option.item.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
			});
		} else {
			return this.rawData.slice();
		}
	};


	status: Status[] = [
		{ value: 'in-training', viewValue: 'In Training' },
		{ value: 'resigned', viewValue: 'Resigned' },
		{ value: 'process-exit', viewValue: 'Process exit' },
		{ value: 'confirmed', viewValue: 'Confirmed' }
	];

	statusControl = new FormControl(this.status[0].value);
	form = new FormGroup({
		food: this.statusControl,
	});


	showDialog(data:any){
			this.dialogService
				.openDialog(ConfirmationDialogComponent, {
					data: {},
				})
				setTimeout(()=>{                           
					this.statusCheck();
				}, 2500);
			
	}

	statusCheck(){
		this.pipStatus=this.dialogService.status;
		if(this.pipStatus==="pip"){
			this.ispip=true;
		}
	}
}
