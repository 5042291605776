<mat-form-field appearance="fill" floatLabel="always"
	[ngClass]="{'auto-complete-list-validation': (usersInput?.hasError('required') || showError || showValidationMsg)}">
	<input matInput #myInput type="text" placeholder="Select..." [formControl]="usersInput" [matAutocomplete]="auto"
		(click)="toggleDropdown($event)">
	<mat-icon matSuffix svgIcon="dropdown-arrow" class="dropdown-icon" (click)="toggleDropdown($event)"></mat-icon>
	<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" #autocomplete>
		<mat-option *ngFor="let user of userData" (onSelectionChange)="userOptionClicked(user)"
			[value]="user.managerName">
			{{user.managerName}}
			<mat-icon svgIcon="checkmark-icon" *ngIf="usersInput?.value === user.managerId" class="tick-icon">
			</mat-icon>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
<mat-error *ngIf="usersInput?.hasError('required') || showError || showValidationMsg" class="validation-msg">
	Please assign a Reporting Manager
</mat-error>