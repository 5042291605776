import { Observable, of, debounceTime } from "rxjs"

// import {Observable} from 

export interface INotification{
    notificationId : number,
    heading : string,
    action?: string,
    text: string,
    time: string,
    icon: string,
    actionable : boolean,
    date? : string,
    candidateDetails : {
        candidateId : number,
        batchId : number,
        candidateName : string | null
    }
    newNotification: boolean,
    isActive : 0 | 1
}

export interface IGetNotification {
   
        "pageSize": number,
        "page": number,
        "startDate":string,
        "endDate": string,
        "userId": string,
        "role": string
     
}


export const notifications : INotification[] = [
    // {
    //     heading: 'Reminder',
    //     text: 'Add feedback for tejas labde Add feedback for tejas labde Add feedback for tejas labde Add feedback for tejas labde Add feedback for tejas labde Add feedback for tejas labde Add feedback for tejas labde Add feedback for tejas labde Add feedback for tejas labde Add feedback for tejas labdeAdd feedback for tejas labdeAdd feedback for tejas labdeAdd feedback for tejas labdeAdd feedback for tejas labdeAdd feedback for tejas labdeAdd feedback for tejas labde',
    //     icon: 'success-check-icon',
    //     time: 'just now',
    //             newNotification: true
    // },
    // {
    //     heading: 'Reminder',
    //     action: 'Give Feedback',
    //     text: 'Add feedback for tejas labde',
    //     icon: 'success-check-icon',
    //     time: '12:30 pm',
    //             newNotification: true
    // },
    // {
    //     heading: 'Reminder',
    //     action: 'Give Feedback',
    //     text: 'Add feedback for tejas labde',
    //     icon: 'success-check-icon',
    //     time: 'just now',
    //         newNotification: true
    // },
    // {
    //     heading: 'Reminder',
    //     action: 'Give Feedback',
    //     text: 'Add feedback for tejas labde',
    //     icon: 'success-check-icon',
    //     time: 'just now',
    //         newNotification: false
    // },
    // {
    //     heading: 'Reminder',
    //     action: 'Give Feedback',
    //     text: 'Add feedback for tejas labde',
    //     icon: 'success-check-icon',
    //     time: 'just now',
    //         newNotification: false
    // },
    // {
    //     heading: 'Reminder',
    //     action: 'Give Feedback',
    //     text: 'Add feedback for tejas labde',
    //     icon: 'success-check-icon',
    //     time: 'just now',
    //         newNotification: true
    // }
]

export interface INotificationData{
    date : string,
    notifications: INotification[] | notificationDTO[] | any
}

export const notificationData: INotificationData[]=  [
    {
        date: 'Today',
        notifications : notifications
    },
    {
        date : 'Yesterday',
        notifications: notifications
    }
];

export interface notificationDTO  {
    notificationId: number,
    title: string,
    description: string,
    timestamp:string,
    isActionable: number,
    candidateId: number,
    batchId: number,
    candidateFirstName: string | null,
    candidateLastName : string | null,
    readStatus: number,
    actionable: number,
    icon : string,
    isActive : 0 | 1
    }

    export interface INotificationResponse {
        unreadCount : number
        totalCount : number,
        candidateIdList : number[] | null, 
        notificationContents : {
            [key : string] : notificationDTO[] | INotification[]
        }
    }