<div class="named-avatar-wrapper ">
	<span class="no-image-small">{{ replacementText }}</span>
	<span [ngClass]="{'blue-highlight': clickable === true}">{{userData?.name}}</span>
	<mat-chip *ngIf="userData?.isExternal" class="external-trainer-indicator">Ext</mat-chip>
	<mat-chip *ngIf="userData?.isPIP===1 && !(userData?.isPipExtended || userData?.isExtended)" class="pip-indicator">PIP</mat-chip>
	<mat-chip *ngIf="userData?.isPipExtended===1 || userData?.isExtended" class="pip-indicator">PIP+</mat-chip>



	<!-- <div class="user-avatar">
		<div *ngIf="imgSrc?.length !== 0; else noImgSrcMedium">
			<img src="{{ imgSrc }}" class="default medium" />
		</div>
		<ng-template #noImgSrcMedium>
			<div class="no-image-medium">
				
			</div>
		</ng-template>
	</div>
	<div class="user-name">
		
	</div> -->
</div>