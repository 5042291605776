import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { SnackbarService, UtilityService } from 'src/app/core';
import { IDialogData } from 'src/app/core/models';
import { DatepickerComponent } from 'src/app/shared/datepicker/datepicker.component';
import { CandidateService } from './../../../../core/services/admin/candidate/candidate.service';
import { TrainerUserService } from './../../../../core/services/trainer/trainer.service';
import { OPERATIONS } from './../../../../core/structs/operations.enum';

@Component({
    selector: 'app-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

    exampleHeader = DatepickerComponent;
    maxAllowedDate!: Date;
    trainingNameInput = new FormControl('');
    currentRange = 7;
    isLinear = true;
    trainingsArray!: Array<any>;
    startDate = '';
    endDate = '';
    selectedTrainingName = '';
    overallFeedback = new FormControl('', [Validators.required]);
    submitIsClicked = false;
    candidateId = 0;
    batchId!: number;
    trainerId!: number;
    isVisible = false;
    showDropdown = false;
    userData!: any;
    userDetails!: any;
    overallFeedbackError = false;
    emptyLengthCount = 0;
    emptyLengthPosition = 0;
    injectingData = false;
    feedbackLength: number | undefined = 0;
    valuesChanged = false;
    id!: number;
    @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) matAutocomplete: MatAutocompleteTrigger | undefined;
    feedbackParameters = [{
        id: 0, key: "Logic", param: 'logic', value: 0, comment: '', icon: 'comment-icon',
        showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true, showOptionClicked: false
    },
    {
        id: 1, key: "Proactiveness", param: 'proactiveness', value: 0, comment: '', icon: 'comment-icon',
        showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true, showOptionClicked: false
    },
    {
        id: 2, key: "Grasping", param: 'grasping', value: 0, comment: '', icon: 'comment-icon',
        showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true, showOptionClicked: false
    },
    {
        id: 3, key: "Problem Solving", param: 'problemSolving', value: 0, comment: '', icon: 'comment-icon',
        showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true, showOptionClicked: false
    },
    {
        id: 4, key: "Communication", param: 'communication', value: 0, comment: '', icon: 'comment-icon',
        showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true, showOptionClicked: false
    },
    {
        id: 5, key: "Assignments", param: 'assignment', value: 0, comment: '', icon: 'comment-icon',
        showCommentBox: false, showError: false, commentIsRequired: true, showCommentIcon: true, showOptionClicked: false
    }]

    detailsForm = new FormGroup({
        trainingId: new FormControl('', Validators.required),
        startDate: new FormControl('', Validators.required),
        endDate: new FormControl('', Validators.required)
    })
    feedbackForm = new FormGroup({
        logic: new FormControl(0, [Validators.required, Validators.min(1)]),
        logicComment: new FormControl(''),
        grasping: new FormControl(0, [Validators.required, Validators.min(1)]),
        graspingComment: new FormControl(''),
        communication: new FormControl(0, [Validators.required, Validators.min(1)]),
        communicationComment: new FormControl(''),
        assignment: new FormControl(0, [Validators.required, Validators.min(1)]),
        assignmentComment: new FormControl(''),
        proactiveness: new FormControl(0, [Validators.required, Validators.min(1)]),
        proactivenessComment: new FormControl(''),
        problemSolving: new FormControl(0, [Validators.required, Validators.min(1)]),
        problemSolvingComment: new FormControl('')
    })

    

    constructor(@Inject(MAT_DIALOG_DATA) public data: IDialogData<any>,
        public dialogRef: MatDialogRef<FeedbackDialogComponent>,
        private _snackbar: SnackbarService,
        private trainerUserService: TrainerUserService,
        private _utility: UtilityService,
        private router: Router,
        private route: ActivatedRoute,
        private candidateService: CandidateService) {
    }

    ngOnInit(): void {
        this.getTrainerId();
        if(this.data.operation === OPERATIONS.EDIT){
            this.batchId = this.data.data.batchId;
        }
        else{
            this.batchId=this.data.batchId;
        }
        this.candidateId = this.data.candidateId;
        
        this.trainingNameInputChanges();
        if (this.data.operation === OPERATIONS.EDIT) {
          
            this.batchId = this.data.data.batchId;
            this.detailsForm.get('trainingId')?.setValue(this.data.data.trainingId);
            this.detailsForm.get('startDate')?.setValue(this.data.data.startDate);
            this.setMaxDate();
            this.batchId = this.data.data.batchId;
            this.data.data.category.forEach((parameter: any, paramIndex: number) => {
                const indexPos = this.feedbackParameters.findIndex((param: any) => param.key.toLowerCase() === parameter.key.toLowerCase());
                this.feedbackParameters[indexPos].comment = parameter.comment;
                this.feedbackParameters[indexPos].value = parameter.value;
                this.feedbackForm.get(parameter.key)?.patchValue(parameter.value);   
            
                if (parameter.comment === 0) {
                    this.emptyLengthCount += 1;
                    if (this.emptyLengthCount === 1) {
                        this.emptyLengthPosition = indexPos;
                    }
                }
                this.injectingData = true;
                this.valueIsSelected({ id: this.feedbackParameters[indexPos].id, value: parameter.value });
                this.saveCommentClicked({ id: this.feedbackParameters[indexPos].id, comment: parameter.comment })
            })
            this.injectingData = false;
            this.overallFeedback.patchValue(this.data.data.comments);
            this.startDate = this.data.data.startDate;
            this.endDate = this.data.data.endDate;
            this.selectedTrainingName = this.data.data.trainingName;
        }
        this.overallFeedback.valueChanges.subscribe({
            next: (value: any) => {
                this.feedbackLength = value.length;
                this.valuesChanged = true;
            }
        })
        this.valuesChangeDetector();
        this.feedbackLength = this.overallFeedback.value?.length;
        
    }

    /*to track changes whenever done to the training name input field. */
    trainingNameInputChanges() {
        this.trainingNameInput.valueChanges.pipe(
            startWith(''),
            debounceTime(1000),
            distinctUntilChanged()
        ).subscribe((res: any) => {
            this.getTrainingsForFeedback(res);
            const selectedArray: any = this.trainingsArray.filter((el: any) => this.trainingNameInput.value === el.trainingName);
            const id = selectedArray[0].trainingId;
            this.detailsForm.get('trainingId')?.patchValue(id);
        })
    }

    /*function to get list of trainings according to the text entered in the searchField in add Feedback input field. */
    getTrainingsForFeedback(text: any) {
        const trainingListReqBody = {
            searchText: text,
            trainerId: this.trainerId,
            batchId: this.batchId
        }
        this.trainerUserService.getAllTrainingsForFeedback(this.trainerUserService.http, trainingListReqBody).subscribe({
            next: (response: any) => {
                this.trainingsArray = response;
            },
            error: (error: any) => {
                this._snackbar.openSnackBar(error.error.message, 'snackbar-error');
            }
        })
    }

    /**
     * function to set the max date to be selected by default. Calculated as startDate + 6days
     */
    setMaxDate() {
        if (this.data.operation === OPERATIONS.EDIT) {
            this.detailsForm.get('endDate')?.patchValue(this.data.data.endDate);
        } else {
            const startDate = this.detailsForm.get(['startDate'])?.value._i;
            const startingDate = this._utility.getDateFormat(startDate);
            const value = new Date(startingDate);
            value.setDate(value.getDate() + 6);
            const dateArray = value.toLocaleDateString().split('/');
            const currentDateObject = {
                year: Number(dateArray[2]),
                month: Number(dateArray[0]) - 1,
                date: Number(dateArray[1])
            }
            const currentDateInFormat = this._utility.getDateFormat(currentDateObject);
            this.detailsForm.get('endDate')?.patchValue(currentDateInFormat);
        }
    }

    /*function to set value of score and comment for a particular parameter after the user has 
    selected a value in the score-slider. Also to trigger the icon states as per the conditions of feedback required */
    valueIsSelected(event: any) {
        this.feedbackParameters[event.id].value = event.value;
        const scoreValue = this.feedbackParameters[event.id].value;
        const commentLength = this.feedbackParameters[event.id].comment.length;
        if (scoreValue > 0 && scoreValue <= 5) {
            this.feedbackParameters[event.id].commentIsRequired = false;
            this.feedbackParameters[event.id].showCommentIcon = true;
            this.feedbackParameters[event.id].showCommentBox = true;
            if (commentLength === 0) {
                this.feedbackParameters[event.id].icon = 'comment-icon-active';
                this.addValidators(`${this.feedbackParameters[event.id].param}Comment`, [Validators.required, Validators.maxLength(200)]);
                this.feedbackForm.get(`${this.feedbackParameters[event.id].param}Comment`)?.updateValueAndValidity();
            } else if (commentLength > 0) {
                this.feedbackParameters[event.id].icon = 'comment-success-icon';
                this.feedbackParameters[event.id].showCommentBox = false;
            }
        }
        else if (scoreValue > 5) {
            this.feedbackParameters[event.id].commentIsRequired = true;
            this.feedbackParameters[event.id].showCommentIcon = false;
            this.feedbackParameters[event.id].showCommentBox = false;
            this.feedbackForm.get(`${this.feedbackParameters[event.id].param}Comment`)?.clearValidators();
            this.feedbackForm.get(`${this.feedbackParameters[event.id].param}Comment`)?.updateValueAndValidity();
        }
        else if (scoreValue === 0) {
            this.feedbackParameters[event.id].showCommentIcon = true;
            this.feedbackParameters[event.id].commentIsRequired = true;
            this.feedbackParameters[event.id].showError = false;
            this.feedbackParameters[event.id].showCommentBox = false;
            this.feedbackParameters[event.id].icon = 'comment-icon';
        }
        const index = this.feedbackParameters.findIndex((param: any) => param.id === event.id);
        const parameterKey = this.feedbackParameters[index].param;
        this.feedbackForm.get(parameterKey)?.patchValue(this.feedbackParameters[index].value);
        if (this.injectingData && commentLength === 0) {

            if (event.id !== this.emptyLengthPosition && scoreValue <= 5) {
                this.feedbackParameters[event.id].icon = 'comment-error-icon';
                this.feedbackParameters[event.id].commentIsRequired = false;
                this.feedbackParameters[event.id].showCommentBox = false;
                this.feedbackParameters[event.id].showCommentIcon = true;
            }
        }
        if (this.submitIsClicked) {
            this.showErrorMessages();
        }
    }

    addValidators(formControlName: string, validators: ValidatorFn[]) {
        this.feedbackForm.get(formControlName)?.addValidators(validators);
        this.feedbackForm.get(formControlName)?.updateValueAndValidity();
    }

    showCommentBoxTooltip(column: string) {
        const index = this.feedbackParameters.findIndex((param: any) => param.key === column);
        this.feedbackParameters[index].showOptionClicked = true;
        if (this.feedbackParameters[index].comment.length === 0 && (this.feedbackParameters[index].value > 0 && this.feedbackParameters[index].value <= 5)) {
            this.feedbackParameters[index].showError = true;
            this.feedbackParameters[index].icon = 'feedback-comment-error';
        }
        if (this.feedbackParameters[index].value <= 5 && this.feedbackParameters[index].comment.length >= 0) {
            this.feedbackParameters[index].showCommentBox = !this.feedbackParameters[index].showCommentBox;
        }
    }

    onCancelClick() {
        this.dialogRef.close('cancel');
    }

    /*to set values of training name and the start and end date of the feedback as entered in the first step. */
    onNextClick() {
        this.startDate = this.detailsForm.get('startDate')?.value || '';
        this.endDate = this.detailsForm.get('endDate')?.value || '';
        const selectedTraining = this.trainingsArray.filter((el: any) => el.trainingId === this.detailsForm.get('trainingId')?.value);
        this.selectedTrainingName = selectedTraining[0].trainingName || '';
    }

    cancelCommentClicked(event: any) {
        if (this.feedbackParameters[event.id].comment.length > 0) {
            this.feedbackParameters[event.id].showCommentBox = !this.feedbackParameters[event.id].showCommentBox;
            this.valueIsSelected(this.feedbackParameters[event.id].value);
        }
    }

    /*function to store the value of comment entered by the user in the comment box for that respective parameter */
    saveCommentClicked(event: any) {
        this.feedbackParameters[event.id].showError = false;
        this.feedbackParameters[event.id].comment = event.comment;
        const index = this.feedbackParameters.findIndex((param: any) => param.id === event.id);
        const parameterKey = this.feedbackParameters[index].param;
        const commentParameter = `${parameterKey}Comment`;
        this.feedbackForm.get(`${commentParameter}`)?.setValue(this.feedbackParameters[index].comment);
        this.feedbackParameters[event.id].showCommentBox = !this.feedbackParameters[event.id].showCommentBox;
        if (event.id < 6) {
            this.valueIsSelected({ id: event.id, value: this.feedbackParameters[event.id].value });
        }
    }

    /*to check for conditions in the parameters step of dialog and show the validation error messages as per requirement. */
    showErrorMessages() {
        this.feedbackParameters.forEach((parameter: any, index: number) => {
            if (parameter.value <= 5 && parameter.comment.length === 0) {
                this.feedbackParameters[index].showError = true;
                this.feedbackParameters[index].icon = 'comment-error-icon';
            } else if (parameter.value > 5 || (parameter.value <= 5 && parameter.comment.length > 0)) {
                this.feedbackParameters[index].showError = false;
            }
        })
        if (!this.overallFeedback.valid) {
            this.overallFeedbackError = true;
        }
        else if (this.overallFeedback.valid) {
            this.overallFeedbackError = false;
        }
    }

    onFormSubmit() {
        this.submitIsClicked = true;
        let endingDate = '';
        let startingDate = '';
        const startDate = this.detailsForm.get(['startDate'])?.value._i;
        startingDate = this._utility.getDateFormat(startDate);
        if (this.data.operation === OPERATIONS.EDIT) {
            const endDate = this.detailsForm.get(['endDate'])?.value._i;
            endingDate = this._utility.getDateFormat(endDate);
        }
        const reqBody: any = {
            batchId: this.batchId,
            userId: this.candidateId,
            trainingId: this.detailsForm.get('trainingId')?.value,
            trainingName: this.selectedTrainingName,
            logic: this.feedbackForm.get('logic')?.value,
            logicComment: this.feedbackForm.get('logicComment')?.value || '',
            grasping: this.feedbackForm.get('grasping')?.value,
            graspingComment: this.feedbackForm.get('graspingComment')?.value || '',
            communication: this.feedbackForm.get('communication')?.value,
            communicationComment: this.feedbackForm.get('communicationComment')?.value || '',
            problemSolving: this.feedbackForm.get('problemSolving')?.value,
            problemSolvingComment: this.feedbackForm.get('problemSolvingComment')?.value || '',
            assignment: this.feedbackForm.get('assignment')?.value,
            assignmentComments: this.feedbackForm.get('assignmentComment')?.value || '',
            proactiveness: this.feedbackForm.get('proactiveness')?.value,
            proactivenessComment: this.feedbackForm.get('proactivenessComment')?.value || '',
            overAllFeedback: this.overallFeedback.value,
        }
        if (this.data.operation === OPERATIONS.EDIT) {
            reqBody.evaluationId = this.data.data.evaluationId;
            reqBody.feedbackId = this.data.data.feedbackId;
            reqBody.startDate = this.detailsForm.get('startDate')?.value;
            reqBody.endDate = this.detailsForm.get('endDate')?.value;

        } else if (this.data.operation === OPERATIONS.ADD) {
            reqBody.evaluationTypeName = "Weekly";
            reqBody.startDate = startingDate;
            reqBody.endDate = this.detailsForm.get('endDate')?.value;

        }
        this.showErrorMessages();
        if (this.feedbackForm.valid && this.overallFeedback.valid) {
            if (this.data.operation === OPERATIONS.ADD) {
                this.trainerUserService.addFeedback(reqBody).subscribe({
                    next: (response: any) => {
                        this._snackbar.openSnackBar(response.message, 'snackbar-success');
                        this.dialogRef.close('success');
                    },
                    error: (error: any) => {
                        this._snackbar.openSnackBar(error.error.message, 'snackbar-error');
                    }
                })
            }
            else if (this.data.operation === OPERATIONS.EDIT) {
                this.trainerUserService.updateFeedback(reqBody).subscribe({
                    next: (response: any) => {
                        this._snackbar.openSnackBar(response.message, 'snackbar-success');
                        this.dialogRef.close('success');
                    },
                    error: (error: any) => {
                        this._snackbar.openSnackBar(error.error.message, 'snackbar-error');
                    }
                })
            }
        }
    }

    getTrainerId() {
        // const data = localStorage.getItem('userDetails');
        // const userDetails = JSON.parse(data || '') || '' ;
        this.userData = localStorage.getItem('userDetails');
        this.userDetails = JSON.parse(this.userData) || '';
        this.trainerId = this.userDetails?.userId;
    }

    optionClicked(item: any) {
        this.detailsForm.get('trainingId')?.setValue(item.trainingId);
    }

    toggleDropdown(event: any) {
        if (this.isVisible) {
            setTimeout(() => {
                this.matAutocomplete?.closePanel();
            }, 10)
            this.isVisible = false;
        } else {
            this.isVisible = true;
        }
    }

    valuesChangeDetector() {
        this.feedbackForm.valueChanges.subscribe({
            next: () => {
                this.valuesChanged = true;
            }
        })
    }

}
