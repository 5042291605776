import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IQueryParams, IResponse } from 'src/app/core/models';
import { IMentor } from 'src/app/core/models/mentor';
import { API_ENDPOINTS } from 'src/app/core/structs';
import { ServicesUtilityService } from '../../utility/services-utility.service';

@Injectable({
	providedIn: 'root'
})
export class BatchService {

	constructor(public http: HttpClient,
		private servicesUtilityService: ServicesUtilityService) { }

	/**
	 * Fetches all Batches
	 * @param http httpClient object
	 * @param reqBody request filter body
	 * @returns list of batches
	 */
	getBatches(
		http: HttpClient,
		reqBody: IQueryParams
	): Observable<any> {
		return http.post<IResponse<any>>(API_ENDPOINTS.getAllBatches, reqBody);
	}
	/**
	 *
	 * @param batchId Unique Id of the batch number whose data we need to GET
	 * @param payload contains payload with parameters required for the
	 * @returns batch details for required batch.
	 */
	getParticularBatchDetails(batchId: number, payload: any) {
		return this.http.post(`${API_ENDPOINTS.particularBatchDetails}${batchId}`, payload,)
	}

	/**
	   * to update status of batch-archive/unarchive
	   * @param batch batch details-id and status which is to be set.
	   * @returns success/error message
	   */
	updateBatchStatus(batch: any): Observable<any> {
		return this.http.patch<any>(
			`${API_ENDPOINTS.updateBatchStatus}/${batch.batchId}/${batch.status}`, {}
		)
	}

	/**
	 * get list of trainings according to particular batch ID
	 * @param batchId ID of the batch whose training list is needed
	 * @param reqBody reqBody for the post request
	 * @returns list of trainings present in the batch.
	 */
	getTrainingsByBatchId(batchId: number, reqBody: any) {
		return this.http.post(`${API_ENDPOINTS.getTrainingsByBatchId}${batchId}`, reqBody)
	}

	/**
	 * add new batch
	 * @param payload Body payload as per required by the BE
	 * @returns success/error message
	 */
	addBatch(payload: any): Observable<any> {
		return this.http.post(`${API_ENDPOINTS.addBatch}`, payload);
	}

	getBatchDetails(batchId: number) {
		return this.http.get(`${API_ENDPOINTS.getBatchDetails}/${batchId}`)
	}

	updateBatch(payload: any): Observable<any> {
		return this.http.put(`${API_ENDPOINTS.updateBatch}`, payload);
	}

	updateCandidateStatus(reqBody: any): Observable<any> {
		return this.http.patch<any>(
			`${API_ENDPOINTS.updateCandidateStatus}`, reqBody);
	}

	getMentorsByTrack(trackId: number[]) {
		return this.http.post(API_ENDPOINTS.getMentorsByTrack, trackId) as Observable<IMentor[]>;
	}

	getAllUsers(): Observable<any> {
		return this.http.get(API_ENDPOINTS.getAllUsers);
	}

	markSinglePip(payload: any): Observable<any> {
		return this.http.post(`${API_ENDPOINTS.markForPip}`, payload);
	}

	extendPip(payload: any): Observable<any> {
		return this.http.patch(`${API_ENDPOINTS.updateForPip}`, payload);
	}

}

