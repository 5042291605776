export enum OPERATIONS {
  EDIT = 'edit',
  ADD = 'add',
  ADD_NEW = 'addNew',
  DELETE = 'delete',
  DUPLICATE='duplicate',
  MARK = 'mark',
  EXTEND = 'extend',
  SCHEDULED = 'SCHEDULED',
  RESCHEDULED = 'RESCHEDULED',
}
