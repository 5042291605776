import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebsocketService } from 'src/app/core';
import { ICustomLoginResponse, ISuccessResponse } from '../../models';
import { IAuthData } from '../../models/authData';
import { API_ENDPOINTS } from '../../structs';
import { IUserDetails } from './../../interfaces/user-details';
import { ROLE_ROUTES } from './../../structs/roles.enum';


@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private http: HttpClient, private router: Router,private websocket:WebsocketService) { 	}

	/**
	 * Checks whether the user is logged in or not.
	 *
	 * @returns true, if user is logged in, otherwise, false
	 */
	
	isLoggedIn() {
		return this.getCurrentRole() !== null ? true : false;
	}

	/**
	 * Returns the token from LocalStorage if exists
	 *
	 * @returns the stored token if exists; otherwise null
	 */
	getToken(): string | null {
		return localStorage.getItem('token');
	}

	/**
	 * Used to set the user token in localStorage
	 * @param token user's unique token
	 */
	setToken(token: string): void {
		localStorage.setItem('token', token);
	}

	/**
	 * used to login an external user into the system using their credentials
	 * @param credentials email and password as entered by the user in the login page
	 * @returns message with success/failure of login
	 */
	customLogin(credentials: { email: string; password: string; rememberMe: boolean; }): Observable<ICustomLoginResponse> {
		return this.http.post<ICustomLoginResponse>(
			API_ENDPOINTS.authLogin,
			credentials
		);
	}

	/**
	 * used to send the user email to send a mail containing the temporary password
	 * @param data user email
	 * @returns success/failure message
	 */
	forgotPassword(data: { email: string }): Observable<ISuccessResponse> {
		return this.http.post<ISuccessResponse>(
			API_ENDPOINTS.forgotPassword + '?email=' + data.email,
			{}
		);
	}

	/**
	 * to send new password fields entered by the user
	 * @param data contains the new Password and confirm Password fields
	 * @returns success/failure message
	 */
	resetPassword(data: {
		newPassword: string;
		confirmPassword: string;
	}): Observable<ISuccessResponse | string[]> {
		return this.http.post<ISuccessResponse | string[]>(
			API_ENDPOINTS.resetPassword,
			data
		);
	}

	/**
	 * logout the user
	 * redirects to the login page
	 * removes userDetails from localstorage
	 */
	logout() {
		localStorage.clear();

		this.router.navigate(['auth/login']);
	}

	/**
	 * Gets user details from localstorage
	 * @return UserDetails or Empty Object
	 */
	getUserDetails(): IUserDetails | any {
		const userDetails = localStorage.getItem('userDetails');

		return userDetails ? JSON.parse(userDetails) : undefined;
	}


	/**
	 * To verify the incoming Token
	 * @return userDetails as observable
	 */
	findUserDetails(): Observable<any> {
		return this.http.get(API_ENDPOINTS.findUserDetails);
	}

	/**
	 * Stores user details to localstorage
	 * @return void
	 */
	setUserDetails(userDetails: any): void {
		localStorage.setItem('userDetails', JSON.stringify(userDetails));
		if (!localStorage.getItem('token')) localStorage.setItem('token', JSON.stringify(userDetails.jwtToken));
		this.setRoles();
	}


	/**
	 * Checks the user's role and returns the dashboard route for user
	 *
	 * @returns The route for the current user role
	 */
	getRoleRoute(): string {
		return Object.keys(ROLE_ROUTES).filter(
			(key: string) => ROLE_ROUTES[key] === this.getUserDetails()?.role[0]
			)[0];
		}
		
		setRoles(){
			const roles = this.getUserDetails().role.map((role:string)=>role.split('_').splice(1).join('_').toLowerCase());
			localStorage.setItem('roles', JSON.stringify(roles))
			if(!localStorage.getItem('currentRole') && roles.length===1){
				localStorage.setItem('currentRole', roles[0])
			}
	}

	setCurrentRole(role:string){
		localStorage.setItem('currentRole', role);
	}

	getCurrentRole(){
		return localStorage.getItem('currentRole');
	}

	getRoles(){
		return JSON.parse(localStorage.getItem('roles') || '[]');
	}

	switchRole(role:string){
		if(localStorage.getItem('roles')?.includes(role)){
			this.router.navigate([role]);
			this.setCurrentRole(role);
			this.websocket._disconnect();
		}
	}

	getAuthData(authData: IAuthData | any): Observable<any> {
		return this.http.get(API_ENDPOINTS.oauthData, {
			params: authData
		});
	}
}
