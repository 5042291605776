<div class="empty-state-container ">
	<div *ngIf="title" class="empty-state-title">
		<h6>{{title}}</h6>
		<div *ngIf="graphType==='line'" class="score-and-icon">
			<app-score-chip [score]="0"></app-score-chip>
			<mat-icon svgIcon="info-info" class="info-icon" matTooltip="This is the average of all the weekly scores." matTooltipPosition="right"></mat-icon>
		</div>
	</div>
	<mat-icon svgIcon="{{svgIcon}}" class="empty-state-icon"></mat-icon>
	<h6>{{msgTitle}}</h6>
	<p>{{msgLine1}}</p>
	<p>{{msgLine2}}</p>
</div>