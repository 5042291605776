import { Component } from '@angular/core';
import { UtilityService } from './core/utility/index';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor(private utilityService: UtilityService) {
		this.utilityService.registerIcons();
	}
}
