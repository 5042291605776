<div class="candidate-detail-header">
    <div class="candidate-info" *ngIf="isCandidatePortal; else showTrainerPortal">
        <div class="no-image-larger avatar">
            <p class="candidate-pic">{{ nameInitials }}</p>
        </div>
        <div class="candidate-details">
            <h6 class="candidate-name">{{ candidateFirstName }} {{ candidateLastName }}</h6>
            <p class="info caption-1">{{ batchName }} &#x2022; {{ trackName }} &#x2022; {{ programName }}</p>
        </div>
        <mat-divider class="divider" [vertical]="true"></mat-divider>
        <div class="candidate-score-details">
            <h6 class="candidate-score">Overall Score</h6>
            <app-score-chip [score]="candidateDetails?.overAllFeedback"></app-score-chip>
        </div>
        <mat-divider class="mat-divider" [vertical]="true"></mat-divider>
        <div class="images-list">
            <div class="candidates-trainer" (mouseover)="showTrainerImageList=true" (mouseleave)="showTrainerImageList=false">
                <div *ngIf="candidateDetails?.candidateDashboardTrainers.length>0">
                    <h6 class="candidate-trainer">Trainers</h6>
                    <app-avatar-list [avatarList]="candidateDetails?.candidateDashboardTrainers"
                        [userType]="'candidate'"></app-avatar-list>
                </div>
            </div>
            <div class="image-wrapper" *ngIf="showTrainerImageList">
                <mat-card class="card disable-hover">
                    <div *ngFor="let i of trainersList; index as index" class="guide-wrapper">
                        <div class="default no-image-medium image-up avatar">
                            <p>{{replacementText[index]|uppercase }}</p>
                        </div>
                        <p class="guide-name">{{ i }}</p>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="images-list">
            <div class="candidates-mentor" (mouseover)="showMentorImageList = true" (mouseleave)="showMentorImageList=false">
                <div *ngIf="candidateDetails?.candidateDashboardMentor.length>0">
                    <h6 class="candidate-score">Mentor</h6>
                    <app-avatar-list [avatarList]="candidateDetails?.candidateDashboardMentor"
                        [userType]="'candidate'"></app-avatar-list>
                </div>
            </div>
            <div class="image-wrapper" *ngIf="showMentorImageList">
                <mat-card class="card disable-hover">
                    <div *ngFor="let i of mentorsList; index as index" class="guide-wrapper">
                        <div class="default no-image-medium image-up avatar">
                            <p>{{mentorReplacementText[index] | uppercase }}</p>
                        </div>
                        <p class="guide-name">{{ i }}</p>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="images-list" *ngIf="pipMentorsList.length">
            <div class="candidates-trainer">
                <div class="candidates-pip">
                    <div *ngIf="candidateDetails?.candidateDashboardPipMentor.length>0">
                        <h6 class="candidate-score">PIP Mentor</h6>
                        <app-avatar-list [avatarList]="candidateDetails?.candidateDashboardPipMentor"
                            [userType]="'candidate'"></app-avatar-list>
                    </div>
                </div>
            </div>
            <div class="image-wrapper">
                <mat-card class="card disable-hover">
                    <div *ngFor="let i of pipMentorsList; index as index" class="guide-wrapper">
                        <div class="default no-image-medium image-up avatar">
                            <p>{{pipMentorReplacementText[index]|uppercase }}</p>
                        </div>
                        <p class="guide-name">{{ i }}</p>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <ng-template #showTrainerPortal>
        <div class="candidate-info-wrapper">
            <div class="candidate-info">
                <mat-icon svgIcon="Back" (click)="onClick()" class="action-btn"></mat-icon>
                <div class="no-image-larger avatar">
                    <p class="candidate-pic">{{ nameInitials }}</p>
                </div>
                <div class="candidate-details">
                    <div class="chip-wrapper">
                        <h6 class="candidate-name">{{ candidateFirstName }} {{ candidateLastName }}</h6>
                        <ng-content select="[pipChip]"></ng-content>
                    </div>
                    <p class="info caption-1">{{ batchName }} &#x2022; {{ trackName }} &#x2022; {{ programName }}</p>
                </div>
                <mat-divider class="divider" [vertical]="true"></mat-divider>
                <ng-template [ngIf]="isOverAllScore">
                    <div class="candidate-score-details">
                        <h6 class="candidate-score">Overall Score</h6>
                        <app-score-chip [score]="scoreData?.overallScore"></app-score-chip>
                    </div>
                    <mat-divider class="divider" [vertical]="true"></mat-divider>
                </ng-template>
            </div>
            <div class="candidate-status-details" *ngIf="currentUserType==='admin' || currentUserType==='superAdmin'">
                <mat-divider class="mat-divider" [vertical]="true"></mat-divider>
                <h6 class="candidate-status">Status</h6>
                <app-drop-down [userData]="candidateDetails" (changedStatus)="updatedStatus($event)"></app-drop-down>
            </div>
            <div *ngIf="!downloadVisibility && isMentor || !downloadVisibility && isTrainer || !downloadVisibility && isInterviewer" class="candidate-status-details-wrapper">
                <mat-divider class="divider" [vertical]="true"></mat-divider>
                <div class="candidate-status-details">
                    <h6 class="candidate-status">Status</h6>
                    <div class="status">
                        <mat-icon matPrefix class="status-icon" svgIcon="status-icon"
                            [ngClass]="{ 'training-yellow' : status === 'In Training', 'exit-red': status === 'Process Exit', 'resigned-red': status === 'Resigned', 'confirmed-green': status === 'Confirmed' }"></mat-icon>
                        <span>{{status}}</span>
                    </div>
                </div>
            </div>
        </div>
        <section class="candidate-actions-wrapper">
            <ng-content select="[candidateHeaderActions]"></ng-content>
            <div *ngIf="downloadVisibility">
                <button mat-flat-button disableRipple class="secondary sm" (click)="downloadReport()">
                    <mat-icon class="icon" svgIcon="download"></mat-icon>
                </button>
            </div>
        </section>
        <div *ngIf="!downloadVisibility && isTrainer || !downloadVisibility && isMentor">
            <button mat-flat-button color="primary" disableRipple class="sm" (click)="showDialog('add')">
                <mat-icon svgIcon="add"></mat-icon>
                <span>Add Feedback</span>
            </button>
        </div>
    </ng-template>
</div>