import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from 'src/app/core/models';
import { API_ENDPOINTS } from 'src/app/core/structs';
import { AuthService } from '../../auth/auth.service';
import { EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CandidateService {
	programName!: string;
	batchName!: string;
	trackName!: string;
	candidateFirstName!: string;
	candidateLastName!: string;
	candidateUserId!: number;
	batchId!: number;
	pipMentorId!: number;
	candidateStatus!: string;
	pipStartDate!: string;
	pipEndDate!: string;
	pipStatus!: string;
	overallScore !: number;
	public loadCandidate = new EventEmitter();
	constructor(public http: HttpClient, private authService: AuthService) { }

	/**
   * function to get list of candidates
   * @param http http client for all http requests
   * @param reqBody redBody for POST method as per Swagger URL 
   * @returns  list of all candidates
   */
	getAllCandidates(
		http: HttpClient,
		reqBody: any
	): Observable<any> {
		return http.post<IResponse<any>>(API_ENDPOINTS.getAllCandidates, reqBody);
	}

	getCandidateGraphById(candidateId: number, batchId: number) {
		if (this.authService.getCurrentRole() === 'mentor')
			return this.http.get(`${API_ENDPOINTS.getGraphDataForMentor}/${candidateId}/${batchId}`)
		else
			return this.http.get(`${API_ENDPOINTS.getGraphData}/${candidateId}/${batchId}`)
	}

	/**
	 * function to get graph data for a particular candidate in the mentor's feedback tab.
	 * @param userId user Id of the candidate who's graph details we need to get.
	 * @returns graph data 
	 */
	getMentorFeedbackGraph(candidateId: number, batchId: number) {
		return this.http.get(`${API_ENDPOINTS.getGraphDataForMentor}/${candidateId}/${batchId}`);
	}

	getMentorFeedbackDashboard(payload: any) {
		return this.http.post(`${API_ENDPOINTS.getCandidateDashboardForMentor}`, payload);
	}

	getPipFeedbackDashboard(payload: any) {
		return this.http.post(`${API_ENDPOINTS.getPipDashboardForMentor}`, payload);
	}
	getInterviewFeedbackDashboard(reqBody:any) {
		
		return this.http.post(`${API_ENDPOINTS.getCandidateDashboardForInterview}`,reqBody)
		
	}

	getSingleCandidateFeedback(
		http: HttpClient,
		reqBody: any
	) {
		return http.post(API_ENDPOINTS.getCandidateDashboard, reqBody);
	}

	downloadStudentReport(http: HttpClient,
		reqBody: any
	): Observable<HttpResponse<Blob>> {
		return http.post<Blob>(API_ENDPOINTS.downloadStudentReport, reqBody, {
			observe: 'response',
			responseType: 'blob' as 'json',
		});
	}

	downloadAllCandidatesReport(http: HttpClient,
		reqBody: any
	): Observable<HttpResponse<Blob>> {
		return http.post<Blob>(API_ENDPOINTS.downloadAllCandidatesReport, reqBody, {
			observe: 'response',
			responseType: 'blob' as 'json',
		});
	}

	/**
	 * function to unmark candidate from PIP.
	 * @returns unmarked PIP candidate
	 */

	unmarkCandidateFromPip(candidateId: number[]): Observable<any> {
		return this.http.patch<any>(
			`${API_ENDPOINTS.unMarkPIP}`, candidateId
		)
	}
}
