import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Stomp } from '@stomp/stompjs';
import { BehaviorSubject, Observable } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { API_ENDPOINTS } from 'src/app/core/structs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IGetNotification, INotification, INotificationResponse, notificationDTO } from '../../models/notifications';

@Injectable({
	providedIn: 'root'
})
export class WebsocketService implements OnDestroy {

	stompClient!: any;
	currentUserEmail = '';
	currentUserRole = '';

	private readonly _notificationData = new BehaviorSubject<any>({});
	public readonly notificationData = this._notificationData.asObservable();

	private readonly _notificationReceived = new BehaviorSubject<any>(false);
	public readonly notificationReceived = this._notificationReceived.asObservable();

	public notify = new EventEmitter();
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	// constructor(private authService : AuthService){}
	/**
	 * function to connect to a web server to implement Notification flow in the module.
	 * @param connectUrl EndPoint where the connection is to be made.
	 */
	constructor(private http: HttpClient) {

	}
	_connect() {
		if (this.currentUserEmail.length === 0 && !this.stompClient) {
			const userData = JSON.parse(localStorage.getItem('userDetails') || '');
			this.currentUserEmail = userData.email;
			const serverUrl = `${environment.HOST_URL}${API_ENDPOINTS.webSocketEndPoint}?email=${this.currentUserEmail}`;
			const socket = new SockJS(serverUrl);
			this.stompClient = Stomp.over(socket);
			this.stompClient.connect({}, (frame: any) => {
				this._sendUserCredentials();
				this.stompClient.subscribe(`/user/topic/specific/role_${localStorage.getItem('currentRole')}`, (message: any) => {
					this.onmessageReceived(message);
				})
			},
				
			)
			this.stompClient.debug = (message: any) => {
				if (message.includes('Connection closed to')) {
					setTimeout(() => {
						this._connect();
					}, 2000)
				}
			}
		}
	}

	/**
	 * 
	 * @param endPoint specific endpoint to which the message has to be sent to.
	 * @param message message content to be sent.
	 */
	_sendMessage(endPoint: any, message?: string) {
		this.stompClient.send(`${environment.HOST_URL}${endPoint}`, {}, JSON.stringify(message));
	}

	onmessageReceived(message: any) {
		this.notifications = JSON.parse(message.body);
	}

	public set notifications(resp) {
		this.notify.emit(resp);
		this._notificationData.next(resp);
	}

	public get notifications(): any {
		return this._notificationData.getValue();
	}

	public set receivedNotification(resp) {
		this._notificationReceived.next(resp);
	}

	public get receivedNotification(): any {
		return this._notificationReceived.getValue();
	}


	/**
	 * function to send user credentials after the connection has been made to the websocket server.
	 */
	_sendUserCredentials() {
		const userData = JSON.parse(localStorage.getItem('userDetails') || '');
		const currentRole = localStorage.getItem('currentRole') || '';
		this.currentUserEmail = userData.email;
		this.currentUserRole = `ROLE_${currentRole.toUpperCase()}`;
		this.stompClient.send(`/ws/getRole`, {}, JSON.stringify({ email: this.currentUserEmail }));
	}

	/**
	 * 
	 * @param endPoint specific endpoint to which the message has to be sent to.
	 * @param message message which is to be received.
	 */
	_receiveMessage(endPoint: any, message?: string) {
		this.stompClient.subscribe('', (message: any) => {
			const stom = message
		});
	}

	/**
	 * function to disconnect with the Websocket server.
	 */
	_disconnect() {
		if (this.stompClient) {
			this.stompClient.disconnect();
			this.stompClient = undefined;
			this.currentUserEmail = '';
			this.currentUserRole = '';
		}
	}

	ngOnDestroy(): void {
		this._disconnect();
	}

	getALLNotifications(payload: IGetNotification): Observable<INotificationResponse> {
		return this.http.post(API_ENDPOINTS.getAllNotifications, payload) as Observable<INotificationResponse>;
	}

	markAllAsRead(): Observable<any> {
		const userData = JSON.parse(localStorage.getItem('userDetails') || '');
		const currentUserRole = localStorage.getItem('currentRole') || '';
		const currentRole = `ROLE_${currentUserRole.toUpperCase()}`;
		const params = new HttpParams().set('userId', userData.userId).set('currentRole', currentRole);
		return this.http.patch(API_ENDPOINTS.markAllRead, {},
			{
				params: params
			});
	}
}
