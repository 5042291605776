import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../../structs';
@Injectable({
	providedIn: 'root'
})
export class MentorService {

  constructor(public http: HttpClient) { }

  getMentorDashboard(
		http: HttpClient,
		reqBody: any
	): Observable<any> {
		return http.post(API_ENDPOINTS.getMentorDashboard, reqBody);
	}

	getCandidateData(http: HttpClient,
		reqBody: any): Observable<any> {
			return http.post(API_ENDPOINTS.getCandidateData, reqBody);
	}

	addMentorFeedback(reqBody: any) {
		return this.http.post( API_ENDPOINTS.addMentorFeedback,reqBody);
	}

	updateMentorFeedback(reqBody: any) {
		return this.http.put(API_ENDPOINTS.updateMentorFeedback, reqBody,);
	}

}
