import { CommonModule } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	DateAdapter,
	MatDateFormats,
	MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-datepicker',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		MatInputModule,
		MatIconModule,
		MatFormFieldModule,
		ReactiveFormsModule,
	],
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent<D> implements OnDestroy {
	private _destroyed = new Subject<void>();
	constructor(
		private _calendar: MatCalendar<D>,
		private _dateAdapter: DateAdapter<D>,
		@Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
		cdr: ChangeDetectorRef
	) {
		_calendar.stateChanges
			.pipe(takeUntil(this._destroyed))
			.subscribe(() => cdr.markForCheck());
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.complete();
	}

	get periodLabel() {
		return this._dateAdapter
			.format(
				this._calendar.activeDate,
				this._dateFormats.display.monthYearLabel
			)
			.toLocaleUpperCase();
	}

	previousClicked(mode: 'month' | 'year') {
		this._calendar.activeDate =
			mode === 'month'
				? this._dateAdapter.addCalendarMonths(
					this._calendar.activeDate,
					-1
				)
				: this._dateAdapter.addCalendarYears(
					this._calendar.activeDate,
					-1
				);
	}

	nextClicked(mode: 'month' | 'year') {
		this._calendar.activeDate =
			mode === 'month'
				? this._dateAdapter.addCalendarMonths(
					this._calendar.activeDate,
					1
				)
				: this._dateAdapter.addCalendarYears(
					this._calendar.activeDate,
					1
				);
	}
}
