<form [formGroup]="formGroup"></form>
<mat-form-field appearance="fill" floatLabel="always" (click)="picker.open()">
    <mat-icon matPrefix svgIcon="datepicker"></mat-icon>
    <mat-date-range-input [rangePicker]="picker" [min]="minAllowedDate">
        <input matStartDate placeholder="Start date" [formControl]="startDate" id="startdate"
            (dateInput)="setDate()">
        <input matEndDate [formControl]="endDate" placeholder="End date" (dateInput)="setDate()">
    </mat-date-range-input>
    <mat-date-range-picker #picker [calendarHeaderComponent]="exampleHeader">
    </mat-date-range-picker>
    <mat-error *ngIf="error">
        Please choose the duration
    </mat-error>
</mat-form-field>