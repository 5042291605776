import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { UsersService } from './../../core/services/admin/users/users.service';
import { SnackbarService } from './../../core/services/snackbar/snackbar.service';

@Component({
	selector: 'app-autocomplete-dropdown',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, FormsModule, MatInputModule, MatFormFieldModule, MatAutocompleteModule, MatIconModule],
	templateUrl: './autocomplete-dropdown.component.html',
	styleUrls: ['./autocomplete-dropdown.component.scss']
})
export class AutocompleteDropdownComponent implements OnChanges, OnInit {

	disableInput = false;
	usersInput = new FormControl('');
	userData: Array<any> = [];
	isVisible = false;
	isUserSelected = false;
	showError = false;
	@ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) matAutocomplete: MatAutocompleteTrigger | undefined;

	@Input() currentUser!: any;
	@Input() showValidationMsg = false;
	@Output() userSelected: EventEmitter<any> = new EventEmitter();


	constructor(private _userService: UsersService, private _snackbar: SnackbarService) { }

	ngOnChanges(): void {
		if (this.currentUser !== undefined) {
			if (this.currentUser.checkSelection && this.usersInput.value === '') {
				this.showError = true;
			}
			this.getReportingManager();
			this.disableInput = this.currentUser.namedAvatar.disableInput;
			if (this.disableInput) {
				this.usersInput.disable();
				this.usersInput.reset();
			}
			else {
				this.usersInput.enable();
			}
		}
	}

	ngOnInit(): void {
		this.usersInput.valueChanges.pipe(
			startWith(''),
			debounceTime(1000),
			distinctUntilChanged()
		).subscribe({
			next: (response: any) => {
				this.getReportingManager();
			},
			error: (error: any) => {
				this._snackbar.openSnackBar('snackbar-error', error.error.message);
			}
		})
	}


	getReportingManager() {
		let payload = {};
		if (this.isUserSelected) {
			payload = {
				searchText: '',
				userRole: 'ALL'
			}
			this.isUserSelected = false

		} else {
			payload = {
				searchText: this.usersInput.value,
				userRole: 'ALL'
			}
		}
		this._userService.getUsers(this._userService.http, payload).subscribe({
			next: (response: any) => {
				this.userData = [...response];
				this.userData = response.map((item: any) => {
					item.managerId = item.userId;
					item.managerName = `${item.firstName} ${item.lastName}`;
					if (this.currentUser !== undefined && this.currentUser.assignedManagerId !== -1) {
						if (item.userId === this.currentUser.assignedManagerId) {
							this.usersInput.patchValue(item.managerName);
						}
					}
				})

				this.userData = [...response] || [];
			},
			error: (error: any) => {
				this._snackbar.openSnackBar(error.error.message, 'snackbar-error');
			}
		})
	}

	toggleDropdown(event: any) {
		if (this.isVisible) {
			setTimeout(() => {
				this.matAutocomplete?.closePanel();
			}, 10)
			this.isVisible = false;
		} else {
			this.isVisible = true;
		}
	}

	userOptionClicked(user: any) {
		this.isUserSelected = true;
		if (this.currentUser !== undefined) {
			this.userSelected.emit({
				batchId: this.currentUser.batchId,
				studentId: this.currentUser.userId,
				studentName: this.currentUser.namedAvatar.name,
				managerId: user.managerId,
				managerName: user.managerName
			});
		}
		else {
			this.userSelected.emit({
				managerId: user.managerId,
				managerName: user.managerName
			})
		}
	}

}
