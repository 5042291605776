import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './../../core/services';

@Component({
	selector: 'app-loader',
	standalone: true,
	imports: [CommonModule,
		MatProgressSpinnerModule,
		MatProgressBarModule
	],
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

	constructor(private loader:LoaderService) { }

	loading$=this.loader.loading$;

}
