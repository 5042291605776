import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IQueryParams, IResponse, ISuccessResponse } from '../../../models';
import { ITrack } from '../../../models/track';
import { API_ENDPOINTS } from '../../../structs';
import { ServicesUtilityService } from '../../utility/services-utility.service';

@Injectable({
	providedIn: 'root'
})
export class TracksService {

	constructor(public http: HttpClient, private servicesUtilityService: ServicesUtilityService) { }


	getTracks(
		http: HttpClient,
		reqBody: IQueryParams
	): Observable<any> {
		return http.post<IResponse<any>>(API_ENDPOINTS.getTracks, reqBody);
	}

	createTrack(track: ITrack): Observable<ISuccessResponse> {
		return this.http.post<ISuccessResponse>(API_ENDPOINTS.createTrack, track);
	}


	updateTrack(track: ITrack): Observable<ISuccessResponse> {
		return this.http.put<ISuccessResponse>(
			`${API_ENDPOINTS.updateTrack}`,
			track
		);
	}

	/**
	 * to get list of all trainings according to the trackID's selected in the filter sidebar
	 * @param reqBody contains array of trackIdList and searchText incase the user wants to search for a particular track by name
	 * @returns list containing the training with their training Name and training Id.
	 */
	getAllTrainingsFromTracks(reqBody: any) {
		return this.http.post(`${API_ENDPOINTS.getAllTrainingsFromTracks}`, reqBody)
	}

	updateTrackStatus(track: ITrack): Observable<ISuccessResponse> {
		return this.http.patch<ISuccessResponse>(
			`${API_ENDPOINTS.updateTrackStatus}/${track.trackId}/${track.status}`,
			track
		);
	}

	/**
	 * to return list of tracks according to selected program Id
	 * @param programId specific program ID for which corresponding tracks list is to be fetched
	 * @returns tracks list
	 */
	getTracksByProgramId(programId: number) {
		return this.http.get(`${API_ENDPOINTS.getTracksByProgram}/${programId}`)
	}

	/**
	 * function to get list of ptracks according to the text entered in the input field
	 * @param queryParams queryParam must contain the text entered in the input field in tracks dialog
	 * @returns list of ptracks corresponding to the value entered
	 */
	searchTracks(queryParams: IQueryParams): Observable<any> {
		const params = this.servicesUtilityService.createHttpParams(queryParams);
		return this.http.post(API_ENDPOINTS.searchTracks, {}, {
			params: params
		}
		);
	}
}
