import { Injectable } from '@angular/core';
import { BehaviorSubject, delay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  // a behavior subject for the status of the loader
  private _loading = new BehaviorSubject<boolean>(false);

  // loading status of the loader (will be used in the components)
  public readonly loading$ = this._loading.asObservable().pipe(delay(1));

  // hold flag for holding and releasing the loader
  private hold = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  /**
   * sends true to observable for changing the state of the loader to true
   *
   * @returns void
   */
  show(): void {
    this._loading.next(true);
  }

  /**
   * sends false to observable for changing the state of the loader to false
   *
   * @returns void
   */
  hide(): void {
    this._loading.next(false);
  }

  /**
   * sets the value of hold flag to true
   *
   * @returns void
   */
  holdLoader() {
    this.hold = true;
  }

  /**
   * sets the value of hold flag to false
   *
   * @returns void
   */
  releaseLoader() {
    this.hold = false;
  }

  /**
   * returns the value of hold flag
   *
   * @returns boolean
   */
  isHold() {
    return this.hold;
  }
}
