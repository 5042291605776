import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-rank',
  standalone: true,
  imports: [CommonModule,MatChipsModule,MatIconModule],
  templateUrl: './rank.component.html',
  styleUrls: ['./rank.component.scss']
})
export class RankComponent implements OnChanges {

  @Input() imgSrc = '';
  @Input() clickable: any;
  @Input() userData:any;
  
  rankNumber!:number;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  ngOnChanges() {       
     this.rankNumber = this.userData;
  }

}
