import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IQueryParams, IResponse } from '../../../models';
import { API_ENDPOINTS } from '../../../structs';
import { ServicesUtilityService } from '../../utility/services-utility.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(public http: HttpClient, private servicesUtilityService: ServicesUtilityService) { }

  /**
   * 
   * @param http httpClient injector
   * @param reqBody reqBody containing the searchText and userRole as required
   * @returns list of all users present in the organization.
   */
  getUsers(
    http: HttpClient,
    reqBody: IQueryParams
  ): Observable<any> {
    return http.post<IResponse<any>>(API_ENDPOINTS.getUsers, reqBody);
  }

  /**
   * 
   * @param http httpClient injector
   * @param reqBody reqBody containing the searchText and userRole as required
   * @returns list of all users present in the organization who are not present in any active batch.
   */
  getAllCandidatesForNewBatch(
    http: HttpClient,
    reqBody: IQueryParams
  ): Observable<any> {
    return http.post<IResponse<any>>(API_ENDPOINTS.getAllCandidatesForNewBatch, reqBody);
  }

  /**
   * 
   * @param http httpClient injector
   * @param reqBody reqBody containing the searchText and userRole as required
   * @returns list of all admins present in the organization.
   */

  getAllAdmins(http: HttpClient, data: any): Observable<any> {
    return http.post(API_ENDPOINTS.getAllUsers, data);
  }

}


