import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-msg',
  templateUrl: './success-msg.component.html',
  styleUrls: ['./success-msg.component.scss']
})
export class SuccessMsgComponent {

  @Input() condition='';
  @Input() forgotEmail='';
  constructor(private router:Router) { }

  signInClicked(){
    this.router.navigate(['auth/login']);
  }

}
